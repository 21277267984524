import React, { useState, useCallback } from "react";
import Layout from "../../../../../Components/Layout/Layout";
import styles from "./AdicionarAtivos.module.css";
import Loading from "../../../../../Components/Loading/Loading";
import Alert from "../../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../../Components/Dropdown/Dropdown";
import EncryptDecryptStorage from "../../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarAtivos({permissions}) {

  const { id } = useParams();
  const { ativoId } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");
  const [caracteristics, setCaracteristics] = useState([]);

  const [addCaracteristicas, setAddCaracteristicas] = useState([]);

  const [firstLoadLocals, setFirstLoadLocals] = useState(true);
  const [locais, setLocais] = useState([]);
  const [nextLocais, setNextLocais] = useState("");
  const [localValue, setLocalValue] = useState("");
  const urlLocais = Url + "/locals?start=0&limit=15";

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getEquipmentCaracteristics = useCallback((equipmentId) => {
    var newCaracteristicsValues = [];
      axios.get(Url+"/equipments/"+equipmentId+"/caracteristic-types/", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      for (var f = 0; f < res.data.length; f++){
        newCaracteristicsValues.push({id: res.data[f].id, name: res.data[f].name, unit: res.data[f].unit, value: ""});
      }
      setAddCaracteristicas(newCaracteristicsValues);
      await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getEquipmentCaracteristics(equipmentId) : window.location.reload();
      }
      await setLoading(false);
    })
  }, []);

  const getAtivoDetails = useCallback((ativoId) => {
    axios.get(Url+"/equipments/"+ativoId, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setCaracteristics(res.data.characteristics);
        setLocalValue(res.data.local);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAtivoDetails(ativoId) : window.location.reload();
      }
      await setLoading(false);
    })
  }, []);

  const getLocais = (urlLocais, search) => {
    axios.get(urlLocais, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setNextLocais(res.data.next);
      setNextLocais(res.data.next);
      if (search){
        setLocais(res.data.detail);
        setFirstLoadLocals(true);
      }
      else {
        if (firstLoadLocals){
          setLocais(res.data.detail);
          setFirstLoadLocals(false);
        }
        else {
          setLocais(prevLocals => [...prevLocals, ...res.data.detail]);
        }
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocais(urlLocais, search) : window.location.reload();
      }
      else {
        setLocais([]);
        setNextLocais("");
      }
    })
  };

  useEffect(() => {
    getLocais(urlLocais);
  }, [urlLocais]);

  useEffect(() => {
    if (ativoId){
        getAtivoDetails(ativoId);
    }
    else {
      getEquipmentCaracteristics(id);
    }
  }, [ativoId, getAtivoDetails, getEquipmentCaracteristics, id]);

  const changeCaracteristicValue = (id, value) => {
    const updatedCaracteristics = [...caracteristics]; // create a copy of the state array
    const index = updatedCaracteristics.findIndex(c => c.id === id); // find the index of the object with the given id
    if (index !== -1) {
      updatedCaracteristics[index] = {...updatedCaracteristics[index], name: value}; // update the name of the object at the found index
      setCaracteristics(updatedCaracteristics); // update the state variable with the new array
    }
  }

  const changeAddCaracteristicValue = (id, value) => {
    const updatedCaracteristics = [...addCaracteristicas]; // create a copy of the state array
    const index = updatedCaracteristics.findIndex(c => c.id === id); // find the index of the object with the given id
    if (index !== -1) {
      updatedCaracteristics[index] = {...updatedCaracteristics[index], value: value}; // update the name of the object at the found index
      setAddCaracteristicas(updatedCaracteristics); // update the state variable with the new array
    }
  }

  const addAtivo = async () => {
    await setAlert();
    if (nameValue === ""){
      return;
    }
    setSpinner(true);
    await axios
      .post(Url + "/equipments", {
        name: nameValue,
        local_id: localValue.local.id,
        caracteristics: addCaracteristicas,
        equipment_type_id: id
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Ativo criado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/manutencao/equipamentos/"+id);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addAtivo() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar ativo</Alert>);
        }
      });
  }

  const editAtivo = async (ativoId) => {
    if (nameValue === ""){
        return;
    }
    setAlert();
    var finalCaracteristics = [];
    for(var t=0; t < caracteristics.length; t++){
      finalCaracteristics.push({id: caracteristics[t].caracteristic_type.id, name: caracteristics[t].caracteristic_type.name, value: caracteristics[t].name});
    }
    setSpinner(true);
    await axios
      .put(Url + "/equipments/"+ativoId, {
        name: nameValue,
        local_id: localValue.id ? localValue.id : localValue.local.id,
        caracteristics: finalCaracteristics
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Ativo editado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/manutencao/equipamentos/"+id);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editAtivo(ativoId) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar ativo</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={ativoId ? "manutenção - ativo #"+ativoId : "manutenção - ativos"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to={"/manutencao/equipamentos/"+id}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
              <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do ativo</p>
              <input
                defaultValue={nameValue}
                rows={3}
                placeholder="Indique o nome do ativo..."
                onChange={(value) => setNameValue(value.target.value)}
              />
            </div>
            <Dropdown
              label={"Local do ativo"}
              placeholder={"Escolher local do ativo"}
              itemsWidth={"40vw"}
              items={locais}
              onUrlChange={getLocais}
              value={localValue}
              onValueChange={setLocalValue}
              nextUrl={nextLocais}
              search={true}
              urlSearch={"/locals"}
              required={true}
            />
            {
            caracteristics.length > 0 ?
            <div className={styles.titleActions}>
              <h3 className={styles.title}>Características</h3>
              {
                caracteristics.map((caracteristic) => (
                    <div className={styles.formField} style={{marginBottom: "5px"}} key={caracteristic.id}>
                      <p style={{maxWidth: "140px"}} className={ caracteristic.name ? styles.inputLabel : styles.inputLabelNull }>{caracteristic.caracteristic_type.name}</p>
                      <input
                        value={caracteristic.name}
                        rows={3}
                        placeholder="Indique o valor..."
                        onChange={(value) => {changeCaracteristicValue(caracteristic.id, value.target.value)}}
                      />
                      <p className={styles.unit}>{ caracteristic.caracteristic_type.unit ? caracteristic.caracteristic_type.unit.name : ""}</p>
                  </div>
                ))
              }
            </div>
            : ""
            }
            {
              addCaracteristicas.length > 0 ?
              <div className={styles.titleActions}>
                <h3 className={styles.title}>Características</h3>
                {
                  addCaracteristicas.map((caracteristic) => (
                      <div className={styles.formField} style={{marginBottom: "5px"}} key={caracteristic.id}>
                          <p style={{maxWidth: "140px"}} className={ caracteristic.value ? styles.inputLabel : styles.inputLabelNull }>{caracteristic.name}</p>
                          <input
                            value={caracteristic.value}
                            rows={3}
                            placeholder="Indique o valor..."
                            onChange={(value) => {changeAddCaracteristicValue(caracteristic.id, value.target.value)}}
                          />
                        <p className={styles.unit}>{ caracteristic.unit ? caracteristic.unit.name : ""}</p>
                      </div>
                      
                  ))
                }
            </div>
              : ""
            }
            
            {
              ativoId ?
              <button className={styles.submitBtn} onClick={() => editAtivo(ativoId)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addAtivo()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarAtivos;
import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarDutyGroups.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import MultiSelect from "../../../../Components/MultiSelect/MultiSelect";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarDutyGroups({permissions}) {

  const { id } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");

  const [urlDutyAreas, setUrlDutyAreas] = useState(Url+"/duty/areas/?start=0&limit=15");
  const [areas, setAreas] = useState([]);
  const [nextAreas, setNextAreas] = useState("");
  const [previousAreas, setPreviousAreas] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getGroupDetails = (id) => {
    axios.get(Url+"/duty/groups/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        await setNameValue(res.data.name);
        await setSelectedAreas(res.data.duty_areas);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getGroupDetails(id) : window.location.reload();
      }
      await setLoading(false);
    })
  }

  const getAreas = async (urlDutyAreas) => {
    axios.get(urlDutyAreas, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      await setAreas(res.data.detail);
      await setNextAreas(res.data.next);
      await setPreviousAreas(res.data.previous);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAreas(urlDutyAreas) : window.location.reload();
      }
      else {
        await setAreas([]);
        await setNextAreas("");
      }
    })
  };

  useEffect(() => {
    getAreas(urlDutyAreas);
  }, [urlDutyAreas]);

  useEffect(() => {
    if (id) getGroupDetails(id);
    else setLoading(false);
  }, [id]);

  const addDuty = async () => {
    await setAlert();
    if (nameValue === "" || selectedAreas.length === 0){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    var finalAreas = [];
    for (let a = 0; a < selectedAreas.length; a++){
        finalAreas.push(selectedAreas[a].id);
    }
    setSpinner(true);
    await axios
      .post(Url + "/duty/groups/", {
        name: nameValue,
        duty_areas: finalAreas
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Duty report criado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/duty");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addDuty() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar duty report</Alert>);
        }
    });
  }

  const editDuty = async (id) => {
    await setAlert();
    if (nameValue === "" || selectedAreas.length === 0){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    var finalAreas = [];
    for (let a = 0; a < selectedAreas.length; a++){
        finalAreas.push(selectedAreas[a].id);
    }
    setSpinner(true);
    await axios
      .put(Url + "/duty/groups/"+id, {
        name: nameValue,
        duty_areas: finalAreas
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Duty report editado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/duty");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editDuty(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
        }
    });
  }

  return (
    <>
      <Layout tab={id ? "duty report #"+id : "duty report"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/duty">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder={"Indique o nome do relatório a adicionar..."}
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            <MultiSelect
              label={"Áreas"}
              placeholder={"Escolher áreas"}
              itemsWidth={"50vw"}
              items={areas}
              onUrlChange={getAreas}
              value={selectedAreas}
              onValueChange={setSelectedAreas}
              nextUrl={nextAreas}
              previousUrl={previousAreas}
              required={true}
              pagination={true}
            />
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editDuty(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addDuty()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarDutyGroups;
import React from "react";

import styles from "./Layout.module.css";
import LeftBar from "../LeftBar/LeftBar";
import TopBar from "../TopBar/TopBar";

function Layout({children, tab, permissions}) {

  return (
    <>
      <div className={styles.divider}>
        <LeftBar tab={tab} permissions={permissions} />
        <TopBar tabName={tab}/>
        <div className={styles.container}>
          <div className={styles.scrollContent}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;

import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./BackofficeAvarias.module.css";
import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoAddCircleSharp } from "react-icons/io5";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function BackofficeAvarias({permissions}) {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalArea, setDeleteModalArea] = useState(false);
  const [deleteModalTipo, setDeleteModalTipo] = useState("");

  const [firstLoadAreas, setFirstLoadAreas] = useState(true);
  const urlAreas = Url+"/malfunctions/malfunction-areas/?start=0&limit=15";
  const [areas, setAreas] = useState([]);
  const [areaValue, setAreaValue] = useState(state ? state.from : "");
  const [nextAreas, setNextAreas] = useState([]);

  const [tipos, setTipos] = useState([]);

  const getAreas = async (urlAreas) => {
    axios.get(urlAreas, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setNextAreas(res.data.next);
      if (firstLoadAreas) {
        setAreas(res.data.detail);
        setFirstLoadAreas(false);
      } else {
        setAreas(prevAreas => [...prevAreas, ...res.data.detail]);
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAreas(urlAreas) : window.location.reload();
      }
      else {
        setAreas([]);
        setNextAreas("");
      }
    })
  };

  const getTipos = async (url) => {
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        },
      }).then((res) => {
        setTipos(res.data.detail);
      }).catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? getTipos(url) : window.location.reload();
        }
        else {
          setTipos([]);
        }
      })
  };

  const TiposList = () => {
    return (
      areaValue !== "" ? 
        <>
        <p className={styles.areaTitle} style={{marginTop: "15px"}}>Tipos de {areaValue.name}<NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/avarias/tipos/adicionar"} state={{from: areaValue}}><IoAddCircleSharp className={styles.hoverAdd} size={22}/></NavLink></p>
        {
          tipos.map((tipo) => (
              <div className={styles.tiposContainer} key={tipo.id}>
                {deleteModalTipo?.id === tipo?.id ? <DeleteModal onClose={setDeleteModalTipo} headerText={"ELIMINAR TIPO"} confirmationText={"Deseja eliminar "+tipo.name+" ?"} deleteFunction={deleteTipo} objectId={tipo.id} areaId={areaValue.id}/> : ""}
                <p>
                    {tipo.name}
                </p>
                <div className={styles.tiposActions}>
                    <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/avarias/tipos/editar/"+tipo.id} state={{from: areaValue}}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                    <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => setDeleteModalTipo(tipo)}/>
                </div>
              </div>
        ))
        }
      </>
      : ""
    );
  }

  const deleteArea = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/malfunctions/malfunction-areas/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Área apagada com sucesso</Alert>);
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteArea(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao apagar área</Alert>);
        }
      });
  }

  const deleteTipo = async (areaID, typeID) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/malfunctions/malfunction-types/"+typeID+"/malfunction-areas/"+areaID, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo apagado com sucesso</Alert>);
        setTimeout(() => {
          setTimeout(() => {
            setSpinner(false);
          }, 500);
          getTipos(Url+"/malfunctions/malfunction-types/"+areaValue.id+"?start=0&limit=500");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteTipo(areaID, typeID) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao apagar tipo</Alert>);
        }
      });
  }

  useEffect(() => {
      getAreas(urlAreas);
      if (state) {
        getTipos(Url+"/malfunctions/malfunction-types/"+areaValue.id+"?start=0&limit=500");
      }
      setLoading(false);
  }, [urlAreas]);

  return (
    <>
      <Layout tab={"backoffice avarias"} permissions={permissions}>
        {alert}
        {deleteModalArea ? <DeleteModal onClose={setDeleteModalArea} headerText={"ELIMINAR ÁREA"} confirmationText={"Deseja eliminar "+areaValue.name+" ?"} warningText={"Todos os tipos associados a esta área serão eliminados!"} deleteFunction={deleteArea} objectId={areaValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <p className={styles.areaTitle}>Área de avaria
              {
                (permissions.malfunctions && permissions.malfunctions.PUT) ?
                <NavLink style={{color: "var(--text-primary)"}} to="/backoffice/avarias/areas/adicionar"><IoAddCircleSharp className={styles.hoverAdd} size={22}/></NavLink>
              : ""
              }
            </p>
            <div className={styles.dropdown}>
                <div className={styles.dropdownActions}>
                    <Dropdown 
                      label={false}
                      placeholder={"Escolher a área da avaria"}
                      dropdownWidth={"45vw"}
                      itemsWidth={"45vw"}
                      value={areaValue}
                      onValueChange={setAreaValue}
                      items={areas}
                      onUrlChange={getAreas}
                      nextUrl={nextAreas}
                      callGetMalfunctionTypes={getTipos}
                      callbackLink={Url+"/malfunctions/malfunction-types/"}
                    />
                    {
                      areaValue && (permissions.malfunctions && permissions.malfunctions.DELETE) ?
                      <div className={styles.tiposActions}>
                        <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/avarias/areas/editar/"+areaValue.id} state={{from: areaValue}}><IoPencilSharp className={styles.hoverEdit} size={22}/></NavLink>
                        <IoTrashSharp className={styles.hoverDelete} size={22} onClick={() => setDeleteModalArea(true)}/>
                    </div>
                    : ""
                    }
                </div>
                <TiposList/>
            </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default BackofficeAvarias;
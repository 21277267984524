import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarIntervencoes.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import MultiSelect from "../../../../Components/MultiSelect/MultiSelect";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarIntervencoes({permissions}) {

  const { id } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");
  const [referenceValue, setReferenceValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");

  const [firstLoadTechnicalProfiles, setFirstLoadTechnicalProfiles] = useState(true);
  const urlTechnicalProfile = Url+"/malfunctions/profiles/?start=0&limit=15";
  const [technicalProfiles, setTechnicalProfiles] = useState([]);
  const [technicalProfileValue, setTechnicalProfileValue] = useState({id: 0, name: "Sem perfil específico"});
  const [nextTechnicalProfiles, setNextTechnicalProfiles] = useState("");

  const urlTasks = Url+"/tasks?start=0&limit=15";
  const [tasks, setTasks] = useState([]);
  const [nextTasks, setNextTasks] = useState("");
  const [previousTasks, setPreviousTasks] = useState("");
  const [selectedTasks, setSelectedTasks] = useState([]);

  const urlInterventionTypes = Url+"/interventions/intervention-types/";
  const [interventionTypes, setInterventionTypes] = useState([]);
  const [interventionTypeValue, setInterventionTypeValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getInterventionDetails = (id) => {
    axios.get(Url+"/interventions/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setReferenceValue(res.data.reference);
        setDescriptionValue(res.data.description);
        setInterventionTypeValue(res.data.intervention_type);
        setTechnicalProfileValue(res.data.technical_profile);
        setSelectedTasks(res.data.tasks);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getInterventionDetails(id) : window.location.reload();
      }
      await setLoading(false);
    })
  }

  const getInterventionTypes = async (urlInterventionTypes) => {
    axios.get(urlInterventionTypes, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      await setInterventionTypes(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getInterventionTypes(urlInterventionTypes) : window.location.reload();
      }
    })
  }

  const getTechnicalProfile = async (urlTechnicalProfile) => {
    axios.get(urlTechnicalProfile, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNextTechnicalProfiles(res.data.next);
      if (firstLoadTechnicalProfiles) {
        setTechnicalProfiles([{id: 0, name: "Sem perfil específico"}].concat(res.data.detail));
        setFirstLoadTechnicalProfiles(false);
      } else {
        setTechnicalProfiles(prevTechnicalProfiles => [...prevTechnicalProfiles, ...res.data.detail]);
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTechnicalProfile(urlTechnicalProfile) : window.location.reload();
      }
    })
  }

  const getTasks = async (urlTasks) => {
    axios.get(urlTasks, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
        await setTasks(res.data.detail);
        await setNextTasks(res.data.next);
        await setPreviousTasks(res.data.previous);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTasks(urlTasks) : window.location.reload();
      }
      else {
        await setTasks([]);
        await setNextTasks("");
        await setPreviousTasks("");
      }
    })
  };

  useEffect(() => {
    getTasks(urlTasks);
  }, [urlTasks]);

  useEffect(() => {
    getTechnicalProfile(urlTechnicalProfile);
  }, [urlTechnicalProfile]);

  useEffect(() => {
    getInterventionTypes(urlInterventionTypes);
    if (id) getInterventionDetails(id);
    else setLoading(false);
  }, []);


  const addIntervention = async () => {
    await setAlert();
    if (nameValue === "" || referenceValue === "" || interventionTypeValue === "" || selectedTasks.length === 0){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    var finalTasks = [];
    for (let a = 0; a < selectedTasks.length; a++){
      finalTasks.push(selectedTasks[a].id);
    }
    setSpinner(true);
    await axios
      .post(Url + "/interventions", {
        name: nameValue,
        reference: referenceValue,
        description: descriptionValue,
        intervention_type_id: interventionTypeValue.id,
        technical_profile_id: technicalProfileValue ? (technicalProfileValue.id === 0 ? null : technicalProfileValue.id) : null,
        tasks: finalTasks
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Intervenção criada com sucesso</Alert>);
        setSpinner(false);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addIntervention() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao criar intervenção"}</Alert>);
        }
      });
  }

  const editIntervention = async (id) => {
    await setAlert();
    if (nameValue === "" || referenceValue === "" || interventionTypeValue === "" || selectedTasks.length === 0){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    var finalTasks = [];
    for (let a = 0; a < selectedTasks.length; a++){
      finalTasks.push(selectedTasks[a].id);
    }
    setSpinner(true);
    await axios
      .put(Url + "/interventions/"+id, {
        name: nameValue,
        reference: referenceValue,
        description: descriptionValue,
        intervention_type_id: interventionTypeValue.id,
        technical_profile_id: technicalProfileValue ? technicalProfileValue.id : null,
        tasks: finalTasks
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Intervenção editada com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/trabalhos");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editIntervention(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao editar intervenção"}</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice trabalhos - intervenção #"+id : "backoffice trabalhos - intervenções"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/trabalhos">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder={"Indique o nome da intervenção a adicionar..."}
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={ referenceValue ? styles.inputLabel : styles.inputLabelNull}>Referência</p>
                <input
                    defaultValue={referenceValue}
                    rows={3}
                    placeholder={"Indique a referência da intervenção..."}
                    onChange={(value) => setReferenceValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={styles.inputLabel}>Descrição</p>
                <input
                    defaultValue={descriptionValue}
                    rows={3}
                    placeholder={"Indique uma descrição da intervenção..."}
                    onChange={(value) => setDescriptionValue(value.target.value)}
                />
            </div>
            <Dropdown
              label={"Tipo de intervenção"}
              placeholder={"Escolher tipo de intervenção"}
              itemsWidth={"40vw"}
              items={interventionTypes}
              value={interventionTypeValue}
              onValueChange={setInterventionTypeValue}
              required={true}
            />
            <Dropdown
              label={"Perfil técnico"}
              placeholder={"Escolher perfil técnico"}
              itemsWidth={"40vw"}
              items={technicalProfiles}
              onUrlChange={getTechnicalProfile}
              value={technicalProfileValue}
              onValueChange={setTechnicalProfileValue}
              nextUrl={nextTechnicalProfiles}
              required={false}
            />
            <MultiSelect
              label={"Tarefas"}
              placeholder={"Escolher tarefas"}
              itemsWidth={"50vw"}
              items={tasks}
              onUrlChange={getTasks}
              value={selectedTasks}
              onValueChange={setSelectedTasks}
              required={true}
              pagination={true}
              nextUrl={nextTasks}
              previousUrl={previousTasks}
            />
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editIntervention(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addIntervention()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarIntervencoes;
import React, { useState } from "react";
import Layout from "../../../../../Components/Layout/Layout";
import styles from "./AdicionarPerfisTecnicos.module.css";
import Loading from "../../../../../Components/Loading/Loading";
import Alert from "../../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarPerfisTecnicos({permissions}) {

  const navigate = useNavigate();
  const { id } = useParams();

  const [nameValue, setNameValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getProfileDetails = (id) => {
    axios.get(Url+"/malfunctions/profiles/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getProfileDetails(id) : window.location.reload();
      }
    })
  };

  useEffect(() => {
    if (id) getProfileDetails(id);
    else setLoading(false);
  }, []);

  const addProfile = async () => {
    if (nameValue === ""){
      return;
    }
    await setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/malfunctions/profiles/", {
        name: nameValue,
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(() => {
        setAlert(<Alert type={"success"}>Perfil criado com sucesso</Alert>);
        setTimeout(() => {
          window.location = "/backoffice/utilizadores/perfis";
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addProfile() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar perfil</Alert>);
        }
      });
  }

  const editProfile = async (id) => {
    if (nameValue === "") return;
    await setAlert();
    await setSpinner(true);
    await axios.put(Url + "/malfunctions/profiles/"+id, {
      name: nameValue
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(() => {
      setAlert(<Alert type={"success"}>Perfil editado com sucesso</Alert>);
      setTimeout(() => {
        navigate(0);
      }, 500);
    })
    .catch(async (err) => {
      setSpinner(false);
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? editProfile(id) : window.location.reload();
      }
      else {
        setAlert(<Alert type={"error"}>Erro ao editar perfil</Alert>);
      }
    });
  }

  return (
    <>
      <Layout tab={id ? "backoffice utilizadores perfil #"+id : "backoffice utilizadores perfil"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/utilizadores/perfis">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
              <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do perfil</p>
              <input
                defaultValue={nameValue}
                rows={3}
                placeholder="Indique o nome do perfil a adicionar..."
                onChange={(value) => setNameValue(value.target.value)}
              />
            </div>
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editProfile(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addProfile()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarPerfisTecnicos;
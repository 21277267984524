import React, { useState, useCallback, useRef } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./DetalhesAvarias.module.css";
import GetDate from "../../../Components/GetDate/GetDate";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { HiChevronLeft, HiPencilSquare } from "react-icons/hi2";
import { MdOutlineHistory } from "react-icons/md";
import { IoLocationSharp, IoPersonCircle, IoCheckmarkCircle, IoTime, IoCameraSharp, IoCameraOutline } from "react-icons/io5";
import { FiRotateCw, FiX } from "react-icons/fi";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import ProgressBar from "@ramonak/react-progress-bar";
import ChangeStatusPage from "../../../Components/ChangeStatus/ChangeStatus";
import modal from "../../../Components/Modal/Modal.module.css"
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function DetalhesAvarias({permissions}) {

  const { id } = useParams();
  const location = useLocation();
  const { state } = location;

  const [malfunction, setMalfunction] = useState([]);
  const [users, setUsers] = useState([]);
  const [joinedAlready, setJoinedAlready] = useState(false);
  const [biggerImage, setBiggerImage] = useState(false);
  const [malfunctionImage, setMalfunctionImage] = useState("");

  const [changeStatusModal, setChangeStatusModal] = useState([false, 0, 0]);
  const [loading, setLoading] = useState(true);
  const [showImages, setShowImages] = useState(false);

  const getMalfunction = useCallback(async () => {
    axios.get(Url+"/malfunctions/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setMalfunction(res.data);
      var images = [];
      if (res.data.image.length > 0){
        for (let a = 0; a < res.data.image.length; a++){
          images.push([0, ""]);
        }
      }
      for(let t=0; t< res.data.users.length; t++){
        if (res.data.users[t].id === EncryptDecryptStorage("decrypt").user.id){
          setJoinedAlready(true);
        }
      }
      setUsers(res.data.users);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getMalfunction() : window.location.reload();
      }
      setLoading(false);
    })
  }, [id]);

  const ChosenImage = ({malfunctionImg}) => {

    const img = new Image();
    const [loadingImg, setLoadingImg] = useState(true);
    const [rotation, setRotation] = useState(0);
    const [widthLimiter, setWidthLimiter] = useState(true);
    
    function getImageDimensions(){
      img.src = Url+"/malfunctions/images/"+malfunctionImg;
      img.onload = () => {
        if (img.width > img.height) setWidthLimiter(true)
        else setWidthLimiter(false);
      };
      setLoadingImg(false);
    }

    useEffect(() => {
      getImageDimensions();
    }, []);

    function changeRotation() {
      setWidthLimiter(!widthLimiter);
      if (rotation === 270) return setRotation(0);
      else return setRotation(rotation+90);
    }

    return (
      <div className={modal.body}>
        {
          loadingImg ? ""
          :
          <div className={styles.imgsDiv}>
            <img
              className={styles.malfunctionImgZoomed}
              src={Url+"/malfunctions/images/"+malfunctionImg}
              alt={"img "+malfunctionImg}
              style={{maxWidth: widthLimiter ? "80vw" : "95vh", maxHeight: "95vh", transform: `rotate(${rotation}deg)`}}
            />
            <FiRotateCw
              size={"5vw"}
              className={styles.rotateImage}
              onClick={() => changeRotation()}
            />
            <FiX
              size={"5vw"}
              className={styles.closeImage}
              onClick={() => setBiggerImage(false)}
            />
          </div>
        }
      </div>
    );
  };
  
  

  useEffect(() => {
    getMalfunction();
  }, [getMalfunction]);

  return (
    <>
      <Layout tab={"avaria #"+id} permissions={permissions}>
        {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} url={"/malfunctions/"} type={"avaria"} /> : ""}
        { 
        loading ? <></> :
        <div className={styles.content}>
          {
            biggerImage && malfunctionImage ?
            <ChosenImage malfunctionImg={malfunctionImage}/>
            : ""
          }
          <NavLink className={styles.goBack} to="/avarias" state={state ? {from: state.from ? state.from : "", page: state.page ? state.page : "", previous: state.previous ? state.previous : "", next: state.next ? state.next : "", search: state?.search ?? ""}: ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
            <div className={styles.history} style={{top: ((malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id || (permissions.malfunctions && permissions.malfunctions.PUT))  && malfunction.status.id !== 4) ? "calc(45vh - 34px)" : (permissions.malfunctions && permissions.malfunctions.DELETE) ? "45vh" : "0"}}>
            {
             ((malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id || (permissions.malfunctions && permissions.malfunctions.DELETE)) && malfunction.status.id !== 4) ?
              <NavLink title={"Editar"} to={"/avarias/editar/"+id} state={{from: "detalhes", page: state ? state.page ? state.page : "" : "", previous: state ? state.previous ? state.previous : "" : "", next: state ? state.next ? state.next : "" : "", search: state?.search ?? ""}}>
                <HiPencilSquare size={54} color={"var(--goback-btn)"}/>
              </NavLink>
              : ""
            }
            {
              (permissions.malfunctions && permissions.malfunctions.PUT) ?
              <NavLink title={"Histórico"} to={"/avarias/logs/"+id} state={state ? {from: state.from ? state.from : "", page: state.page ? state.page : "", previous: state.previous ? state.previous : "", next: state.next ? state.next : "", search: state?.search ?? ""}: ""}>
                <MdOutlineHistory size={54} color={"var(--goback-btn)"}/>
              </NavLink>
              : ""
            }
          </div>
          <div className={styles.iconArea}>
            <div className={styles.malfunctionInfo}>
              <h4>
                <div className={styles.priority} style={{backgroundColor: malfunction.priority ? malfunction.priority.color : ""}}>
                  {malfunction.priority ? malfunction.priority.name : ""}
                </div>
                {malfunction.malfunction_areas ? malfunction.malfunction_areas.name : ""} - {malfunction.malfunction_types ? malfunction.malfunction_types.name : ""}
              </h4>
              <div className={styles.iconsAndLabels}>
                <div className={styles.icons}>
                  <IoLocationSharp className={styles.icon} title={"Local"} size={24}/>
                  <IoPersonCircle className={styles.icon} title={"Criada por"} size={24}/>
                  { users.length > 0 ? <IoCheckmarkCircle className={styles.icon} title={"Realizada por"} size={24}/> : ""}
                  <IoTime className={styles.icon} title={"Data de criação"} size={24}/>
                </div>
                <div className={styles.iconsLabels}>
                  <h5 title={"Local"}>{malfunction.local ? malfunction.local.local_name : ""}</h5>
                  <h5 title={"Criada por"}>{malfunction.created_by ? malfunction.created_by.full_name : ""}</h5>
                  { users.length > 0 ? <h5 title={"Realizada por"}>{users.map((item, index) => index !== users.length -1 ? item.first_name+" "+item.last_name+", ": item.first_name+" "+item.last_name )}</h5> : ""}
                  { malfunction.created_at ? <h5 title={"Data de criação"}>{GetDate(malfunction.created_at)}</h5> : ""}
                </div>
              </div>
              {
                malfunction.status ?
                <p style={{color: malfunction.status.color}}>{malfunction.status.name === "Nova" ? "À espera de aprovação ..." : malfunction.status.name === "Aberta" ? "Em curso ..." : malfunction.status.name}</p>
              : ""
              }
              {
                malfunction.status ?
                <ProgressBar completed={malfunction.status.percentage*100} height={"7px"} labelSize={"0px"} bgColor={malfunction.status.color} />
              : ""
              }
              <div className={styles.description}>
                <p>{malfunction.description ? malfunction.description : ""}</p>
              </div>
            </div>
            {
              malfunction.image.length > 0 ?
                <div style={{display: "flex", flexDirection:"column", alignItems: "center", margin: "20px 0"}}>

                  {
                    showImages ?
                    <div className={styles.fotoIcon}>
                      <IoCameraSharp onClick={() => setShowImages(!showImages)} size={50}/>
                      <p>Ver imagens</p>
                    </div>
                    :
                    <div className={styles.fotoIcon}>
                      <IoCameraOutline onClick={() => setShowImages(!showImages)} size={50}/>
                      <p>Ver imagens</p>
                    </div>
                  }
                  {
                  showImages ? 
                    <div className={styles.malfunctionImages}>
                      {
                        malfunction.image.map((malfunctionImg, index) => (
                          <div key={index} style={{position: "relative", margin: "10px 0"}}>
                            <img className={styles.malfunctionImg} onClick={() => {setBiggerImage(true); setMalfunctionImage(malfunctionImg);}} src={Url+"/malfunctions/images/"+malfunctionImg} alt={"img "+malfunctionImg}/>
                          </div>
                        ))
                      }
                    </div>
                  : ""
                  }
                </div>
              : ""
            }
          </div>
          {
            malfunction.status && ((permissions.malfunctions && permissions.malfunctions.PUT) || malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id) ?
            ((malfunction.status.name === "Nova" && (permissions.malfunctions && permissions.malfunctions.PUT)) ?
            <div className={styles.buttons} style={{ width: "20vw" }}>
              <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 2])} type="">APROVAR</div>
            </div>
            :
            (malfunction.status.name === "Fechada" && (joinedAlready || malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id)) ?
            <div className={styles.buttons} style={{ width: "20vw" }}>
              <div className={styles.btn} onClick={() => { setChangeStatusModal([true, id, 6])  }} type="">REABRIR</div>
            </div>
            :
            <div className={styles.buttons} style={{ width: (malfunction.status.name !== "Pausada" && joinedAlready) ? "40vw" : (malfunction.status.name !== "Pausada" && !joinedAlready) ? "60vw" : "20vw", justifyContent: (malfunction.status.name !== "Fechada" && !joinedAlready) ? "center" : "space-between" }}>
              { (malfunction.status.name !== "Fechada" && !joinedAlready && (permissions.malfunctions && permissions.malfunctions.PUT)) ? <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 69])} type="">JUNTAR-SE</div> : ""}
              {joinedAlready ? <div className={styles.btn} onClick={() => { malfunction.status.name === "Pausada" ? setChangeStatusModal([true, id, 5]) : setChangeStatusModal([true, id, 3]) }} type="">{malfunction.status.name === "Pausada" ? "RETOMAR" : "PAUSAR"}</div>:""}
              { malfunction.status.name !== "Pausada" && joinedAlready ? <div className={styles.btn} onClick={() => setChangeStatusModal([true, id, 4])} type="">FECHAR</div> : ""}
            </div>
            )
            : ""
          }
        </div>
        }
      </Layout>
    </>
  );
}

export default DetalhesAvarias;
import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import styles from "./Avarias.module.css";
import GetDate from "../../Components/GetDate/GetDate";
import { useRef, useEffect } from "react";
import axios from "axios";
import { Url } from "../../Constants/globals";
import { NavLink, useLocation, useNavigate  } from "react-router-dom";
import { IoCheckmarkDoneOutline, IoStopwatchOutline, IoPeopleCircleOutline, IoRefreshCircleOutline, IoCheckmarkCircleOutline, IoPlayCircleOutline, IoPerson, IoLocationSharp, IoSearch, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoPauseCircleOutline, IoCloseCircleOutline, IoCamera } from "react-icons/io5";
import { HiPencilSquare, HiOutlineInformationCircle } from "react-icons/hi2";
import { MdAdd, MdSettings } from "react-icons/md";
import ProgressBar from "@ramonak/react-progress-bar";
import RefreshToken from "../../RefreshToken/RefreshToken";
import ChangeStatusPage from "../../Components/ChangeStatus/ChangeStatus";
import getLocal from "../../Components/GetLocalName/GetLocalName";
import { useDebounce } from "../../Components/Debounce/Debounce";
import LoadingAnimationOnly from "../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import { AiOutlineCheckSquare, AiOutlineBorder } from "react-icons/ai";
import EncryptDecryptStorage from "../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Avarias({permissions}) {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [loading, setLoading] = useState(true);
  const [loadingAnimationOnly, setLoadingAnimationOnly] = useState(true);
  const [stopAnimation, setStopAnimation] = useState(true);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [selectedBuilding, setSelectedBuilding] = useState(localStorage.getItem("malfunctionsBuilding") ? parseInt(localStorage.getItem("malfunctionsBuilding")) : "");
  const [buildings, setBuildings] = useState([]);
  const urlBuildings = Url + "/clients/buildings/?start=0&limit=50";

  const [onlyMe, setOnlyMe] = useState(state?.from === "my" ? true : false);
  const [malfunctionStatus, setMalfunctionStatus] = useState(state ? state.from ? state.from : "novas" : "novas");
  const [malfunctionStatusId, setMalfunctionStatusId] = useState(state ? (state.from === "novas" ? "1" : state.from === "ativas" ? "2,5" : state.from === "pausadas" ? "3" : state.from === "fechadas" ? "4" : state.form === "my" ? "0" : "1") : "1");
  const [malfunctionsData, setMalfunctionsData] = useState([]);
  const [malfunctionCount, setMalfunctionCount] = useState(0);
  const [current, setCurrent] = useState(state?.page ? state.page : 1);
  const [next, setNext] = useState(state ? (state.next ? state.next : "") : "");
  const [previous, setPrevious] = useState(state ? (state.previous ? state.previous : "") : "");
  const [chosenIdModal, setChosenIdModal] = useState(null);
  const [searchName, setSearchName] = useState(state?.search ?? "");
  const debouncedSearchName = useDebounce(searchName, 250);
  const [changeStatusModal, setChangeStatusModal] = useState([false, 0, 0]);

  const [malfunctionFilters, setMalfunctionFilters] = useState(false);
  const [toggleProfiles, setToggleProfiles] = useState(localStorage.getItem("malfunctionProfiles") === "false" ? false : true);
  const [autoRefresh, setAutoRefresh] = useState(localStorage.getItem("autoRefreshMalfunctions") === "true" ? true : false);

  // Deteta quando clica fora do modal de ações para fechá-lo
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setChosenIdModal(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getBuildings = async (urlBuildings) => {
    try {
      const res = await axios.get(urlBuildings, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        },
      })
      const locais = [{id: "", name: "Todos"}, ...res.data.detail]
      setBuildings(locais);
    }
    catch (err) {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getBuildings(urlBuildings) : window.location.reload();
      }
      else {
        setBuildings([]);
      }
    }
  };

  const getMalfunctions = async(notLoading, page, search, onlyMe, status, showProfile, buildingId) => {
    if (!notLoading){
      setLoadingAnimationOnly(true);
      setMalfunctionCount("");
    }
    const finalUrl = onlyMe ? `${Url}/malfunctions/only-me/?per_page=15` : `${Url}/malfunctions?per_page=15${buildingId ? `&building_id=${buildingId}` : ``}&status=${status ? status : 1}`
    axios.get(`${finalUrl}&page=${page ?? 1}${search ? `&name=${debouncedSearchName}` : ``}${showProfile ? `` : `&profile=0`}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      setMalfunctionCount(res.data.count);
      setMalfunctionsData(res.data.detail);
      setCurrent(res.data.current);
      setNext(res.data.next);
      setPrevious(res.data.previous);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getMalfunctions(notLoading, page, search, onlyMe, status, showProfile, buildingId) : window.location.reload();
      }
      else {
        setNext("");
        setPrevious("");
        setMalfunctionsData([]);
        setMalfunctionCount(0);
      }
    })
    .finally(async() => {
      if (!notLoading){
        setLoading(false);
        await setStopAnimation(false);
        await setTimeout(() => {
          setLoadingAnimationOnly(false);
        }, 300);
      }
    });
  }

  const searchData = (name) => {
    setStopAnimation(true);
    setSearchName(name);
  }

  useEffect(() => {
    getBuildings(urlBuildings);
    getMalfunctions(false, current, searchName, onlyMe, malfunctionStatusId, toggleProfiles, selectedBuilding);
  }, []);

  const handlePageChange = (page) => {
    setLoadingAnimationOnly(true);
    getMalfunctions(false, page, searchName, onlyMe, malfunctionStatusId, toggleProfiles, selectedBuilding); 
  }

  const handleStatusChange = (name, id, building) => {
    if (building){
      setSelectedBuilding(id ? id : "");
      localStorage.setItem("malfunctionsBuilding", id);
      return getMalfunctions(false, 1, searchName, onlyMe, malfunctionStatusId, toggleProfiles, id);
    }
    else {
      setStopAnimation(false);
      setMalfunctionStatus(name);
      setMalfunctionStatusId(id);
      if (name === "my"){
        setOnlyMe(true);
        getMalfunctions(false, 1, searchName, true, id, toggleProfiles, selectedBuilding);
      }
      else {
        setOnlyMe(false);
        getMalfunctions(false, 1, searchName, false, id, toggleProfiles, selectedBuilding);
      }
    }
  }

  useEffect(() => {
    if (autoRefresh) {
      // Use useEffect to start the interval when the component mounts
      const intervalId = setInterval(() => {
        // Call getMalfunctions here to run it at the specified interval
        getMalfunctions(false, current, searchName, onlyMe, malfunctionStatusId, toggleProfiles, selectedBuilding);
      }, 10000); // 10,000 milliseconds = 10 seconds
  
      // Return a cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [autoRefresh]);
  

  const handle3dots = (e, id) => {
    setStopAnimation(true);
    e.preventDefault();
    setChosenIdModal(id);
  }

  const handleCheckMalfunction = (e, id, value) => {
    e.preventDefault();
    axios
      .put(Url + "/malfunctions/"+id, {
        is_checked: !value,
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        getMalfunctions(true, current, searchName, onlyMe, malfunctionStatusId, toggleProfiles, selectedBuilding);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? handleCheckMalfunction(e, id, value) : window.location.reload();
        }
      });
  }

  const MalfunctionsList = () => {
    return (
      <>
        <div className={styles.malfunctionSection}>
          <p>Tarefa</p>
          <p>Local</p>
          <p>Detalhes</p>
        </div>
        {
          malfunctionsData.map((malfunction, index) => (
          <div key={index} className={stopAnimation ? "" : styles.fade}>
            <NavLink className={styles.malfunctionContainer} to={"/avarias/detalhes/"+malfunction.id} state={{from: malfunctionStatus ? malfunctionStatus : "", page: current, previous: previous, next: next, search: searchName ?? ""}}>
              <div className={styles.iconArea}>
                {
                  malfunction.status.id === 4 ?
                  <div className={styles.imgAndCheck}>
                    <img src={(malfunction.status.name === "Aberta" || malfunction.status.name === "Reaberta") ? "/warning.png" : "/warning_red.png"} alt="" width={24} height={24}/>
                    {
                      permissions.malfunctions &&
                      permissions.malfunctions.DELETE ?
                      malfunction.is_checked ?
                      <IoCheckmarkDoneOutline title={"Conferida"} size={20} color="#21ba45" onClick={(e) => handleCheckMalfunction(e, malfunction.id, malfunction.is_checked)}/>
                      :
                      <IoStopwatchOutline title={"Não conferida"} size={20} color="#ffab40" onClick={(e) => handleCheckMalfunction(e, malfunction.id, malfunction.is_checked)}/>
                      : ""
                    }
                  </div>
                  :
                    <img src={(malfunction.status.name === "Aberta" || malfunction.status.name === "Reaberta") ? "/warning.png" : "/warning_red.png"} alt="" width={24} height={24}/>
                }
                <div className={styles.malfunctionInfo}>
                  <h4>{malfunction.malfunction_area ? malfunction.malfunction_area.name : ""} - {malfunction.malfunction_type ? malfunction.malfunction_type.name : ""}</h4>
                  <div className={styles.priority} style={{backgroundColor: malfunction.priority.color}}>
                    {malfunction.priority.name}
                  </div>
                  <p style={{color: malfunction.status.color}}>{malfunction.status.name === "Nova" ? "À espera de aprovação ..." : (malfunction.status.name === "Aberta" || malfunction.status.name === "Reaberta") ? "Em curso ..." : malfunction.status.name}</p>
                  <ProgressBar completed={malfunction.status.percentage*100} height={"7px"} labelSize={"0px"} bgColor={malfunction.status.color} />
                </div>
              </div>
              <div className={styles.iconArea}>
                <div style={{width: "24px", display: "flex", flexDirection:"column", gap:"11px", marginLeft: "10px"}}>
                  <IoLocationSharp size={19}/>
                  {
                    malfunction.has_image > 0 ?
                    <IoCamera title={"Inclui imagens"} size={19} color={"#888"}/>
                    : ""
                  }
                </div>
                <div className={styles.malfunctionInfo}>
                  <h4 style={{letterSpacing: "0.4px"}}>{getLocal(malfunction.local.building, malfunction.local.blocks, malfunction.local.floors, malfunction.local.local)}</h4>
                  <div className={styles.createdbyAndDate}>
                    <p>Criado por: {malfunction.created_by.name}</p>
                    <p>{GetDate(malfunction.created_at)}</p>
                  </div>
                </div>
              </div>
              <div className={styles.malfunctionDetails}>
                <div className={styles.description}>
                  <p>{malfunction.description.length > 200 ? malfunction.description.slice(0, 201)+"..." : malfunction.description }</p>
                </div>
                <ThreeDotsModal malfunction={malfunction}/>
              </div>
            </NavLink>
          </div>
        ))
        }
        <div className={styles.changePages}>
            {previous ? <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previous) }}/> : ""}
            {next ? <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(next) }}/> : ""}
        </div>
      </>
    );
  }

  const ThreeDotsModal = ({malfunction}) => {
    let modalOptions = [];
    if ((malfunction.status.id === 2 || malfunction.status.id === 5) && (malfunction.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 4])}>
          <IoCloseCircleOutline color="#21ba45" size={22}/>
          Fechar
        </div>
      );
    }
    if ((malfunction.status.id === 2 || malfunction.status.id === 5) && (malfunction.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 3])}>
          <IoPauseCircleOutline color="#ffab40" size={22}/>
          Pausar
        </div>
      );
    }
    if (malfunction.status.id === 3 && (malfunction.is_user)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 5])}>
          <IoPlayCircleOutline color="#21ba45" size={22}/>
          Retomar
        </div>
      );
    }
    if ( malfunction.status.id === 1 && (permissions.malfunctions && permissions.malfunctions.PUT)){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 2])}>
          <IoCheckmarkCircleOutline color="#21ba45" size={22}/>
          Aprovar
        </div>
      );
    }
    if ([2,3,5].includes(malfunction.status.id) && (permissions.malfunctions && permissions.malfunctions.PUT) && !malfunction.is_user){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 69])}>
          <IoPeopleCircleOutline color="rgb(40, 190, 225)" size={22}/>
          Juntar-se
        </div>
      );
    }
    if (malfunction.status.id !== 4 && ((malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id) || (permissions.malfunctions && permissions.malfunctions.DELETE))){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => navigate("/avarias/editar/"+malfunction.id, { state:{from: malfunctionStatus ? malfunctionStatus : "", page: current, previous: previous, next: next, search: searchName ?? ""}})}>
          <HiPencilSquare color="#529eff" size={18} style={{margin: "2px"}}/>
          Editar
        </div>
      );
    }
    if (malfunction.status.id === 4 && (malfunction.is_user || (malfunction.created_by.id === EncryptDecryptStorage("decrypt").user.id))){
      modalOptions.push(
        <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 6])}>
          <IoRefreshCircleOutline color="#529eff" size={22} style={{margin: "2px"}}/>
          Reabrir
        </div>
      );
    }
    if (modalOptions.length > 0){
      return (
        <div className={styles.actions} onClick={(e) =>  handle3dots(e, malfunction.id) }>
          <img src="/3dots.svg" height={20} alt=""/>
          {
            chosenIdModal === malfunction.id ?
            <div ref={wrapperRef} className={styles.actionsModal}>
              {modalOptions}
            </div>
          : <></>
          }
        </div>
      );
    }
    else {
      return (
        <div>
        </div>
      );
    }
  }

  return (
    <>
      <Layout tab={"avarias"} permissions={permissions} >
      {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} url={"/malfunctions/"} type={"avaria"} /> : ""}
        { loading ? <></> :
        <div className={styles.content}>
          {
            buildings.length > 2 ?
              <div className={styles.buildings}>
                {
                  buildings.map((building, index) =>
                    <div key={index} className={ selectedBuilding === building.id ? styles.activeStatusBuilding : styles.statusBuilding } onClick={() => { handleStatusChange(building.name, building.id, true); }}>
                      {building.name}
                    </div>
                  )
                }
              </div>
            :""
          }
          <div className={styles.searchBar}>
            <div className={styles.malfunctionsStatus}>
              {
                permissions.malfunctions &&
                (permissions.malfunctions.PUT) ?
                <div title="As minhas avarias" className={ malfunctionStatus === "my" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("my", "0"); }}>
                  {malfunctionStatus === "my" ? malfunctionCount + " " : ""}
                  <IoPerson style={{marginLeft: "3px"}}/>
                </div>
                : ""
              }
              <div className={ malfunctionStatus === "novas" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("novas", "1"); }}>
                {malfunctionStatus === "novas" ? malfunctionCount + " " : ""}
                Novas
              </div>
              <div className={ malfunctionStatus === "ativas" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("ativas", "2,5"); }}>
                {malfunctionStatus === "ativas" ? malfunctionCount + " " : ""}
                Ativas
              </div>
              <div className={ malfunctionStatus === "pausadas" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("pausadas", "3"); }}>
                {malfunctionStatus === "pausadas" ? malfunctionCount + " " : ""}
                Pausadas
              </div>
              <div className={ malfunctionStatus === "fechadas" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("fechadas", "4"); }}>
                {malfunctionStatus === "fechadas" ? malfunctionCount + " " : ""}
                Fechadas
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
                <NavLink className={styles.addMalfunction} title="Adicionar avaria" to={"/avarias/adicionar"} state={{from: malfunctionStatus ? malfunctionStatus : ""}}>
                  <MdAdd className={styles.addIcon} size={22} />
                </NavLink>
                <div className={styles.search}>
                  <IoSearch className={styles.searchIcon}  />
                  <input
                    placeholder={"Clique enter para pesquisar"}
                    defaultValue={searchName ?? ""}
                    onChange={(e) => searchData(e.target.value) }
                    onKeyDown={(e) => {
                      if (e.key === "Enter"){
                        getMalfunctions(false, 1, searchName, onlyMe, malfunctionStatusId, toggleProfiles, selectedBuilding);
                      }
                    }}
                  />
                </div>
                <div className={styles.settingsMalfunctions} title="Definições" onClick={() => setMalfunctionFilters(!malfunctionFilters)}>
                  <MdSettings className={styles.addIcon} color={"var(--text-primary)"} size={22} />
                </div>
              </div>
              {
                malfunctionFilters ?
                <div>
                  {
                    malfunctionStatus !== "my" || searchName !== "" ?
                      <div style={{display: "flex", justifyContent: "flex-end" }}>
                        <p className={styles.checked} onClick={() => {localStorage.setItem("malfunctionProfiles", !toggleProfiles); getMalfunctions(false, 1, searchName, onlyMe, malfunctionStatusId, !toggleProfiles, selectedBuilding); setToggleProfiles(!toggleProfiles);}}>Mostrar também avarias com perfil técnico{toggleProfiles ? <AiOutlineCheckSquare size={19} style={{transition: "0.3s ease-in-out"}}/> : <AiOutlineBorder size={19}/>}</p>
                      </div>
                    : ""
                  }
                  <div style={{display: "flex", justifyContent: "flex-end" }}>
                    <p className={styles.checked} onClick={() => {localStorage.setItem("autoRefreshMalfunctions", !autoRefresh); setAutoRefresh(!autoRefresh);}}>Atualizar automaticamente a cada 10 segundos{autoRefresh ? <AiOutlineCheckSquare size={19} style={{transition: "0.3s ease-in-out"}}/> : <AiOutlineBorder size={19}/>}</p>
                  </div>
                </div>
                :""
              }
            </div>
          </div>
          {
            loadingAnimationOnly ? <LoadingAnimationOnly/> :
            malfunctionsData.length > 0 ?
            <div id="scrollMalfunctions" style={{overflowY: "scroll", height: "calc(100vh - 3rem - 190px)" }}>
              <MalfunctionsList/>
            </div>
            :
            <div className={styles.noData}>
              <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
              <p className={styles.noDataText}>{malfunctionStatus === "my" ? "Não existem avarias aprovadas por si" : "Não existem avarias "+malfunctionStatus}</p>
            </div>
          }
        </div>
        }
      </Layout>
    </>
  );
}

export default Avarias;
import React, { useState, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./Locais.module.css";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import getLocal from "../../../Components/GetLocalName/GetLocalName";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Locais({permissions}) {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalLocal, setDeleteModalLocal] = useState(false);

  const [urlLocals, setUrlLocals] = useState(Url+"/locals?start=0&limit=15");
  const [locals, setLocals] = useState([]);
  const [localValue, setLocalValue] = useState("");
  const [previousLocals, setPreviousLocals] = useState("");
  const [nextLocals, setNextLocals] = useState("");

  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);

  const getLocals = useCallback(async (urlLocals) => {
    axios.get(urlLocals, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setLocals(res.data.detail);
      setNextLocals(res.data.next);
      setPreviousLocals(res.data.previous);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocals(urlLocals) : window.location.reload();
      }
      else {
        setLocals([]);
        setNextLocals("");
        setPreviousLocals("");
        setLoading(false);
      }
    })
  }, []);

  const LocalsList = () => {
    return (
        <>
        {
          locals.map((local) => (
              <div className={styles.localsContainer} key={local.local.id}>
                <p>
                    {getLocal(local.building.name, local.block ? local.block.name : "", local.floor ? local.floor.name : "", local.local.name)}
                </p>
                <div className={styles.localsActions}>
                    {
                      permissions.locals && permissions.locals.PUT ?
                      <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/locais/editar/"+local.local.id}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                      : ""
                    }
                    {
                      permissions.locals && permissions.locals.DELETE ?
                      <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => {setLocalValue(local.local); setDeleteModalLocal(true)}}/>
                      : ""
                    }
                </div>
              </div>
        ))
        }
      </>
    );
  }

  const searchData = (name) => {
    setSearchName(name);
  }

  const handlePageChange = (url) => {
    setUrlLocals(url);
  }

  const deleteLocal = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/locals/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Local apagado com sucesso</Alert>);
        setTimeout(() => {
          setLocalValue("");
          setSpinner(false);
          setUrlLocals(Url+"/locals?start=0&limit=15");
          getLocals(Url+"/locals?start=0&limit=15");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteLocal(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar local</Alert>);
      });
  }

  useEffect(() => {
    if (debouncedSearchName.length > 0) {
      setUrlLocals(Url+"/locals?name="+debouncedSearchName+"&start=0&limit=15");
    }
    else {
      setUrlLocals(Url+"/locals?start=0&limit=15");
    }
  }, [debouncedSearchName]);

  useEffect(() => {
    getLocals(urlLocals);
  }, [urlLocals]);

  return (
    <>
      <Layout tab={"backoffice locais"} permissions={permissions}>
        {alert}
        {deleteModalLocal ? <DeleteModal onClose={setDeleteModalLocal} headerText={"ELIMINAR LOCAL"} confirmationText={"Deseja eliminar "+localValue.name+" ?"} deleteFunction={deleteLocal} objectId={localValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <p className={styles.localTitle} style={{marginTop: "15px"}}>Locais<NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/locais/adicionar"}></NavLink></p>
            <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              {
                permissions.locals && permissions.locals.POST ?
                <NavLink className={styles.addLocal} to={"/backoffice/locais/adicionar"}>
                  <MdAdd className={styles.addIcon} size={22} />
                </NavLink>
                : ""
              }
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
            </div>
            <div className={styles.dropdown}>
              <LocalsList/>
            </div>
            <div className={styles.changePages}>
                {previousLocals === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousLocals) }}/>}
                {nextLocals === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextLocals) }}/>}
        </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default Locais;
import React, { useState, useCallback } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./DetalhesTrabalhos.module.css";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import GetDate from "../../../../Components/GetDate/GetDate";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function LogsTrabalhos({permissions}) {

  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getJob = useCallback(() => {
    axios.get(Url+"/jobs/schedules/"+id+"/logs", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setLogs(res.data);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getJob() : window.location.reload();
      }
      setLoading(false);
    })
  }, [id]);

  useEffect(() => {
    getJob();
  }, []);

  return (
    <>
      <Layout tab={"manutenção - trabalhos #"+id} permissions={permissions}>
        { 
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to={"/manutencao/trabalhos/detalhes/"+id} state={{from: state ? state.from : "", page: state?.page ? state.page : ""}}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.logs}>
          {
            logs.map((log, index) => (
              <div key={index} className={styles.log} style={{backgroundColor: index % 2 === 0 ? "var(--background-content)" : "" }}>
                <p>{GetDate(log.date)}</p>
                {log.log}
              </div>
            ))
          }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default LogsTrabalhos;
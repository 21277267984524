import React, { useState, useEffect, useRef, useCallback } from "react";

import styles from "./EstatisticasTrabalhos.module.css";
import Loading from "../../../Components/Loading/Loading";
import { NavLink, useNavigate } from "react-router-dom";
import Alert from "../../../Components/Alert/Alert";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { HiChevronLeft, HiChevronRight, HiOutlineInformationCircle } from "react-icons/hi";
import { FiFilter, FiDownload } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch } from "react-icons/io5";
import Layout from "../../../Components/Layout/Layout";
import LoadingAnimationOnly from "../../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryArea } from 'victory';
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import stylesLocals from "../../Backoffice/Locais/Locais.module.css";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";

function EstatisticasTrabalhos({permissions}) {

	function getMonthDigits(m){
		return m.toString().length === 1 ? `0${m}` : m;
	}

	const navigate = useNavigate();

  const [estatisticasTrabalhos, setEstatisticasTrabalhos] = useState({});
  const [spinner, setSpinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [statisticType, setStatisticType] = useState(localStorage.getItem("jobStatistic") ?? "stats");

  const [alert, setAlert] = useState(false);

  const months = [{id: 1,name: "Janeiro"},{id: 2,name: "Fevereiro"},{id: 3,name: "Março"},{id: 4,name: "Abril"},{id: 5,name: "Maio"},{id: 6,name: "Junho"},{id: 7,name: "Julho"},{id: 8,name: "Agosto"},{id: 9,name: "Setembro"},{id: 10,name: "Outubro"},{id: 11,name: "Novembro"},{id: 12,name: "Dezembro"}];

  const [showModalFinalDate, setShowModalFinalDate] = useState(false);
  const [finalMonthValue, setFinalMonthValue] = useState(months.find(month => month.id === new Date().getMonth() + 1));
  const [finalYearValue, setFinalYearValue] = useState(new Date().getFullYear());

  const [initialYearValue, setInitialYearValue] = useState(new Date().getFullYear());
  const [showModalInitialDate, setShowModalInitialDate] = useState(false);
  const [initialMonthValue, setInitialMonthValue] = useState(months.find(month => month.id === new Date().getMonth() + 1));

  const [urlEstatisticasTrabalhos, setUrlEstatisticasTrabalhos] = useState(Url+"/statistics/jobs?date_start="+initialYearValue+"-"+getMonthDigits(initialMonthValue.id)+"-01&date_end="+finalYearValue+"-"+getMonthDigits(finalMonthValue.id)+"-01");
  
  const years = [new Date().getFullYear()-5, new Date().getFullYear()-4, new Date().getFullYear()-3, new Date().getFullYear()-2,new Date().getFullYear()-1,new Date().getFullYear() ];

  const wrapperRefMonths = useRef(null);
  useOutsideAlerter(wrapperRefMonths);
  const wrapperRefYears = useRef(null);
  useOutsideAlerter(wrapperRefYears);

  const [deleteModalFormula, setDeleteModalFormula] = useState(false);

  const [formulas, setFormulas] = useState([]);
  const [formulaValue, setFormulaValue] = useState("");
  const [previousFormulas, setPreviousFormulas] = useState("");
  const [nextFormulas, setNextFormulas] = useState("");

  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);

  const getFormulas = async (page, search) => {
    axios.get(`${Url}/apiV2/formulas?page=${page ? page : 1}&per_page=15${search ? `&search=${search}` : ""}`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setFormulas(res.data.data);
      setNextFormulas(res.data.next_page);
      setPreviousFormulas(res.data.prev_page);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getFormulas() : window.location.reload();
      }
      else {
        setFormulas([]);
        setNextFormulas("");
        setPreviousFormulas("");
      }
    })
  };

  // Deteta quando clica fora do modal de ações para fechá-lo
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowModalInitialDate(false);
          setShowModalFinalDate(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getEstatisticasTrabalhos = useCallback(() => {
    setLoading(true);
    setSpinner(true);
    axios.get(urlEstatisticasTrabalhos, {
      headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
      setEstatisticasTrabalhos(res.data);
      await setLoading(false);
      await setSpinner(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getEstatisticasTrabalhos() : window.location.reload();
      }
      else {
        setEstatisticasTrabalhos("");
        setLoading(false);
        await setSpinner(false);
      }
    })
  }, [urlEstatisticasTrabalhos, setEstatisticasTrabalhos]);

  useEffect(() => {
    getEstatisticasTrabalhos();
  }, [urlEstatisticasTrabalhos, getEstatisticasTrabalhos, navigate]);

  function getFormulaLabel(f){
    if (f?.length > 0){
      let finalAnswer = "";
      const fo = JSON.parse(f);
      for (const formu of fo){
        finalAnswer += `${formu.label} `;
      }
      return finalAnswer;
    }
    else return "";
  }

  useEffect(() => {
    getFormulas(1, debouncedSearchName);
  }, [debouncedSearchName]);

  const searchData = (name) => {
    setSearchName(name);
  }

  const FormulasList = () => {
    return (
      <>
        {
          formulas.map((formula) => (
            <div className={stylesLocals.localsContainer} key={formula.id}>
              <p title={getFormulaLabel(formula.formula)}>
                {formula.name}
              </p>
              <div className={stylesLocals.localsActions}>
                <NavLink style={{color: "var(--text-primary)"}} to={"/estatisticas/trabalhos/editar-formula/"+formula.id}><IoPencilSharp className={stylesLocals.hoverEdit} size={20}/></NavLink>
                <IoTrashSharp className={stylesLocals.hoverDelete} size={20} onClick={() => {setFormulaValue(formula); setDeleteModalFormula(true)}}/>
              </div>
            </div>
          ))
        }
      </>
    );
  }

  const handleFilter = async () => {
    await setAlert("");
    if (initialYearValue > finalYearValue) return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    else if (initialYearValue === finalYearValue && initialMonthValue.id > finalMonthValue.id) return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    setUrlEstatisticasTrabalhos(Url+"/statistics/jobs?date_start="+initialYearValue+"-"+getMonthDigits(initialMonthValue.id)+"-01&date_end="+finalYearValue+"-"+getMonthDigits(finalMonthValue.id)+"-01");
  }

  function getFileName() {
    const hotelName = EncryptDecryptStorage("decrypt").user.client.display_name;
    let initialMonth = initialMonthValue.id < 10 ? `0${initialMonthValue.id}` : initialMonthValue.id;
    let finalMonth = finalMonthValue.id < 10 ? `0${finalMonthValue.id}` : finalMonthValue.id;
    
    const finalDates = `${initialMonth}${initialYearValue}` === `${finalMonth}${finalYearValue}` ? `${initialYearValue}-${initialMonth}` : `${initialYearValue}-${initialMonth}_${finalYearValue}-${finalMonth}`;

    return `Trabalhos ${hotelName} ${finalDates}`;
  }

  const downloadExcelFile = async (downloadLink) => {
    await setLoadingDownload(true);
    await setAlert("");

    if (initialYearValue > finalYearValue) return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    else if (initialYearValue === finalYearValue && initialMonthValue.id > finalMonthValue.id) return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    
    try {
      const response = await fetch(downloadLink, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          var newToken = await RefreshToken(response.status);
          newToken ? downloadExcelFile(downloadLink) : window.location.reload();
        }
        if (response.status === 404) return setAlert(<Alert type={"error"}>{"Data inválida"}</Alert>);
        else return setAlert(<Alert type={"error"}>{"Ocorreu um erro"}</Alert>);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getFileName());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
      if (error.request && error.request.status === 401){
        var newToken2 = await RefreshToken(error);
        newToken2 ? downloadExcelFile(downloadLink) : window.location.reload();
      }
      else return setAlert(<Alert type={"error"}>{error?.response?.data?.detail ? error.response.data.detail : "Ocorreu um erro ao exportar o documento"}</Alert>);
    } finally {
      setLoadingDownload(false);
    }
  };

  const deleteFormula = async (id) => {
    await setAlert();
    await axios.delete(Url + "/apiV2/formulas/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(async () => {
      setAlert(<Alert type={"success"}>Fórmula eliminada com sucesso</Alert>);
      getFormulas();
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? deleteFormula(id) : window.location.reload();
      }
      setAlert(<Alert type={"error"}>Erro ao eliminar fórmula</Alert>);
    });
  }

  return (
    <>
      <Layout tab={"estatísticas - trabalhos"} permissions={permissions}>
        {spinner ? <LoadingAnimationOnly /> : ""}
        {loadingDownload ? <Loading warningText="Aguarde, a exportação poderá demorar alguns minutos..."/> : ""}
        {deleteModalFormula ? <DeleteModal onClose={setDeleteModalFormula} headerText={"ELIMINAR FÓRMULA"} confirmationText={"Deseja eliminar "+formulaValue.name+" ?"} deleteFunction={deleteFormula} objectId={formulaValue.id}/> : ""}
        {alert}
        {
          loading ? <></>
          :
            <div className={styles.content}>
              <div className={styles.tabs}>
                <div className={ statisticType === "stats" ? styles.activeStatus : styles.status } onClick={() => { localStorage.setItem("jobStatistic" ,"stats"); setStatisticType("stats"); }}>
                  Estatística
                </div>
                <div className={ statisticType === "formulas" ? styles.activeStatus : styles.status } onClick={() => { localStorage.setItem("jobStatistic" ,"formulas"); setStatisticType("formulas"); }}>
                  Tratamento de dados
                </div>
              </div>
              {
                statisticType === "stats" ?
                  <div>
                    <div className={styles.header}>                  
                      <div className={styles.monthAndYear}>
                        {
                          permissions.costs && permissions.costs.POST ?
                            <button className={styles.searchButton} title="Exportar Excel" onClick={() => { downloadExcelFile(Url+`/files/jobs?date_start=${initialYearValue}-${initialMonthValue.id < 10 ? `0${initialMonthValue.id}` : initialMonthValue.id}-01&date_end=${finalYearValue}-${finalMonthValue.id < 10 ? `0${finalMonthValue.id}` : finalMonthValue.id}-01`);}}><FiDownload size={16}/>Exportar</button>
                          :""
                        }
                        <div style={{display: "flex", gap: "10px"}}>
                          <div>
                            <p className={styles.labelMonths}>Mês inicial</p>
                            <div className={styles.chosenDate} onClick={() => setShowModalInitialDate(true)}>
                              <p>{initialMonthValue.name} {initialYearValue}</p>
                            </div>
                          </div>
                          <div>
                            <p className={styles.labelMonths}>Mês final</p>
                            <div className={styles.chosenDate} onClick={() => setShowModalFinalDate(true)}>
                              <p>{finalMonthValue.name} {finalYearValue}</p>
                            </div>
                          </div>
                        </div>
                        <div style={{display: "flex"}}>
                          <button className={styles.searchButton} title="Filtrar" onClick={() =>  handleFilter() }><FiFilter size={16}/>Filtrar</button>
                        </div>
                      </div>
                    </div>
                    {
                      estatisticasTrabalhos ?
                        <div className={styles.graphs}>
                          <div>
                            <h4 className={styles.graphTitle}>Utilizadores com mais trabalhos concluídos<br/>
                              <label>{estatisticasTrabalhos.date.start} - {estatisticasTrabalhos.date.end}</label>
                            </h4>
                            <div className={styles.graph}>
                              {
                                estatisticasTrabalhos.most_active_users.length > 0 ?
                                  <VictoryChart
                                    animate={{
                                      duration: 500,
                                      onLoad: { duration: 500 }
                                    }}
                                    domainPadding={{x: estatisticasTrabalhos.most_active_users.length <= 3 ? 100 : 30}}
                                  >
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:8}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                    />
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                      dependentAxis
                                    />
                                    <VictoryBar
                                      barRatio={estatisticasTrabalhos.most_active_users.length === 1 ? 10: 0.8}
                                      style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                      labelComponent={<VictoryLabel dy={-3}/>}
                                      labels={({ datum }) => datum.quantity}
                                      data={estatisticasTrabalhos.most_active_users}
                                      x="name"
                                      y="quantity"
                                      cornerRadius={{
                                        top: 3
                                      }}
                                    />
                                  </VictoryChart>
                                :
                                  <div className={styles.noData}>
                                    <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                    <p className={styles.noDataText}>Sem dados</p>
                                  </div>
                              }
                            </div>
                          </div>
                          <div>
                            <h4 className={styles.graphTitle}>Periodicidade de trabalhos<br/>
                              <label>{estatisticasTrabalhos.date.start} - {estatisticasTrabalhos.date.end}</label>
                            </h4>
                            <div className={styles.graph}>
                              {
                                estatisticasTrabalhos.quantity_of_periodicities.length > 0 ?
                                  <VictoryChart
                                    animate={{
                                      duration: 500,
                                      onLoad: { duration: 500 }
                                    }}
                                    domainPadding={{x: estatisticasTrabalhos.quantity_of_periodicities.length <= 3 ? 100 : 30}}
                                  >
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:8}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                    />
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                      dependentAxis
                                    />
                                    <VictoryBar
                                      barRatio={estatisticasTrabalhos.quantity_of_periodicities.length === 1 ? 10: 0.8}
                                      style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                      labelComponent={<VictoryLabel dy={-3}/>}
                                      labels={({ datum }) => datum.quantity}
                                      data={estatisticasTrabalhos.quantity_of_periodicities}
                                      x="name"
                                      y="quantity"
                                      cornerRadius={{
                                        top: 3
                                      }}
                                    />
                                  </VictoryChart>
                                :
                                  <div className={styles.noData}>
                                    <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                    <p className={styles.noDataText}>Sem dados</p>
                                  </div>
                              }
                            </div>
                          </div>
                          <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                            <h4 className={styles.graphTitle}>Locais de trabalhos mais frequentes<br/>
                              <label>{estatisticasTrabalhos.date.start} - {estatisticasTrabalhos.date.end}</label>
                            </h4>
                            <div className={styles.graph}>
                              {
                                estatisticasTrabalhos.local_with_most_jobs.length > 0 ?
                                  <VictoryChart
                                    width={900}
                                    animate={{
                                      duration: 500,
                                      onLoad: { duration: 500 }
                                    }}
                                    domainPadding={{x: estatisticasTrabalhos.local_with_most_jobs.length <= 3 ? 100 : 60}}>
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato", angle:3}, axisLabel: {fontSize: "10", fill: "#888"} }}
                                    />
                                    <VictoryAxis
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10 }, }}
                                      dependentAxis
                                    />
                                    <VictoryBar
                                      barRatio={estatisticasTrabalhos.local_with_most_jobs.length === 1 ? 10 : 0.8}
                                      style={{ data: { fill: "var(--text-primary)" }, labels: { fill: "#888", fontSize: "10" }}}
                                      labelComponent={<VictoryLabel dy={-3}/>}
                                      labels={({ datum }) => datum.quantity}
                                      data={estatisticasTrabalhos.local_with_most_jobs}
                                      x="name"
                                      y="quantity"
                                      cornerRadius={{
                                        top: 3
                                      }}
                                    />
                                  </VictoryChart>
                                :
                                  <div className={styles.noData}>
                                    <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                    <p className={styles.noDataText}>Sem dados</p>
                                  </div>
                              }
                            </div>
                          </div>
                          <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                            <h4 className={styles.graphTitle}>Trabalhos concluídos semanalmente<br/>
                              <label>{estatisticasTrabalhos.date.start} - {estatisticasTrabalhos.date.end}</label><br/>
                              <label style={{fontSize: "9pt"}}>• Concluídos</label>
                            </h4>
                            <div className={styles.graph}>
                              {
                                estatisticasTrabalhos.job_schedules_per_week.length > 0 ?
                                  <VictoryChart
                                    width={900}
                                    domainPadding={40}
                                  >
                                    <VictoryArea
                                      style={{
                                        data: {
                                          fill: "var(--text-primary)", fillOpacity: 0.6, stroke: "var(--text-primary)", strokeWidth: 2
                                        },
                                        labels: {
                                          fontSize: 11,
                                          fill: "var(--text-primary)",
                                          padding: 10
                                        },
                                      }}
                                      data={estatisticasTrabalhos.job_schedules_per_week}
                                      y={(d) => d.done}
                                      x={(d) => d.date}
                                      labels={({ datum }) => datum.done}
                                      labelComponent={
                                        <VictoryLabel dx={-12}/>
                                      }
                                    />
                                    <VictoryAxis
                                      fixLabelOverlap
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, axisLabel: {fontSize: "11", fill: "var(--text-primary)", padding: 50 }, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10, strokeOpacity: 0.4 } }}
                                    />
                                    <VictoryAxis
                                      fixLabelOverlap
                                      style={{ axis: { stroke: "var(--text-primary)" }, tickLabels: { fill: "var(--text-primary)", fontSize: "11", fontFamily: "Lato"}, grid: { stroke: 'var(--background-content)', strokeWidth: 1.5, strokeDasharray: 10, strokeOpacity: 0.4 }, }}
                                      dependentAxis
                                    />
                                  </VictoryChart>
                                :
                                  <div className={styles.noData}>
                                    <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                                    <p className={styles.noDataText}>Sem dados</p>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      :""
                    }
                  </div>
                :
                  <div className={stylesLocals.content} style={{width: "100%", scrollbarWidth: "none"}}>
                    <p className={stylesLocals.localTitle} style={{marginTop: "15px"}}>
                      Fórmulas
                    </p>
                    <p style={{marginTop: 0, fontSize: "11pt"}}>
                      As fórmulas são utilizadas para calcular os consumos de acordo com os valores inseridos nos trabalhos.
                    </p>
                    <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
                      <NavLink className={stylesLocals.addLocal} to={"/estatisticas/trabalhos/adicionar-formula"}>
                        <MdAdd className={stylesLocals.addIcon} size={22} />
                      </NavLink>
                      <div className={stylesLocals.search}>
                        <IoSearch className={stylesLocals.searchIcon}  />
                        <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
                      </div>
                    </div>
                    <div className={stylesLocals.dropdown}>
                      <FormulasList/>
                    </div>
                    <div className={stylesLocals.changePages}>
                      {previousFormulas ? <IoArrowBackCircleOutline className={stylesLocals.changePageButtons} size={40} onClick={() => { getFormulas(previousFormulas) }}/> : ""}
                      {nextFormulas ? <IoArrowForwardCircleOutline className={stylesLocals.changePageButtons} size={40} onClick={() => { getFormulas(nextFormulas) }}/> : ""}
                    </div>
                  </div>
              }
              {
                statisticType === "stats" ?
                  showModalInitialDate ?
                    <div ref={wrapperRefMonths} className={styles.modalInitialDate}>
                      <div className={styles.chooseYear}>
                        {
                          (years.indexOf(initialYearValue) - 1) > 0
                          ? <HiChevronLeft style={{cursor: "pointer"}} onClick={() => setInitialYearValue(initialYearValue-1)}/>
                          : <HiChevronLeft color={"var(--background)"}/>
                        }
                        <h4 className={styles.modalYear}>{initialYearValue}</h4>
                        {
                          (years.indexOf(initialYearValue) + 1) < years.length
                          ? <HiChevronRight style={{cursor: "pointer"}} onClick={() => setInitialYearValue(initialYearValue+1)}/>
                          : <HiChevronRight color={"var(--background)"}/>
                        }
                      </div>
                      <div className={styles.modalGrid}>
                        {
                          months.map((month) =>
                            <p key={month.id} onClick={() => {setInitialMonthValue(month); setShowModalInitialDate(false);}}>{month.name}</p>
                          )
                        }
                      </div>
                    </div>
                  : showModalFinalDate ?
                    <div ref={wrapperRefMonths} className={styles.modalFinalDate}>
                      <div className={styles.chooseYear}>
                        {
                          (years.indexOf(finalYearValue) - 1) > 0
                          ? <HiChevronLeft style={{cursor: "pointer"}} onClick={() => setFinalYearValue(finalYearValue-1)}/>
                          : <HiChevronLeft color={"var(--background)"}/>
                        }
                        <h4 className={styles.modalYear}>{finalYearValue}</h4>
                        {
                          (years.indexOf(finalYearValue) + 1) < years.length
                          ? <HiChevronRight style={{cursor: "pointer"}} onClick={() => setFinalYearValue(finalYearValue+1)}/>
                          : <HiChevronRight color={"var(--background)"}/>
                        }
                      </div>
                      <div className={styles.modalGrid}>
                        {
                          months.map((month) =>
                            <p key={month.id} onClick={() => {setFinalMonthValue(month); setShowModalFinalDate(false);}}>{month.name}</p>
                          )
                        }
                      </div>
                    </div>
                  :""
                :""
              }
            </div>
        }
      </Layout>
    </>
  );
}

export default EstatisticasTrabalhos;

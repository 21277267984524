import React, { useState, useCallback } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./Ativos.module.css";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch, IoHardwareChipOutline } from "react-icons/io5";
import { HiChevronLeft, HiOutlineInformationCircle } from "react-icons/hi2";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../../Components/DeleteModal/DeleteModal";
import { useDebounce } from "../../../../Components/Debounce/Debounce";
import getLocal from "../../../../Components/GetLocalName/GetLocalName";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Ativos({permissions}) {

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalAtivo, setDeleteModalAtivo] = useState(false);

  const [equipmentInfo, setEquipmentInfo] = useState("");

  const [urlAtivos, setUrlAtivos] = useState(Url+"/equipments/equipment-type/"+id+"?start=0&limit=15");
  const [ativos, setAtivos] = useState([]);
  const [ativoValue, setAtivoValue] = useState("");
  const [previousAtivos, setPreviousAtivos] = useState("");
  const [nextAtivos, setNextAtivos] = useState("");

  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);

  const getEquipmentInfo = useCallback(() => {
    axios.get(Url + "/equipments/equipments-types/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setEquipmentInfo(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getEquipmentInfo() : window.location.reload();
      }
      else {
        setEquipmentInfo("");
      }
    })
  }, [id]);

  const getAtivos = useCallback((urlAtivos) => {
    axios.get(urlAtivos, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setAtivos(res.data.detail);
      setNextAtivos(res.data.next);
      setPreviousAtivos(res.data.previous);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAtivos(urlAtivos) : window.location.reload();
      }
      else {
        setAtivos([]);
        setNextAtivos("");
        setPreviousAtivos("");
        setLoading(false);
      }
    })
  }, []);

  const AtivosList = () => {
    return (
        <>
        {
          ativos.length > 0 ?
          ativos.map((ativo) => (
              <div className={styles.ativosContainer} key={ativo.id}>
                <div className={styles.iconInfo}>
                  <IoHardwareChipOutline size={30} />
                  <p>
                    {ativo.name}
                    <label className={styles.equipmentLabel}>{getLocal(ativo.local.building_name, ativo.local.block_name, ativo.local.floor_name, ativo.local.name)}</label>
                  </p>
                </div>
                <div className={styles.ativosActions}>
                  <NavLink style={{color: "var(--text-primary)"}} to={"/manutencao/equipamentos/"+id+"/editar/"+ativo.id}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                  <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => {setAtivoValue(ativo); setDeleteModalAtivo(true)}}/>
                </div>
              </div>
        ))
        :
        <div style={{display: "flex", justifyContent: "center"}}>
          <div className={styles.noData}>
            <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
            <p className={styles.noDataText}>Sem Ativos Associados</p>
          </div>
        </div>
        }
      </>
    );
  }

  const searchData = (name) => {
    setSearchName(name);
  }

  const handlePageChange = (url) => {
    setUrlAtivos(url);
  }

  const deleteAtivo = async (ativoId) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/equipments/"+ativoId, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Ativo apagado com sucesso</Alert>);
        setUrlAtivos("");
        setTimeout(() => {
          setAtivoValue("");
          setSpinner(false);
          setUrlAtivos(Url+"/equipments/equipment-type/"+id+"?start=0&limit=15");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteAtivo(ativoId) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar ativo</Alert>);
      });
  }

  useEffect(() => {
    if (debouncedSearchName.length > 0) {
      setUrlAtivos(Url+"/equipments/equipment-type/"+id+"?name="+debouncedSearchName+"&start=0&limit=15");
    }
    else {
      setUrlAtivos(Url+"/equipments/equipment-type/"+id+"?start=0&limit=15");
    }
  }, [debouncedSearchName, id]);

  useEffect(() => {
    getEquipmentInfo();
    getAtivos(urlAtivos);
  }, [urlAtivos]);

  return (
    <>
      <Layout tab={"manutenção - ativos"} permissions={permissions}>
        {alert}
        {deleteModalAtivo ? <DeleteModal onClose={setDeleteModalAtivo} headerText={"ELIMINAR ATIVO"} confirmationText={"Deseja eliminar "+ativoValue.name+" ?"} deleteFunction={deleteAtivo} objectId={ativoValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <NavLink className={styles.goBack} to={"/manutencao/equipamentos/"}>
              <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
            </NavLink>
            <p className={styles.equipmentTitle} style={{marginTop: "15px"}}>Ativos de {equipmentInfo.name}<NavLink style={{color: "var(--text-primary)"}} to={"/manutencao/equipamentos"}></NavLink></p>
            <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              <NavLink className={styles.addAtivo} to={"/manutencao/equipamentos/"+id+"/adicionar"}>
                <MdAdd className={styles.addIcon} size={22} />
              </NavLink>
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
            </div>
            <div className={styles.dropdown}>
              <AtivosList/>
            </div>
            <div className={styles.changePages}>
              {previousAtivos === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousAtivos) }}/>}
              {nextAtivos === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextAtivos) }}/>}
        </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default Ativos;
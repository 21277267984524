import './App.css';
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { Url } from "./Constants/globals";

import NotFound from './Components/404/NotFound';

import Avarias from "./Pages/Avarias/Avarias";
import AdicionarAvarias from "./Pages/Avarias/AdicionarAvarias/AdicionarAvarias";
import DetalhesAvarias from "./Pages/Avarias/DetalhesAvarias/DetalhesAvarias";
import LogsAvarias from "./Pages/Avarias/DetalhesAvarias/LogsAvarias";

import Duty from "./Pages/DutyReport/DutyReport";

import Housekeeping from "./Pages/Housekeeping/Housekeeping";
import AdicionarHousekeeping from "./Pages/Housekeeping/AdicionarHousekeeping/AdicionarHousekeeping";
import DetalhesHousekeeping from "./Pages/Housekeeping/DetalhesHousekeeping/DetalhesHousekeeping";
import LogsHousekeeping from "./Pages/Housekeeping/DetalhesHousekeeping/LogsHousekeeping";
import LinenCount from './Pages/Housekeeping/LinenCount/LinenCount';

import Equipamentos from "./Pages/Manutencao/Equipamentos/Equipamentos";
import Ativos from './Pages/Manutencao/Equipamentos/Ativos/Ativos';
import AdicionarAtivos from './Pages/Manutencao/Equipamentos/Ativos/AdicionarAtivos/AdicionarAtivos';
import Trabalhos from "./Pages/Manutencao/Trabalhos/Trabalhos";
import AdicionarTrabalhos from "./Pages/Manutencao/Trabalhos/AdicionarTrabalhos/AdicionarTrabalhos";
import DetalhesTrabalhos from "./Pages/Manutencao/Trabalhos/DetalhesTrabalhos/DetalhesTrabalhos";
import LogsTrabalhos from "./Pages/Manutencao/Trabalhos/DetalhesTrabalhos/LogsTrabalhos";

import Login from "./Pages/Login/Login";

import EstatisticasAvarias from "./Pages/Estatisticas/EstatisticasAvarias/EstatisticasAvarias";
import EstatisticasTrabalhos from "./Pages/Estatisticas/EstatisticasTrabalhos/EstatisticasTrabalhos";
import AddFormula from "./Pages/Estatisticas/EstatisticasTrabalhos/AddFormula/AddFormula";
import EstatisticasHousekeeping from "./Pages/Estatisticas/EstatisticasHousekeeping/EstatisticasHousekeeping";

import BackofficeAvarias from "./Pages/Backoffice/Avarias/BackofficeAvarias";
import AdicionarAreas from "./Pages/Backoffice/Avarias/AdicionarAreas/AdicionarAreas";
import AdicionarTipos from "./Pages/Backoffice/Avarias/AdicionarTipos/AdicionarTipos";
import BackofficeDuties from "./Pages/Backoffice/Duties/BackofficeDuties";
import AdicionarDutyAreas from "./Pages/Backoffice/Duties/AdicionarDutyAreas/AdicionarDutyAreas";
import AdicionarDutyTypes from "./Pages/Backoffice/Duties/AdicionarDutyTypes/AdicionarDutyTypes";
import AdicionarDutyGroups from "./Pages/Backoffice/Duties/AdicionarDutyGroups/AdicionarDutyGroups";
import BackofficeHousekeeping from "./Pages/Backoffice/Housekeeping/BackofficeHousekeeping";
import AdicionarTiposHousekeeping from "./Pages/Backoffice/Housekeeping/AdicionarTiposHousekeeping/AdicionarTiposHousekeeping";
import BackofficeLocais from "./Pages/Backoffice/Locais/Locais";
import AdicionarLocais from "./Pages/Backoffice/Locais/AdicionarLocais/AdicionarLocais";
import BackofficeUtilizadores from "./Pages/Backoffice/Utilizadores/Utilizadores";
import PerfisTecnicos from './Pages/Backoffice/Utilizadores/PerfisTecnicos/PerfisTecnicos';
import AdicionarGruposUtilizadores from './Pages/Backoffice/Utilizadores/AdicionarGruposUtilizadores/AdicionarGruposUtilizadores';
import AdicionarUtilizadores from "./Pages/Backoffice/Utilizadores/AdicionarUtilizadores/AdicionarUtilizadores";
import ChangePassword from "./Pages/Backoffice/Utilizadores/ChangePassword/ChangePassword";
import BackofficeTrabalhos from "./Pages/Backoffice/Trabalhos/BackofficeTrabalhos";
import AdicionarIntervencoes from './Pages/Backoffice/Trabalhos/AdicionarIntervencoes/AdicionarIntervencoes';
import AdicionarTarefas from './Pages/Backoffice/Trabalhos/AdicionarTarefas/AdicionarTarefas';

import useLocalStorage from 'use-local-storage';
import { MdOutlineLightMode, MdDarkMode } from 'react-icons/md';
import RefreshToken from './RefreshToken/RefreshToken';
import EncryptDecryptStorage from './Components/EncryptDecryptStorage/EncryptDecryptStorage';
import AdicionarLinenCountGroups from './Pages/Housekeeping/LinenCount/AdicionarLinenCountGroups/AdicionarLinenCountGroups';
import AdicionarLinenCountTypes from './Pages/Housekeeping/LinenCount/AdicionarLinenCountTypes/AdicionarLinenCountTypes';
import AdicionarPerfisTecnicos from './Pages/Backoffice/Utilizadores/PerfisTecnicos/AdicionarPerfisTecnicos/AdicionarPerfisTecnicos';
import me from './Components/Me/Me';

function UserRoutes() {

  const location = useLocation();
  const [permissions, setPermissions] = useState(EncryptDecryptStorage("decrypt").user.permissions);

  async function handlePermissions() {
    const resMe = await me();
    setPermissions(resMe.permissions);
  }

  useEffect(() => {
    handlePermissions();
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Avarias permissions={permissions} />} />
      <Route path="/avarias" element={<Avarias permissions={permissions} />} />
      <Route path="/avarias/adicionar" element={<AdicionarAvarias permissions={permissions} />} />
      <Route path="/avarias/editar/:id" element={<AdicionarAvarias permissions={permissions} />} />
      <Route path="/avarias/detalhes/:id" element={<DetalhesAvarias permissions={permissions} />} />
      {permissions.malfunctions?.PUT ? <Route path="/avarias/logs/:id" element={<LogsAvarias permissions={permissions} />} /> : ""}

      {permissions.duty?.GET ? <Route path="/duty" element={<Duty permissions={permissions} />} /> : ""}
      
      <Route path="/housekeeping" element={<Housekeeping permissions={permissions} />} />
      <Route path="/housekeeping/adicionar" element={<AdicionarHousekeeping permissions={permissions} />} />
      <Route path="/housekeeping/editar/:id" element={<AdicionarHousekeeping permissions={permissions} />} />
      <Route path="/housekeeping/detalhes/:id" element={<DetalhesHousekeeping permissions={permissions} />} />
      {permissions.housekeeping?.PUT ? <Route path="/housekeeping/logs/:id" element={<LogsHousekeeping permissions={permissions} />} /> : ""}
      {permissions["linen-count"]?.GET ? <Route path="/housekeeping/contagem" element={<LinenCount permissions={permissions} />} /> : ""}
      {permissions["linen-count"]?.GET ? <Route path="/housekeeping/contagem/grupos/adicionar" element={<AdicionarLinenCountGroups permissions={permissions} />} /> : ""}
      {permissions["linen-count"]?.GET ? <Route path="/housekeeping/contagem/grupos/:id" element={<AdicionarLinenCountGroups permissions={permissions} />} /> : ""}
      {permissions["linen-count"]?.GET ? <Route path="/housekeeping/contagem/tipos/adicionar/:groupId" element={<AdicionarLinenCountTypes permissions={permissions} />} /> : ""}
      {permissions["linen-count"]?.GET ? <Route path="/housekeeping/contagem/tipos/:groupId/:id" element={<AdicionarLinenCountTypes permissions={permissions} />} /> :"" }

      {permissions.equipments?.GET ? <Route path="/manutencao/equipamentos" element={<Equipamentos permissions={permissions} />} /> : ""}
      {permissions.equipments?.GET ? <Route path="/manutencao/equipamentos/:id" element={<Ativos permissions={permissions} />} /> : ""}
      {permissions.equipments?.POST ? <Route path="/manutencao/equipamentos/:id/adicionar" element={<AdicionarAtivos permissions={permissions} />} /> : ""}
      {permissions.equipments?.POST ? <Route path="/manutencao/equipamentos/:id/editar/:ativoId" element={<AdicionarAtivos permissions={permissions} />} /> : ""}
      {permissions.jobs?.GET ? <Route path="/manutencao/trabalhos" element={<Trabalhos permissions={permissions} />} /> : ""}
      {permissions.jobs?.POST ? <Route path="/manutencao/trabalhos/adicionar" element={<AdicionarTrabalhos permissions={permissions} />} /> : ""}
      {permissions.jobs?.DELETE ? <Route path="/manutencao/trabalhos/editar/:id" element={<AdicionarTrabalhos permissions={permissions} />} /> : ""}
      {permissions.jobs?.GET ? <Route path="/manutencao/trabalhos/detalhes/:id" element={<DetalhesTrabalhos permissions={permissions} />} /> : ""}
      {permissions.jobs?.PUT ? <Route path="/manutencao/trabalhos/logs/:id" element={<LogsTrabalhos permissions={permissions} />} /> : ""}

      {permissions.statistics?.GET ? <Route path="/estatisticas/avarias" element={<EstatisticasAvarias permissions={permissions} />} /> : ""}
      {permissions.costs?.GET ? <Route path="/estatisticas/trabalhos" element={<EstatisticasTrabalhos permissions={permissions} />} /> : ""}
      {permissions.costs?.GET ? <Route path="/estatisticas/trabalhos/adicionar-formula" element={<AddFormula permissions={permissions} />} /> : ""}
      {permissions.costs?.GET ? <Route path="/estatisticas/trabalhos/editar-formula/:id" element={<AddFormula permissions={permissions} />} /> : ""}
      {permissions.statistics?.GET ? <Route path="/estatisticas/housekeeping" element={<EstatisticasHousekeeping permissions={permissions} />} /> : ""}

      {permissions.malfunctions?.PUT || permissions.malfunctions?.DELETE ? <Route path="/backoffice/avarias" element={<BackofficeAvarias permissions={permissions} />} /> : ""}
      {permissions.malfunctions?.PUT ? <Route path="/backoffice/avarias/areas/adicionar" element={<AdicionarAreas permissions={permissions} />} /> : ""}
      {permissions.malfunctions?.DELETE ? <Route path="/backoffice/avarias/areas/editar/:id" element={<AdicionarAreas permissions={permissions} />} /> : ""}
      {permissions.malfunctions?.PUT ? <Route path="/backoffice/avarias/tipos/adicionar" element={<AdicionarTipos permissions={permissions} />} /> : ""}
      {permissions.malfunctions?.DELETE ? <Route path="/backoffice/avarias/tipos/editar/:id" element={<AdicionarTipos permissions={permissions} />} /> : ""}
      {permissions.duty?.DELETE ? <Route path="/backoffice/duty" element={<BackofficeDuties permissions={permissions} />} /> : ""}
      {permissions.duty?.POST ? <Route path="/backoffice/duty/areas/adicionar" element={<AdicionarDutyAreas permissions={permissions} />} /> : ""}
      {permissions.duty?.PUT ? <Route path="/backoffice/duty/areas/editar/:id" element={<AdicionarDutyAreas permissions={permissions} />} /> : ""}
      {permissions.duty?.POST ? <Route path="/backoffice/duty/tipos/adicionar" element={<AdicionarDutyTypes permissions={permissions} />} /> : ""}
      {permissions.duty?.PUT ? <Route path="/backoffice/duty/tipos/editar/:id" element={<AdicionarDutyTypes permissions={permissions} />} /> : ""}
      {permissions.duty?.POST ? <Route path="/backoffice/duty/grupos/adicionar" element={<AdicionarDutyGroups permissions={permissions} />} /> : ""}
      {permissions.duty?.PUT ? <Route path="/backoffice/duty/grupos/editar/:id" element={<AdicionarDutyGroups permissions={permissions} />} /> : ""}
      {permissions.housekeeping?.PUT || permissions.housekeeping?.DELETE ? <Route path="/backoffice/housekeeping" element={<BackofficeHousekeeping permissions={permissions} />} /> : ""}
      {permissions.housekeeping?.PUT ? <Route path="/backoffice/housekeeping/tipos/adicionar" element={<AdicionarTiposHousekeeping permissions={permissions} />} /> : ""}
      {permissions.housekeeping?.DELETE ? <Route path="/backoffice/housekeeping/tipos/editar/:id" element={<AdicionarTiposHousekeeping permissions={permissions} />} /> : ""}
      {permissions.locals?.POST || permissions.locals?.PUT || permissions.locals?.DELETE ? <Route path="/backoffice/locais" element={<BackofficeLocais permissions={permissions} />} /> : ""}
      {permissions.locals?.POST ? <Route path="/backoffice/locais/adicionar" element={<AdicionarLocais permissions={permissions} />} /> : ""}
      {permissions.locals?.PUT ? <Route path="/backoffice/locais/editar/:id" element={<AdicionarLocais permissions={permissions} />} /> : ""}
      {permissions.users?.PUT ? <Route path="/backoffice/alterar-password" element={<ChangePassword permissions={permissions} />} /> : ""}
      {permissions.users?.PUT ? <Route path="/backoffice/utilizadores/alterar-password/:id" element={<ChangePassword permissions={permissions} />} /> : ""}

      {permissions.users?.DELETE ? <Route path="/backoffice/utilizadores/perfis" element={<PerfisTecnicos permissions={permissions} />} /> : ""}
      {permissions.users?.DELETE ? <Route path="/backoffice/utilizadores/perfis/adicionar" element={<AdicionarPerfisTecnicos permissions={permissions} />} /> : ""}
      {permissions.users?.DELETE ? <Route path="/backoffice/utilizadores/perfis/:id" element={<AdicionarPerfisTecnicos permissions={permissions} />} /> : ""}
      {permissions.users?.GET ? <Route path="/backoffice/utilizadores" element={<BackofficeUtilizadores permissions={permissions} />} /> : ""}
      {permissions.users?.POST ? <Route path="/backoffice/utilizadores/adicionar" element={<AdicionarUtilizadores permissions={permissions} />} /> : ""}
      {permissions.users?.DELETE ? <Route path="/backoffice/utilizadores/editar/:id" element={<AdicionarUtilizadores permissions={permissions} />} /> : ""}
      {permissions.groups?.POST ? <Route path="/backoffice/utilizadores/grupos/adicionar" element={<AdicionarGruposUtilizadores userPermissions={permissions} />} /> : ""}
      {permissions.groups?.PUT ? <Route path="/backoffice/utilizadores/grupos/editar/:id" element={<AdicionarGruposUtilizadores userPermissions={permissions} />} /> : ""}
      {permissions.jobs?.DELETE ? <Route path="/backoffice/trabalhos" element={<BackofficeTrabalhos permissions={permissions} />} /> : ""}
      {permissions.interventions?.POST ? <Route path="/backoffice/trabalhos/intervencoes/adicionar" element={<AdicionarIntervencoes permissions={permissions} />} /> : ""}
      {permissions.interventions?.PUT ? <Route path="/backoffice/trabalhos/intervencoes/:id" element={<AdicionarIntervencoes permissions={permissions} />} /> : ""}
      {permissions.tasks?.POST ? <Route path="/backoffice/trabalhos/tarefas/adicionar" element={<AdicionarTarefas permissions={permissions} />} /> : ""}
      {permissions.tasks?.PUT ? <Route path="/backoffice/trabalhos/tarefas/:id" element={<AdicionarTarefas permissions={permissions} />} /> : ""}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {

  const [loadPage, setLoadPage] = useState(false);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [timeoutTheme, setTimeoutTheme] = useState(false);

  useEffect(() => {
    setLoad(true);
    if (localStorage.getItem("user") === null) {
      setLoading(false);
      setLoadPage(true);
      return;
    }
    else {
      setLoading(true);
      setLoadPage(true);
    }

    if (EncryptDecryptStorage("decrypt")){
      setTheme(EncryptDecryptStorage("decrypt").user.theme);
    }
    else {
      setTheme("light");
    }
  }, [load]);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    if (EncryptDecryptStorage("decrypt")){
      var jason = EncryptDecryptStorage("decrypt");
      jason.user.theme = newTheme;
      axios
          .put(Url + "/users/"+jason.user.id, 
          {
            theme: newTheme
          }, {
              headers: {
                Authorization: "Bearer "+jason.token,
              }
          },)
          .then(async (res) => {
            EncryptDecryptStorage("encrypt", jason);
            setTimeout(() => {
              setTimeoutTheme(false);
            }, 3000);
          })
          .catch((err) => {
            if (err.request && err.request.status === 401){
              RefreshToken(err);
            }
            setTimeout(() => {
              setTimeoutTheme(false);
            }, 3000);
            return "error";
          });
    }
    setTheme(newTheme);
  }

  return (
    <div className="App" data-theme={theme}>
      {
        loadPage ?
        <>
          <button className="switchMode" disabled={timeoutTheme} style={timeoutTheme ? {cursor: "not-allowed"} : {}} onClick={() => {setTimeoutTheme(true); switchTheme();}}>
            {theme === "light" ? <MdDarkMode size={20}/> : <MdOutlineLightMode size={20}/>}
          </button>
            <BrowserRouter basename='/'>
              {
                loading ?
                  <UserRoutes />
                : 
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
              }
            </BrowserRouter>
          </>
          : ""
        }
        </div>
      );
}

export default App;

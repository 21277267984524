import axios from "axios";
import EncryptDecryptStorage from "../EncryptDecryptStorage/EncryptDecryptStorage";
import { Url } from "../../Constants/globals";
import RefreshToken from "../../RefreshToken/RefreshToken";

async function me() {
    let jason = EncryptDecryptStorage("decrypt");
    try {
        const res = await axios.get(Url + "/users/me/", {
            headers: {
                Authorization: "Bearer "+jason.token,
            },
        });
        jason.user = res.data;
        EncryptDecryptStorage("encrypt", jason);
        return res.data;
    }
    catch (err) {
        if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? me() : window.location.reload();
        }
        else {
            localStorage.removeItem("user");
            window.location = "/login";
        }
    }
}

export default me;
import React, { useState, useCallback } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarLocais.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarLocais({permissions}) {

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { id } = useParams();

  const [firstLoadDetails, setFirstLoadDetails] = useState(true);

  const [buildings, setBuildings] = useState([]);
  const [nextBuildings, setNextBuildings] = useState("");
  const [buildingValue, setBuildingValue] = useState("");
  const urlBuildings = Url + "/clients/buildings/?start=0&limit=50";

  const [blocks, setBlocks] = useState([{ id: 0, name: "Sem Bloco" }]);
  const [nextBlocks, setNextBlocks] = useState("");
  const [blockValue, setBlockValue] = useState({ id: 0, name: "Sem Bloco" });
  const [urlBlocks, setUrlBlocks] = useState("");

  const [floors, setFloors] = useState([]);
  const [nextFloors, setNextFloors] = useState("");
  const [floorValue, setFloorValue] = useState("");
  const [urlFloors, setUrlFloors] = useState("");

  const [nameValue, setNameValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getBuildings = (urlBuildings) => {
    axios.get(urlBuildings, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      if (res.data.detail.length === 1){
        await setBuildingValue(res.data.detail[0]);
        await setUrlBlocks(Url + "/clients/building/"+res.data.detail[0].id+"/blocks/?start=0&limit=1000");
        await setUrlFloors(Url + "/clients/building/"+res.data.detail[0].id+"/floors/?start=0&limit=1000");
      }
      else if (res.data.detail.length > 1){
        setTimeout(() => {
          setLoading(false);
          setSpinner(false);
          setFirstLoadDetails(false);
        }, 500);
      }
      setNextBuildings(res.data.next);
      setBuildings(res.data.detail);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getBuildings(urlBuildings) : window.location.reload();
      }
      else {
        setBuildingValue("");
        setBuildings([]);
        setNextBuildings("");
      }
    })
  };

  const getLocalDetails = (id) => {
    axios.get(Url+"/locals/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNameValue(res.data.local.name);
      setBuildingValue(res.data.building);
      setBlockValue(res.data.block ? res.data.block : "");
      setFloorValue(res.data.floor ? res.data.floor : "");
      getBuildings(urlBuildings);
      await setUrlBlocks(Url + "/clients/building/"+res.data.building.id+"/blocks/?start=0&limit=1000");
      await setUrlFloors(Url + "/clients/building/"+res.data.building.id+"/floors/?start=0&limit=1000");
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocalDetails(id) : window.location.reload();
      }
    })
  };

  const getBlocks = (urlBlocks) => {
    setBlocks([{ id: 0, name: "Sem Bloco" }]);
    if (blocks === "" || !firstLoadDetails)
      setBlockValue({ id: 0, name: "Sem Bloco" });
    axios.get(urlBlocks, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNextBlocks(res.data.next);
      setBlocks(blocks => {
        if (blocks.length > 0 && !blocks.includes(res.data.detail)) {
          return blocks.concat(res.data.detail);
        } else {
          return res.data.detail;
        }
      });
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getBlocks(urlBlocks) : window.location.reload();
      }
      else {
        setBlockValue({ id: 0, name: "Sem Bloco" });
        setBlocks([{ id: 0, name: "Sem Bloco" }]);
        setNextBlocks("");
        setFloorValue("");
      }
    })
  };

  const getFloors = async (urlFloors) => {
    if (floors === "" || !firstLoadDetails)
      setFloorValue("");
    await setFloors([]);
    axios.get(urlFloors, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNextFloors(res.data.next);
      setFloors(floors => {
        if (floors.length > 0 && !floors.includes(res.data.detail)) {
          return floors.concat(res.data.detail);
        } else {
          return res.data.detail;
        }
      });
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getFloors(urlFloors) : window.location.reload();
      }
      else {
        setFloorValue("");
        setFloors([]);
        setNextFloors("");
      }
    })
  };

  useEffect(() => {
    if (urlFloors){
      getFloors(urlFloors);
      setTimeout(() => {
        setLoading(false);
        setSpinner(false);
        setFirstLoadDetails(false);
      }, 500);
    }
  }, [urlFloors]);

  useEffect(() => {
    if (urlBlocks)
      getBlocks(urlBlocks);
  }, [urlBlocks]);

  useEffect(() => {
    if (id) getLocalDetails(id);
    else getBuildings(urlBuildings);
  }, []);

  const addLocal = async () => {
    if (nameValue === ""){
      return;
    }
    else if (buildingValue === ""){
      return;
    }
    else if (floors.length > 0 && floorValue === ""){
      return;
    }
    await setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/locals", {
        name: nameValue,
        building_id: buildingValue.id,
        floor_id: (floorValue === "" || floorValue.id === 0) ? null : floorValue.id,
        block_id: (blockValue === "" || blockValue.id === 0) ? null : blockValue.id
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Local criado com sucesso</Alert>);
        setTimeout(() => {
          window.location = "/backoffice/locais";
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addLocal() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar local</Alert>);
        }
      });
  }

  const editLocal = async (id) => {
    if (nameValue === ""){
      return;
    }
    else if (buildingValue === ""){
      return;
    }
    else if (floors.length > 0 && floorValue === ""){
      return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .put(Url + "/locals/"+id, {
        name: nameValue,
        building_id: buildingValue.id,
        floor_id: (floorValue === "" || floorValue.id === 0) ? null : floorValue.id,
        block_id: (blockValue === "" || blockValue.id === 0) ? null : blockValue.id
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Local editado com sucesso</Alert>);
        setTimeout(() => {
          navigate(0);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editLocal(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar local</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "local #"+id : "local"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/locais" state={{from: state ? state.from : ""}}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do local</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder="Indique o nome do local a adicionar..."
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            <Dropdown
              label={"Edifício"}
              placeholder={"Escolher edifício do local"}
              itemsWidth={"40vw"}
              items={buildings}
              onUrlChange={getBuildings}
              value={buildingValue}
              onValueChange={setBuildingValue}
              nextUrl={nextBuildings}
              required={true}
              callGetBlocks={getBlocks}
              callGetFloors={getFloors}
              disabled={buildings.length < 2}
            />
            {
              blocks.length > 0 ?
              <Dropdown
              label={"Bloco"}
              placeholder={"Escolher bloco do local"}
              itemsWidth={"40vw"}
              items={blocks}
              onUrlChange={getBlocks}
              value={blockValue}
              onValueChange={setBlockValue}
              nextUrl={nextBlocks}
              disabled={buildingValue === ""}
            />
            : ""
            }
            {
              floors.length > 0 ?
              <Dropdown
              label={"Piso"}
              placeholder={"Escolher piso do local"}
              itemsWidth={"40vw"}
              items={floors}
              onUrlChange={getFloors}
              value={floorValue}
              onValueChange={setFloorValue}
              nextUrl={nextFloors}
              required={floors.length > 0 && floorValue === ""}
              disabled={buildingValue === ""}
            />
            : ""
            }
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editLocal(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addLocal()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarLocais;
import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarLinenCountGroups.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarLinenCountGroups({permissions}) {

  const { id } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getGroupDetails = (id) => {
    axios.get(Url+"/linen-group/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        await setNameValue(res.data.name);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        await setLoading(false);
        var newToken = await RefreshToken(err);
        newToken ? getGroupDetails(id) : window.location.reload();
      }
    })
  }

  useEffect(() => {
    if (id){
      getGroupDetails(id);
    }
    else {
      setLoading(false)
    }
  }, [id]);


  const addGroup = async () => {
    await setAlert();
    if (nameValue === ""){
      return setAlert(<Alert type={"error"}>Preencha o nome do grupo de roupas</Alert>);
    }
    setSpinner(true);
    await axios
      .post(Url + "/linen-group", {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Grupo de roupas criado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/housekeeping/contagem");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addGroup() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar grupo de roupas</Alert>);
        }
    });
  }

  const editGroup = async (id) => {
    await setAlert();
    if (nameValue === ""){
      return setAlert(<Alert type={"error"}>Preencha o nome do grupo de roupas</Alert>);
    }
    setSpinner(true);
    await axios
      .put(Url + "/linen-group/"+id, {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Grupo de roupas editado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/housekeeping/contagem");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editGroup(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
        }
    });
  }

  return (
    <>
      <Layout tab={id ? "housekeeping - contagem de roupa - grupo #"+id : "housekeeping - contagem de roupa - grupo"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/housekeeping/contagem">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder={"Indique o nome do grupo de roupas..."}
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editGroup(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addGroup()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarLinenCountGroups;
import React, { useState, useEffect, useRef, useCallback } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./Equipamentos.module.css";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoAddCircle, IoRemoveCircle } from "react-icons/io5";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import MultiSelect from "../../../Components/MultiSelect/MultiSelect";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Equipamentos({permissions}) {

  const navigate = useNavigate();

  const modalAddEquipmentRef = useRef(null);
  useOutsideAlerter(modalAddEquipmentRef);

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const [modalAddCaracteristics, setModalAddCaracteristics] = useState(false);
  const [caracteristicValue, setCaracteristicValue] = useState("");
  const [nameCaracteristic, setNameCaracteristic] = useState("");

  const [modalAddUnit, setModalAddUnit] = useState(false);
  const [nameUnitValue, setNameUnitValue] = useState("");

  const urlUnits = Url+"/units?start=0&limit=12000";
  const [units, setUnits] = useState([]);
  const [unitValue, setUnitValue] = useState("");

  const [nameCategoryValue, setNameCategoryValue] = useState("");
  const [codeCategoryValue, setCodeCategoryValue] = useState("");
  const [deleteModalCategory, setDeleteModalCategory] = useState(false);
  const [addCategoryForm, setAddCategoryForm] = useState(false);
  const [editNameCategoryValue, setEditNameCategoryValue] = useState("");
  const [editCodeCategoryValue, setEditCodeCategoryValue] = useState("");
  const [editCategoryForm, setEditCategoryForm] = useState(false);

  const [addSubcategoryForm, setAddSubcategoryForm] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [nameSubcategoryValue, setNameSubcategoryValue] = useState("");
  const [codeSubcategoryValue, setCodeSubcategoryValue] = useState("");
  const [deleteModalSubcategory, setDeleteModalSubcategory] = useState(false);
  const [editNameSubcategoryValue, setEditNameSubcategoryValue] = useState("");
  const [editCodeSubcategoryValue, setEditCodeSubcategoryValue] = useState("");
  const [editSubcategoryForm, setEditSubcategoryForm] = useState(false);

  const [nameEquipmentValue, setNameEquipmentValue] = useState("");
  const [codeEquipmentValue, setCodeEquipmentValue] = useState("");
  const [modalAddEquipments, setModalAddEquipments] = useState(false);
  const [addEquipmentForm, setAddEquipmentForm] = useState(false);
  const [editEquipmentForm, setEditEquipmentForm] = useState(false);
  const [editEquipmentFormSubcategory, setEditEquipmentFormSubcategory] = useState(false);
  const [deleteModalEquipment, setDeleteModalEquipment] = useState(false);
  const [editNameEquipmentValue, setEditNameEquipmentValue] = useState("");
  const [editCodeEquipmentValue, setEditCodeEquipmentValue] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState("");

  const urlCategories = Url+"/equipments/categories/";
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");

  const [subcategories, setSubcategories] = useState([{id: 0, name: "Sem subcategoria"}]);
  const [subcategoryValue, setSubcategoryValue] = useState({id: 0, name: "Sem subcategoria"});

  const [caracteristicas, setCaracteristicas] = useState([]);
  const [previousCaracteristicas, setPreviousCaracteristicas] = useState("");
  const [nextCaracteristicas, setNextCaracteristicas] = useState("");
  const [selectedCaracteristicas, setSelectedCaracteristicas] = useState([]);
  const urlCaracteristicas = Url+"/equipments/caracteristic-types/";

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalAddEquipments(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getCaracteristicas = useCallback((urlCaracteristicas) => {
    
    axios.get(urlCaracteristicas, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setCaracteristicas(res.data.detail);
      setPreviousCaracteristicas(res.data.previous);
      setNextCaracteristicas(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getCaracteristicas(urlCaracteristicas) : window.location.reload();
      }
      else {
        setCaracteristicas([]);
        setNextCaracteristicas("");
        setPreviousCaracteristicas("");
      }
    })
  }, []);

  useEffect(() => {
    getCaracteristicas(urlCaracteristicas);
  }, [urlCaracteristicas, getCaracteristicas]);

  const getUnits = useCallback(async (urlUnits) => {
    
    axios.get(urlUnits, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then(async (res) => {
        await setUnits(res.data.detail);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getUnits(urlUnits) : window.location.reload();
      }
      else {
        await setUnits([]);
      }
    })
  }, []);

  useEffect(() => {
    getUnits(urlUnits);
  }, [urlUnits]);

  const getSubcategories = async (urlSubcategories) => {
    
    axios.get(urlSubcategories, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setSubcategories([...subcategories, ...res.data.sub_categories]);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getSubcategories(urlSubcategories) : window.location.reload();
      }
      else {
        setSubcategories([{id: 0, name: "Sem subcategoria"}]);
      }
    })
  };

  const getCategories = useCallback(async (urlCategories) => {
    
    axios.get(urlCategories, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setCategories(res.data.detail);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getCategories(urlCategories) : window.location.reload();
      }
      else {
        setCategories([]);
        setLoading(false);
      }
    })
  }, []);

  const addUnit = async () => {
    await setAlert();
    if (nameUnitValue === ""){
      return setAlert(<Alert type={"error"}>Indique o nome da unidade de medida</Alert>);;
    }
    
    await axios
      .post(Url + "/units", {
        name: nameUnitValue,
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Unidade criada com sucesso</Alert>);
        setModalAddUnit(false);
        getUnits(urlUnits);
        setNameUnitValue("");
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addUnit() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar unidade</Alert>);
        }
      });
  }

  const addCaracteristic = async () => {
    await setAlert();
    if (nameCaracteristic === ""){
      return setAlert(<Alert type={"error"}>Indique o nome da característica</Alert>);;
    }
    if (unitValue === "" && caracteristicValue.id === 1){
      return setAlert(<Alert type={"error"}>Selecione a unidade de medida</Alert>);;
    }
    
    await axios
      .post(Url + "/equipments/caracteristic-types/", {
        name: nameCaracteristic,
        unit_id: unitValue ? unitValue.id : null
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Característica criada com sucesso</Alert>);
        setModalAddCaracteristics(false);
        getCaracteristicas(urlCaracteristicas);
        setNameCaracteristic("");
        setCaracteristicValue("");
        setUnitValue("");
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addCaracteristic() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar característica</Alert>);
        }
      });
  }

  const addCategory = async (parent) => {
    await setAlert();
    if (parent){
      if ((nameSubcategoryValue === "" || codeSubcategoryValue === ""))
        return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    else {
      if ((nameCategoryValue === "" || codeCategoryValue === ""))
        return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
      
      await axios
        .post(Url + "/equipments/categories/", parent ? {
          name: nameSubcategoryValue,
          reference: codeSubcategoryValue,
          parent: categoryValue.id
        }
        : {
          name: nameCategoryValue,
          reference: codeCategoryValue,
        }, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
          }
        },)
        .then(async (res) => {
          setAlert(<Alert type={"success"}>Categoria criada com sucesso</Alert>);
            getCategories(urlCategories);
            setCategoryValue("");
            if (parent) {
              setAddSubcategoryForm(false);
              setNameSubcategoryValue("");
              setCodeSubcategoryValue("");
            }
            else {
              setAddCategoryForm(false);
              setNameCategoryValue("");
              setCodeCategoryValue("");
            }
        })
        .catch(async (err) => {
          if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? addCategory() : window.location.reload();
          }
          else {
            setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
          }
        });
  }

  const getEquipmentCaracteristics = async (id) => {
    
    axios.get(Url+"/equipments/"+id+"/caracteristic-types/", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setSelectedCaracteristicas(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getEquipmentCaracteristics(id) : window.location.reload();
      }
      else {
        setSelectedCaracteristicas([]);
      }
    })
  };

  const editCategory = async () => {
    await setAlert();
    if (editNameCategoryValue === "" || editCodeCategoryValue === ""){
        return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
      }
      
      await axios
        .put(Url + "/equipments/categories/"+categoryValue.id, {
          name: editNameCategoryValue,
          reference: editCodeCategoryValue
        }, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
          }
        },)
        .then(async (res) => {
          setAlert(<Alert type={"success"}>Categoria criada com sucesso</Alert>);
            getCategories(urlCategories);
            setEditCategoryForm(false);
            setEditNameCategoryValue("");
            setEditCodeCategoryValue("");
        })
        .catch(async (err) => {
          if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? editCategory() : window.location.reload();
          }
          else {
            setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
          }
        });
  }

  const deleteCategory = async (id) => {
    
    setSpinner(true);
    await axios
      .delete(Url + "/equipments/categories/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Categoria apagada com sucesso</Alert>);
        setTimeout(() => {
          setCategoryValue("");
          getCategories(urlCategories);
          setSpinner(false);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteCategory(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar categoria</Alert>);
      });
  }

  const editSubcategory = async (id) => {
    await setAlert();
    if (editNameSubcategoryValue === "" || editCodeSubcategoryValue === ""){
        return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
      }
      
      await axios
        .put(Url + "/equipments/categories/"+id, {
          name: editNameSubcategoryValue,
          reference: editCodeSubcategoryValue,
          parent: categoryValue.id
        }, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
          }
        },)
        .then(async (res) => {
          setAlert(<Alert type={"success"}>Subcategoria editada com sucesso</Alert>);
            await getCategories(urlCategories);
            await setCategoryValue("");
            setEditSubcategoryForm(false);
            setEditNameSubcategoryValue("");
            setEditCodeSubcategoryValue("");
        })
        .catch(async (err) => {
          if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? editSubcategory() : window.location.reload();
          }
          else {
            setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
          }
        });
  }

  const addEquipment = async () => {
    await setAlert();
    if (nameEquipmentValue === "" || codeEquipmentValue === ""){
        return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
      }
      
      await axios
        .post(Url + "/equipments/equipments-types/", {
          name: nameEquipmentValue,
          reference: codeEquipmentValue,
          category_id: subcategoryValue.id === 0 ? categoryValue.id : subcategoryValue.id,
          caracteristics: selectedCaracteristicas
        }, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
          }
        },)
        .then(async (res) => {
          await setAlert(<Alert type={"success"}>Equipamento criado com sucesso</Alert>);
          navigate("/manutencao/equipamentos", {state: { catVal: categoryValue}});
          await getCategories(urlCategories);
          await setCategoryValue("");
          await setAddEquipmentForm(false);
          await setNameEquipmentValue("");
          await setCodeEquipmentValue("");
        })
        .catch(async (err) => {
          if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? addEquipment() : window.location.reload();
          }
          else {
            setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
          }
        });
  }

  const editEquipment = async () => {
    await setAlert();
      
      await axios
        .put(Url + "/equipments/equipments-types/"+selectedEquipment.id, {
          name: editNameEquipmentValue ? editNameEquipmentValue : selectedEquipment.name,
          reference: editCodeEquipmentValue ? editCodeEquipmentValue : selectedEquipment.reference,
          category_id: subcategoryValue.id === 0 ? categoryValue.id : subcategoryValue.id,
          caracteristics: selectedCaracteristicas
        }, {
          headers: {
            Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
          }
        },)
        .then(async (res) => {
          setAlert(<Alert type={"success"}>Equipamento editado com sucesso</Alert>);
            await getCategories(urlCategories);
            await setCategoryValue("");
            await setSubcategoryValue("");
            setEditEquipmentForm(false);
            setEditEquipmentFormSubcategory(false);
            setEditNameEquipmentValue("");
            setEditCodeEquipmentValue("");
        })
        .catch(async (err) => {
          if (err.request && err.request.status === 401){
            var newToken = await RefreshToken(err);
            newToken ? editEquipment() : window.location.reload();
          }
          else {
            setAlert(<Alert type={"error"}>{err.response.data.detail}</Alert>);
          }
        });
  }

  const deleteEquipment = async (id) => {
    
    setSpinner(true);
    await axios
      .delete(Url + "/equipments/equipments-types/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Equipamento eliminado com sucesso</Alert>);
        setTimeout(() => {
          getCategories(urlCategories);
          setCategoryValue("");
          setSpinner(false);
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteEquipment(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar equipamento</Alert>);
      });
  }

  useEffect(() => {
    setAddEquipmentForm(false);
    setAddCategoryForm(false);
    setAddSubcategoryForm(false);
    setEditCategoryForm(false);
    setEditSubcategoryForm(false);
    setEditEquipmentForm(false);
  }, [categoryValue]);

  useEffect(() => {
    getCategories(urlCategories);
  }, [urlCategories, getCategories]);

  return (
    <>
      <Layout tab={"manutenção - equipamentos"} permissions={permissions}>
        {alert}
        {deleteModalCategory ? <DeleteModal onClose={setDeleteModalCategory} headerText={"ELIMINAR CATEGORIA"} confirmationText={"Deseja eliminar "+categoryValue.name+" ?"} deleteFunction={deleteCategory} objectId={categoryValue.id}/> : ""}
        {deleteModalSubcategory ? <DeleteModal onClose={setDeleteModalSubcategory} headerText={"ELIMINAR SUBCATEGORIA"} confirmationText={"Deseja eliminar "+ selectedSubcategory.name+" ?"} deleteFunction={deleteCategory} objectId={selectedSubcategory.id}/> : ""}
        {deleteModalEquipment ? <DeleteModal onClose={setDeleteModalEquipment} headerText={"ELIMINAR EQUIPAMENTO"} confirmationText={"Deseja eliminar "+ selectedEquipment.name+" ?"} deleteFunction={deleteEquipment} objectId={selectedEquipment.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
            <h2 className={styles.categoryTitle} style={{marginBottom: "15px", fontSize: "16pt"}}>Categorias de Equipamentos
              {
                (permissions.equipments && permissions.equipments.POST) ?
                  addCategoryForm ? 
                  <IoRemoveCircle className={styles.hoverAdd} onClick={() => setAddCategoryForm(!addCategoryForm)} size={22}/>
                  : <IoAddCircle className={styles.hoverAdd} onClick={() => setAddCategoryForm(!addCategoryForm)} size={22}/>
                : ""
              }
            </h2>
            
            <div className={styles.form}>
                {
                  addCategoryForm ?
                  <div className={styles.formBorder}>
                    <h4>Adicionar categoria</h4>
                    <div className={styles.formField}>
                        <p className={ nameCategoryValue ? styles.inputLabel : styles.inputLabelNull}>Nome da categoria</p>
                        <input
                            defaultValue={nameCategoryValue}
                            rows={3}
                            placeholder="Indique o nome da categoria a adicionar..."
                            onChange={(value) => setNameCategoryValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={ codeCategoryValue ? styles.inputLabel : styles.inputLabelNull}>Código da categoria</p>
                        <input
                            defaultValue={codeCategoryValue}
                            rows={3}
                            placeholder="Indique o código da categoria..."
                            onChange={(value) => setCodeCategoryValue(value.target.value)}
                        />
                    </div>
                    <button className={styles.addBtn} onClick={() => {addCategory()}}>
                        ADICIONAR CATEGORIA
                    </button>
                  </div>
                : ""
                }
                <div className={styles.dropdownActions}>
                  <Dropdown 
                      label={"Categoria"}
                      placeholder={"Escolher a categoria dos equipamentos"}
                      itemsWidth={"40vw"}
                      dropdownWidth={"40vw"}
                      value={categoryValue}
                      onValueChange={setCategoryValue}
                      onValueChangeFormFalse={setEditCategoryForm}
                      items={categories}
                      onUrlChange={getCategories}
                      urlSearch={"/equipments/categories/"}
                      callGetSubcategories={getSubcategories}
                      callbackLink={Url + "/equipments/categories/"}
                  />
                  {
                    categoryValue ?
                    <div className={styles.actionIcons}>
                      {
                        (permissions.equipments && permissions.equipments.PUT) ?
                        <IoPencilSharp size={20} className={styles.hoverEdit} onClick={async () => { await setEditNameCategoryValue(categoryValue.name); await setEditCodeCategoryValue(categoryValue.reference); await setEditCategoryForm(!editCategoryForm)}}/>
                        :""
                      }
                      {
                        (permissions.equipments && permissions.equipments.DELETE) ?
                        <IoTrashSharp size={20} className={styles.hoverDelete} onClick={() => setDeleteModalCategory(true)}/>
                        :""
                      }
                    </div>
                    : ""
                  }
                </div>
                {
                  editCategoryForm ?
                  <div className={styles.formBorder}>
                    <h4>Editar categoria</h4>
                    <div className={styles.formField}>
                        <p className={ editNameCategoryValue ? styles.inputLabel : styles.inputLabelNull}>Nome da categoria</p>
                        <input
                            defaultValue={editNameCategoryValue}
                            rows={3}
                            placeholder="Indique o nome da categoria..."
                            onChange={(value) => setEditNameCategoryValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={ editCodeCategoryValue ? styles.inputLabel : styles.inputLabelNull}>Código da categoria</p>
                        <input
                            defaultValue={editCodeCategoryValue}
                            rows={3}
                            placeholder="Indique o código da categoria..."
                            onChange={(value) => setEditCodeCategoryValue(value.target.value)}
                        />
                    </div>
                    <button className={styles.addBtn} onClick={() => {editCategory();}}>
                        EDITAR CATEGORIA
                    </button>
                  </div>
                : ""
                }
                {
                  categoryValue ?
                  <div className={styles.titleActions}>
                    <h3 className={styles.title}>Equipamentos de {categoryValue.name}
                      {
                        (permissions.equipments && permissions.equipments.POST) ?
                        (addEquipmentForm || addSubcategoryForm) ?
                        <IoRemoveCircle size={20} onClick={() => { setSubcategories([{id: 0, name: "Sem subcategoria"}]); setModalAddEquipments(false); setAddEquipmentForm(false); setAddSubcategoryForm(false);}} className={styles.hoverAdd} title={"Adicionar equipamento a "+categoryValue.name}/>
                        :
                        <IoAddCircle size={20} onClick={async () => { setSubcategories([{id: 0, name: "Sem subcategoria"}]); setEditEquipmentForm(false); setSelectedCaracteristicas([]); await setModalAddEquipments(true);}} className={styles.hoverAdd} title={"Adicionar equipamento a "+categoryValue.name}/>
                      : ""}
                    </h3>
                    {
                    addEquipmentForm ? 
                    <div className={styles.formBorder} style={{marginTop: "10px"}}>
                    <h4>Adicionar equipamento</h4>
                    <div className={styles.formField}>
                        <p className={ nameEquipmentValue ? styles.inputLabel : styles.inputLabelNull}>Nome do equipamento</p>
                        <input
                            rows={3}
                            placeholder="Indique o nome do equipamento..."
                            onChange={(value) => setNameEquipmentValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={ codeEquipmentValue ? styles.inputLabel : styles.inputLabelNull}>Código do equipamento</p>
                        <input
                            rows={3}
                            placeholder="Indique o código do equipamento..."
                            onChange={(value) => setCodeEquipmentValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={styles.inputLabel}>Categoria do equipamento</p>
                        <input
                            style={{color: "var(--placeholder-color)"}}
                            disabled
                            defaultValue={categoryValue.name}
                            rows={3}
                        />
                    </div>
                    {
                      subcategories.length > 0 ?
                      <Dropdown 
                        label={"Subcategoria"}
                        placeholder={"Escolher a subcategoria do equipamento"}
                        itemsWidth={"calc(40vw - 40px)"}
                        dropdownWidth={"100%"}
                        value={subcategoryValue}
                        onValueChange={setSubcategoryValue}
                        items={subcategories}
                        onUrlChange={getSubcategories}
                      />
                    : ""
                    }
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                      <MultiSelect
                        dropdownWidth={"calc(40vw - 70px)"}
                        label={"Características"}
                        placeholder={"Escolher características"}
                        itemsWidth={"calc(40vw - 20px)"}
                        items={caracteristicas}
                        value={selectedCaracteristicas}
                        onValueChange={setSelectedCaracteristicas}
                        onUrlChange={getCaracteristicas}
                        required={false}
                        search={true}
                        urlSearch={"/equipments/caracteristic-types/"}
                        pagination={true}
                        nextUrl={nextCaracteristicas}
                        previousUrl={previousCaracteristicas}
                      />
                      {
                        (modalAddCaracteristics) ?
                        <IoRemoveCircle size={40} onClick={() => {  setModalAddCaracteristics(false); }} className={styles.hoverAdd} title={"Adicionar característica"}/>
                        :
                        <IoAddCircle size={40} onClick={() => setModalAddCaracteristics(true)} className={styles.hoverAdd} title={"Adicionar característica"}/>
                      }
                    </div>
                    {
                        modalAddCaracteristics ?
                        <div className={styles.formBorder} style={{marginTop: "10px"}}>
                          <h4>Adicionar característica</h4>
                          <div className={styles.formField}>
                              <p className={ nameCaracteristic ? styles.inputLabel : styles.inputLabelNull}>Nome da característica</p>
                              <input
                                  rows={3}
                                  placeholder="E.g. Marca"
                                  onChange={(value) => setNameCaracteristic(value.target.value)}
                              />
                          </div>
                          <Dropdown 
                            label={"Tipo de característica"}
                            placeholder={"Escolher o tipo de característica"}
                            itemsWidth={"calc(40vw - 40px)"}
                            dropdownWidth={"100%"}
                            value={caracteristicValue}
                            onValueChange={setCaracteristicValue}
                            items={[{id: 1, name: "Numérico"}, {id: 2, name: "Texto"}]}
                            required
                          />
                          {
                          caracteristicValue.id === 1 ?
                          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Dropdown
                              label={"Unidade de medida"}
                              placeholder={"Escolher unidade de medida"}
                              dropdownWidth={"calc(40vw - 90px)"}
                              itemsWidth={"calc(40vw - 90px)"}
                              items={units}
                              value={unitValue}
                              onValueChange={setUnitValue}
                              required
                            />
                            {
                          (modalAddUnit) ?
                          <IoRemoveCircle size={40} onClick={() => {  setModalAddUnit(false); }} className={styles.hoverAdd} title={"Adicionar unidade"}/>
                          :
                          <IoAddCircle size={40} onClick={() => setModalAddUnit(true)} className={styles.hoverAdd} title={"Adicionar unidade"}/>
                          }
                          </div>
                          : ""
                          }
                          {
                          modalAddUnit ?
                          <div className={styles.formBorder} style={{marginTop: "10px"}}>
                            <h4>Adicionar unidade de medida</h4>
                            <div className={styles.formField}>
                                <p className={ nameUnitValue ? styles.inputLabel : styles.inputLabelNull}>Nome da unidade</p>
                                <input
                                    rows={3}
                                    placeholder="Indique o nome da unidade... E.g. kg"
                                    onChange={(value) => setNameUnitValue(value.target.value)}
                                />
                            </div>
                            <button className={styles.addBtn} onClick={() => {addUnit();}}>
                                ADICIONAR UNIDADE
                            </button>
                          </div>
                          : ""}
                          <button className={styles.addBtn} onClick={() => {addCaracteristic();}}>
                              ADICIONAR CARACTERÍSTICA
                          </button>
                        </div>
                        : ""
                      }
                    <button className={styles.addBtn} onClick={() => {addEquipment();}}>
                        ADICIONAR EQUIPAMENTO
                    </button>
                  </div>
                : ""
                  }
                    {modalAddEquipments ? 
                    <div ref={modalAddEquipmentRef} className={styles.addEquipmentModal}>
                      <div className={styles.addEquipmentModalOption} onClick={async () => { await getSubcategories(Url + "/equipments/categories/"+categoryValue.id); await setAddEquipmentForm(true); await setModalAddEquipments(false);} }><IoAddCircle/>Adicionar equipamento</div>
                      <div className={styles.addEquipmentModalOption} onClick={async () => { await setAddSubcategoryForm(true); await setModalAddEquipments(false);}}><IoAddCircle/>Adicionar subcategoria</div>
                    </div>
                    : ""}
                  </div>
                :""
                }

                {
                  addSubcategoryForm ?
                  <div className={styles.formBorder} style={{marginTop: "10px"}}>
                    <h4>Adicionar subcategoria a {categoryValue.name}</h4>
                    <div className={styles.formField}>
                        <p className={ nameSubcategoryValue ? styles.inputLabel : styles.inputLabelNull}>Nome da subcategoria</p>
                        <input
                            rows={3}
                            placeholder="Indique o nome da subcategoria..."
                            onChange={(value) => setNameSubcategoryValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={ codeSubcategoryValue ? styles.inputLabel : styles.inputLabelNull}>Código da subcategoria</p>
                        <input
                            rows={3}
                            placeholder="Indique o código da subcategoria..."
                            onChange={(value) => setCodeSubcategoryValue(value.target.value)}
                        />
                    </div>
                    <button className={styles.addBtn} onClick={() => {addCategory(categoryValue.id);}}>
                        ADICIONAR SUBCATEGORIA
                    </button>
                  </div>
                : ""
                }
                {
                  categoryValue ?
                  categoryValue.equipments && categoryValue.equipments.length > 0 ?
                  <div className={styles.equipmentCategory}>
                  {
                    (categoryValue.equipments.map((equipment) =>(
                      <div key={equipment.id}>
                        <div className={styles.equipmentActions}>
                          <div onClick={(e) => {
                          navigate("/manutencao/equipamentos/"+equipment.id, {state: {from: equipment}})}} className={styles.equipmentName}>{equipment.reference} - {equipment.name}</div>
                          <div className={styles.actionIcons} onClick={(e) => e.preventDefault()}>
                            {
                              (permissions.equipments && permissions.equipments.PUT) ? 
                                <IoPencilSharp size={20} className={styles.hoverEdit} onClick={async (e) => { await setModalAddEquipments(false);  if (subcategories.length === 1){await getSubcategories(Url + "/equipments/categories/"+categoryValue.id);} await getEquipmentCaracteristics(equipment.id); await setSubcategoryValue({id: 0, name: "Sem subcategoria"}); await setSelectedEquipment(equipment); await setEditEquipmentForm(!editEquipmentForm);}}/>
                              :""
                            }
                            {
                              (permissions.equipments && permissions.equipments.DELETE) ?
                              <IoTrashSharp size={20} className={styles.hoverDelete} onClick={async(e) => {  await setSelectedEquipment(equipment); await setDeleteModalEquipment(true);}}/>
                              :""
                            }
                          </div>
                        </div>
                        {
                        editEquipmentForm && selectedEquipment.id === equipment.id ?
                        <div className={styles.formBorder}>
                          <h4>Editar equipamento</h4>
                          <div className={styles.formField}>
                              <p className={styles.inputLabel}>Nome do equipamento</p>
                              <input
                                  defaultValue={selectedEquipment.name}
                                  rows={3}
                                  placeholder="Indique o nome do equipamento..."
                                  onChange={(value) => setEditNameEquipmentValue(value.target.value)}
                              />
                          </div>
                          <div className={styles.formField}>
                              <p className={styles.inputLabel}>Código do equipamento</p>
                              <input
                                  defaultValue={selectedEquipment.reference}
                                  rows={3}
                                  placeholder="Indique o código do equipamento..."
                                  onChange={(value) => setEditCodeEquipmentValue(value.target.value)}
                              />
                          </div>
                          <div className={styles.formField}>
                            <p className={styles.inputLabel}>Categoria do equipamento</p>
                            <input
                                style={{color: "var(--placeholder-color)"}}
                                disabled
                                defaultValue={categoryValue.name}
                                rows={3}
                            />
                          </div>
                          {
                              subcategories.length > 0 ?
                              <Dropdown 
                                label={"Subcategoria"}
                                placeholder={"Escolher a subcategoria do equipamento"}
                                itemsWidth={"calc(40vw - 40px)"}
                                dropdownWidth={"100%"}
                                value={subcategoryValue}
                                onValueChange={setSubcategoryValue}
                                items={subcategories}
                                onUrlChange={getSubcategories}
                            />
                          : ""
                          }
                          <MultiSelect
                            label={"Características"}
                            placeholder={"Escolher características"}
                            itemsWidth={"calc(40vw - 20px)"}
                            items={caracteristicas}
                            value={selectedCaracteristicas}
                            onValueChange={setSelectedCaracteristicas}
                            onUrlChange={getCaracteristicas}
                            required={false}
                            search={true}
                            urlSearch={"/equipments/caracteristic-types/"}
                            pagination={true}
                            nextUrl={nextCaracteristicas}
                            previousUrl={previousCaracteristicas}
                          />
                          <button className={styles.addBtn} onClick={() => {editEquipment();}}>
                              EDITAR EQUIPAMENTO
                          </button>
                        </div>
                      : ""
                      }
                      </div>
                    )))
                  }
                  </div>
                  :""
                  :""
                }
                {
                categoryValue ?
                categoryValue.sub_categories.length > 0 ?
                <div className={styles.equipmentSubcategory}>
                {(categoryValue.sub_categories.map((subcategory) => (
                  <div key={subcategory.id}>
                    <div className={styles.subcategory} key={subcategory.id}>
                      <p>{subcategory.reference} - {subcategory.name}</p>
                      <div className={styles.actionIcons}>
                        {
                          (permissions.equipments && permissions.equipments.PUT) ?
                          <IoPencilSharp size={20} className={styles.hoverEdit} onClick={async () => { await setSelectedSubcategory(subcategory); await setEditNameSubcategoryValue(subcategory.name); await setEditCodeSubcategoryValue(subcategory.reference); await setEditSubcategoryForm(!editSubcategoryForm)}}/>
                          :""
                        }
                        {
                          (permissions.equipments && permissions.equipments.DELETE) ?
                          <IoTrashSharp size={20} className={styles.hoverDelete} onClick={async () => {await setSelectedSubcategory(subcategory); await setDeleteModalSubcategory(true);}}/>
                          :""
                        }
                      </div>
                    </div>
                    {
                  editSubcategoryForm && subcategory.id === selectedSubcategory.id ?
                  <div className={styles.formBorder} style={{marginTop: "10px"}}>
                    <h4>Editar subcategoria</h4>
                    <div className={styles.formField}>
                        <p className={ editNameSubcategoryValue ? styles.inputLabel : styles.inputLabelNull}>Nome da subcategoria</p>
                        <input
                            defaultValue={editNameSubcategoryValue}
                            rows={3}
                            placeholder="Indique o nome da subcategoria..."
                            onChange={(value) => setEditNameSubcategoryValue(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={ editCodeSubcategoryValue ? styles.inputLabel : styles.inputLabelNull}>Código da subcategoria</p>
                        <input
                            defaultValue={editCodeSubcategoryValue}
                            rows={3}
                            placeholder="Indique o código da subcategoria..."
                            onChange={(value) => setEditCodeSubcategoryValue(value.target.value)}
                        />
                    </div>
                    <button className={styles.addBtn} onClick={() => {editSubcategory(subcategory.id);}}>
                        EDITAR SUBCATEGORIA
                    </button>
                  </div>
                : ""
                }
                {
                  subcategory.equipments && subcategory.equipments.length > 0 ?
                  (subcategory.equipments.map((equipment2) =>(
                    <div key={equipment2.id}>
                      <div className={styles.equipmentActions}>
                        <div className={styles.equipmentName} onClick={() => navigate("/manutencao/equipamentos/"+equipment2.id, {state: {from: equipment2}})}>{equipment2.reference} - {equipment2.name}</div>
                        <div className={styles.actionIcons}>
                          {
                            (permissions.equipments && permissions.equipments.PUT) ?
                            <IoPencilSharp size={20} className={styles.hoverEdit} onClick={async (e) => { await setModalAddEquipments(false); e.preventDefault(); if(subcategories.length === 1){ await getSubcategories(Url + "/equipments/categories/"+categoryValue.id);} await setSubcategoryValue(subcategory); await setSelectedEquipment(equipment2); await getEquipmentCaracteristics(equipment2.id); await setEditEquipmentFormSubcategory(!editEquipmentFormSubcategory);}}/>
                            :""
                          }
                          {
                            (permissions.equipments && permissions.equipments.DELETE) ?
                            <IoTrashSharp size={20} className={styles.hoverDelete} onClick={async (e) => {await e.preventDefault(); await setSelectedEquipment(equipment2); await setDeleteModalEquipment(true);}}/>
                            :""
                          }
                        </div>
                      </div>
                      {
                        editEquipmentFormSubcategory && selectedEquipment.id === equipment2.id ?
                        <div className={styles.formBorder}>
                          <h4>Editar equipamento</h4>
                          <div className={styles.formField}>
                              <p className={styles.inputLabel}>Nome do equipamento</p>
                              <input
                                  defaultValue={selectedEquipment.name}
                                  rows={3}
                                  placeholder="Indique o nome do equipamento..."
                                  onChange={(value) => setEditNameEquipmentValue(value.target.value)}
                              />
                          </div>
                          <div className={styles.formField}>
                              <p className={styles.inputLabel}>Código do equipamento</p>
                              <input
                                  defaultValue={selectedEquipment.reference}
                                  rows={3}
                                  placeholder="Indique o código do equipamento..."
                                  onChange={(value) => setEditCodeEquipmentValue(value.target.value)}
                              />
                          </div>
                          <div className={styles.formField}>
                            <p className={styles.inputLabel}>Categoria do equipamento</p>
                            <input
                                style={{color: "var(--placeholder-color)"}}
                                disabled
                                defaultValue={categoryValue.name}
                                rows={3}
                            />
                          </div>
                          {
                              subcategories.length > 0 ?
                              <Dropdown 
                                label={"Subcategoria"}
                                placeholder={"Escolher a subcategoria do equipamento"}
                                itemsWidth={"calc(40vw - 40px)"}
                                dropdownWidth={"100%"}
                                value={subcategoryValue}
                                onValueChange={setSubcategoryValue}
                                items={subcategories}
                                onUrlChange={getSubcategories}
                              />
                          : ""
                          }
                          <MultiSelect
                            label={"Características"}
                            placeholder={"Escolher características"}
                            itemsWidth={"calc(40vw - 20px)"}
                            items={caracteristicas}
                            value={selectedCaracteristicas}
                            onValueChange={setSelectedCaracteristicas}
                            onUrlChange={getCaracteristicas}
                            required={false}
                            search={true}
                            urlSearch={"/equipments/caracteristic-types/"}
                            pagination={true}
                            nextUrl={nextCaracteristicas}
                            previousUrl={previousCaracteristicas}
                          />
                          <button className={styles.addBtn} onClick={() => {editEquipment();}}>
                              EDITAR EQUIPAMENTO
                          </button>
                        </div>
                      : ""
                      }
                    </div>
                  )))
                  :""
                }
                  </div>
                )))}
                </div>
                : ""
                : ""
                }
            </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default Equipamentos;
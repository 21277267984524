import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./BackofficeHousekeeping.module.css";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoSearch } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function BackofficeHousekeeping({permissions}) {

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalTipo, setDeleteModalTipo] = useState(false);

  const [urlTipos, setUrlTipos] = useState(Url+"/housekeeping/housekeeping-types/?start=0&limit=15");
  const [tipos, setTipos] = useState([]);
  const [tipoValue, setTipoValue] = useState("");
  const [previousTipos, setPreviousTipos] = useState("");
  const [nextTipos, setNextTipos] = useState("");

  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName, 250);

  const getTipos = async (urlTipos) => {
    axios.get(urlTipos, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setTipos(res.data.detail);
      setNextTipos(res.data.next);
      setPreviousTipos(res.data.previous);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTipos(urlTipos) : window.location.reload();
      }
      else {
        setTipos([]);
        setNextTipos([]);
        setPreviousTipos([]);
        setLoading(false);
      }
    })
  };

  const TiposList = () => {
    return (
        <>
        {
          tipos.map((tipo) => (
              <div className={styles.tiposContainer} key={tipo.id}>
                <p>
                    {tipo.name}
                </p>
                {
                  permissions.housekeeping && permissions.housekeeping.DELETE ?
                  <div className={styles.tiposActions}>
                    <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/housekeeping/tipos/editar/"+tipo.id}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                    <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => {setTipoValue(tipo); setDeleteModalTipo(true)}}/>
                  </div>
                  : ""
                }
              </div>
        ))
        }
      </>
    );
  }

  const searchData = (name) => {
    setSearchName(name);
  }

  const handlePageChange = (url) => {
    setUrlTipos(url);
  }

  const deleteTipo = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/housekeeping/housekeeping-type/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo apagado com sucesso</Alert>);
        setTimeout(() => {
          setTipoValue("");
          setSpinner(false);
          setUrlTipos(Url+"/housekeeping/housekeeping-types/?start=0&limit=15");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteTipo(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao apagar tipo</Alert>);
      });
  }

  useEffect(() => {
    if (debouncedSearchName.length > 0) {
      setUrlTipos(Url+"/housekeeping/housekeeping-types/?name="+debouncedSearchName+"&start=0&limit=15");
    }
    else {
        setUrlTipos(Url+"/housekeeping/housekeeping-types/?start=0&limit=15");
    }
  }, [debouncedSearchName]);

  useEffect(() => {
    getTipos(urlTipos);
  }, [urlTipos]);

  return (
    <>
      <Layout tab={"backoffice housekeeping"} permissions={permissions}>
        {alert}
        {deleteModalTipo ? <DeleteModal onClose={setDeleteModalTipo} headerText={"ELIMINAR TIPO"} confirmationText={"Deseja eliminar "+tipoValue.name+" ?"} deleteFunction={deleteTipo} objectId={tipoValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <p className={styles.tipoTitle} style={{marginTop: "15px"}}>Tipos de Pedidos</p>
            <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              {
                permissions.housekeeping && permissions.housekeeping.PUT ?
                <NavLink className={styles.addType} to={"/backoffice/housekeeping/tipos/adicionar"}>
                  <MdAdd className={styles.addIcon} size={22} />
                </NavLink>
                : ""
              }
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
            </div>
            <div className={styles.dropdown}>
                <TiposList/>
            </div>
            <div className={styles.changePages}>
                {previousTipos === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousTipos) }}/>}
                {nextTipos === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextTipos) }}/>}
        </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default BackofficeHousekeeping;
import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarTipos.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";
import Dropdown from "../../../../Components/Dropdown/Dropdown";

function AdicionarTipos({permissions}) {

  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");
  const area = state ? state.from : "";

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const urlProfiles = Url+"/malfunctions/profiles/?start=0&limit=15";
  const [profiles, setProfiles] = useState([]);
  const [nextProfiles, setNextProfiles] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");

  const getTechnicalProfiles = async (urlProfiles) => {
    axios.get(urlProfiles, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setProfiles(res.data.detail);
      setNextProfiles(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTechnicalProfiles(urlProfiles) : window.location.reload();
      }
      else {
        setProfiles([]);
        setNextProfiles("");
      }
    })
  }

  useEffect(() => {
    getTechnicalProfiles(urlProfiles);
  }, [urlProfiles]);

  const getTipoDetails = (id) => {
    axios.get(Url+"/malfunctions/malfunction-types/"+id+"/malfunction-areas/"+area.id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNameValue(res.data.name);
      setSelectedProfile(res.data?.technical_profile ?? "");
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTipoDetails(id) : window.location.reload();
      }
      setLoading(false);
    })
  }


  useEffect(() => {
    if (id) getTipoDetails(id);
    else setLoading(false);
  }, [id]);


  const addTipo = async () => {
    if (nameValue === ""){
      return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/malfunctions/malfunction-types/"+area.id, {
        name: nameValue,
        technical_profile_id: selectedProfile?.id ?? null 
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(() => {
        setAlert(<Alert type={"success"}>Tipo criado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/backoffice/avarias", area ? {state: { from: area}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addTipo() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar tipo</Alert>);
        }
      });
  }

  const editTipo = async (id) => {
    if (nameValue === ""){
        return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .put(Url + "/malfunctions/malfunction-types/"+id+"/malfunction-areas/"+area.id, {
        name: nameValue,
        technical_profile_id: selectedProfile?.id ?? null 
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(() => {
        setAlert(<Alert type={"success"}>Tipo editado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/backoffice/avarias", area ? {state: { from: area}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editTipo(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar tipo</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice avarias tipo #"+id : "backoffice avarias"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/avarias" state={ area ? {from: area} : ""}>
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
              <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do tipo</p>
              <input
                defaultValue={nameValue}
                rows={3}
                placeholder={ area ? "Indique o nome do tipo de avaria a adicionar a "+area.name+"..." : "Indique o nome do tipo de avaria a adicionar..."}
                onChange={(value) => setNameValue(value.target.value)}
              />
            </div>
            <Dropdown
              label={"Perfil técnico"}
              placeholder={"Escolher perfil técnico que atua"}
              itemsWidth={"40vw"}
              items={profiles}
              onUrlChange={getTechnicalProfiles}
              value={selectedProfile}
              onValueChange={setSelectedProfile}
              nextUrl={nextProfiles}
            />
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editTipo(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addTipo()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarTipos;
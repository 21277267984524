import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarTiposHousekeeping.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarTiposHousekeeping({permissions}) {

  const { id } = useParams();
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getTipoDetails = (id) => {
    axios.get(Url+"/housekeeping/housekeeping-type/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setNameValue(res.data.name);
        setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTipoDetails(id) : window.location.reload();
      }
      setLoading(false);
    })
  }

  useEffect(() => {
    if (id) getTipoDetails(id);
    else setLoading(false);
  }, []);

  const addTipo = async () => {
    if (nameValue === ""){
      return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .post(Url + "/housekeeping/housekeeping-type/", {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo criado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/housekeeping");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addTipo() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar tipo</Alert>);
        }
      });
  }

  const editTipo = async (id) => {
    if (nameValue === ""){
        return;
    }
    setAlert();
    setSpinner(true);
    await axios
      .put(Url + "/housekeeping/housekeeping-type/"+id, {
        name: nameValue
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Tipo editado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/housekeeping");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editTipo(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao editar tipo</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice housekeeping pedido #"+id : "backoffice housekeeping"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <NavLink className={styles.goBack} to="/backoffice/housekeeping">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do tipo</p>
                <input
                    defaultValue={nameValue}
                    rows={3}
                    placeholder="Indique o nome do tipo de pedido a adicionar..."
                    onChange={(value) => setNameValue(value.target.value)}
                />
            </div>
            
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editTipo(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addTipo ()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarTiposHousekeeping;
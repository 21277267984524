import React, { useState, useCallback } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./PerfisTecnicos.module.css";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { IoPencilSharp, IoTrashSharp, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoAddCircleSharp } from "react-icons/io5";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import DeleteModal from "../../../../Components/DeleteModal/DeleteModal";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function PerfisTecnicos({permissions}) {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteModalProfile, setDeleteModalProfile] = useState(false);

  const [urlProfiles, setUrlProfiles] = useState(Url+"/malfunctions/profiles/?start=0&limit=15");
  const [profiles, setProfiles] = useState([]);
  const [profileValue, setProfileValue] = useState("");
  const [previousProfiles, setPreviousProfiles] = useState("");
  const [nextProfiles, setNextProfiles] = useState("");

  const getProfiles = useCallback(async (url) => {
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setProfiles(res.data.detail);
      setNextProfiles(res.data.next);
      setPreviousProfiles(res.data.previous);
      setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getProfiles(url) : window.location.reload();
      }
      else {
        setProfiles([]);
        setNextProfiles("");
        setPreviousProfiles("");
        setLoading(false);
      }
    })
  }, []);

  const ProfilesList = () => {
    return (
      <>
        {
          profiles.map((profile) => (
            <div className={styles.profilesContainer} key={profile.id}>
              <p>
                {profile.name}
              </p>
              <div className={styles.profilesActions}>
                {
                  permissions.users && permissions.users.DELETE ?
                    <NavLink style={{color: "var(--text-primary)"}} to={"/backoffice/utilizadores/perfis/"+profile.id}><IoPencilSharp className={styles.hoverEdit} size={20}/></NavLink>
                  : ""
                }
                {
                  permissions.users && permissions.users.DELETE ?
                    <IoTrashSharp className={styles.hoverDelete} size={20} onClick={() => {setProfileValue(profile); setDeleteModalProfile(true)}}/>
                  : ""
                }
              </div>
            </div>
          ))
        }
      </>
    );
  }

  const handlePageChange = (url) => {
    setUrlProfiles(url);
  }

  const deleteProfile = async (id) => {
    setAlert();
    setSpinner(true);
    await axios
      .delete(Url + "/malfunctions/profiles/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(() => {
        setAlert(<Alert type={"success"}>Perfil eliminado com sucesso</Alert>);
        setTimeout(() => {
          setProfileValue("");
          setSpinner(false);
          setUrlProfiles(Url+"/malfunctions/profiles/?start=0&limit=15");
          getProfiles(Url+"/malfunctions/profiles/?start=0&limit=15");
        }, 500);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteProfile(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao eliminar perfil</Alert>);
      });
  }

  useEffect(() => {
    getProfiles(urlProfiles);
  }, [urlProfiles]);

  return (
    <>
      <Layout tab={"backoffice utilizadores"} permissions={permissions}>
        {alert}
        {deleteModalProfile ? <DeleteModal onClose={setDeleteModalProfile} headerText={"ELIMINAR PERFIL"} confirmationText={"Deseja eliminar "+profileValue.name+" ?"} deleteFunction={deleteProfile} objectId={profileValue.id}/> : ""}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        
        <div className={styles.content}>
            <p className={styles.profileTitle} style={{marginTop: "15px"}}>
              Perfis
              <NavLink className={styles.hoverAdd} to={"/backoffice/utilizadores/perfis/adicionar"}>
                <IoAddCircleSharp size={22}/>
              </NavLink>
            </p>
            <div className={styles.dropdown}>
              <ProfilesList/>
            </div>
            <div className={styles.changePages}>
                {previousProfiles === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previousProfiles) }}/>}
                {nextProfiles === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(nextProfiles) }}/>}
        </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default PerfisTecnicos;
import React, { useState } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarGruposUtilizadores.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import { CiSquareCheck, CiSquareRemove } from "react-icons/ci";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarGruposUtilizadores({userPermissions}) {

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [nameValue, setNameValue] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [permissions, setPermissions] = useState("");

  const accountTypesUrl = `${Url}/users/account-types/?start=0&limit=50`;
  const [accountTypes, setAccountTypes] = useState([{id: 0, name: "Todos os departamentos"}]);
  const [accountTypeValue, setAccountTypeValue] = useState("");
  const [originalAccountType, setOriginalAccountType] = useState("");

  const [updatedPermissions, setUpdatedPermissions] = useState("");
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getGroupDetails = (id) => {
    axios.get(`${Url}/groups/${id}/permissions`, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    })
    .then( async (res) => {
      await setNameValue(res.data.group.name);
      await setOriginalName(res.data.group.name);
      await setOriginalAccountType(res.data.group.account_type ? res.data.group.account_type : "");
      await setAccountTypeValue(res.data.group.account_type ? res.data.group.account_type : accountTypes[0]);
      await setPermissions(res.data.group_permissions);
      await setUpdatedPermissions(res.data.group_permissions);
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getGroupDetails(id) : window.location.reload();
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const getAccountTypes = (urlAccountTypes) => {
    axios.get(urlAccountTypes, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    })
    .then((res) => {
      setAccountTypes(accountTypes.concat(res.data.detail));
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAccountTypes(urlAccountTypes) : window.location.reload();
      }
      else {
        setAccountTypes([]);
      }
    })
  }

  useEffect(() => {
    getAccountTypes(accountTypesUrl);
    if (id) getGroupDetails(id);
    else setLoading(false);
  }, []);

  const addGroup = async () => {
    await setAlert();
    if (nameValue === "" || accountTypeValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);;
    }
    setSpinner(true);
    await axios.post(`${Url}/groups`, {
      name: nameValue,
      account_type_id: accountTypeValue.id === 0 ? null : accountTypeValue.id
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then((res) => {
      setAlert(<Alert type={"success"}>Grupo criado com sucesso</Alert>);
      setTimeout(() => {
        navigate("/backoffice/utilizadores/grupos/editar/"+res.data.group.id);
      }, 200);
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? addGroup() : window.location.reload();
      }
      else {
        setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao criar grupo"}</Alert>);
      }
    })
    .finally(() => {
      setSpinner(false);
    });
  }

  const changeName = async (id) => {
    await setAlert();
    if (nameValue === "" || accountTypeValue === "") return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    else if (nameValue !== originalName || accountTypeValue !== originalAccountType){
      setSpinner(true);
      await axios.put(`${Url}/groups/${id}`, {
        name: nameValue,
        account_type_id: accountTypeValue.id === 0 ? null : accountTypeValue.id
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      })
      .then(() => {
        editGroup(id);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? changeName(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao editar grupo"}</Alert>);
        }
      });
    }
    else {
      editGroup(id);
    }
  }

  const editGroup = async (id) => {
    await setAlert();

    let finalArray = [];
    let index = 0;
    
    Object.keys(permissions).forEach(key => {
      finalArray.push({"name": [], page: permissions[key].id})
      if(permissions[key].GET === true || [1,2,3,4,5,6,7,8,9,16].includes(permissions[key].id)) finalArray[index].name.push("GET");
      if(permissions[key].POST === true || [3,6,16].includes(permissions[key].id)) finalArray[index].name.push("POST");
      if(permissions[key].PUT === true || [1,16].includes(permissions[key].id)) finalArray[index].name.push("PUT");
      if(permissions[key].DELETE === true) finalArray[index].name.push("DELETE");
      index++
    });

    setSpinner(true);
    await axios.put(`${Url}/groups/${id}/permissions`, finalArray, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(() => setAlert(<Alert type={"success"}>Grupo editado com sucesso</Alert>))
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? editGroup(id) : window.location.reload();
      }
      else setAlert(<Alert type={"error"}>{(err.response && err.response.data && err.response.data.detail) ? err.response.data.detail : "Erro ao editar grupo"}</Alert>);
    })
    .finally(() => {
      setSpinner(false);
    });
  }


  return (
    <>
      <Layout tab={id ? `backoffice permissões utilizadores #${id}` : "backoffice permissões utilizadores"} permissions={userPermissions}>
        {alert}
        { 
          spinner
          ? <Loading />
          : loading
          ? <></>
          :
          <div className={styles.content}>
            <NavLink className={styles.goBack} to={state && state.from ? `/backoffice/utilizadores/editar/${state.from}` : "/backoffice/utilizadores/adicionar"}>
              <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
            </NavLink>
            <div className={styles.form}>
              <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome do grupo</p>
                <input
                  defaultValue={nameValue}
                  rows={3}
                  placeholder="Indique o nome do grupo..."
                  onChange={(value) => setNameValue(value.target.value)}
                />
              </div>
              <Dropdown
                label={"Departamento"}
                placeholder={"Escolher departamento deste grupo de permissões"}
                itemsWidth={"100%"}
                items={accountTypes}
                onUrlChange={getAccountTypes}
                value={accountTypeValue}
                onValueChange={setAccountTypeValue}
                required={true}
              />
              
              {
                permissions ?
                <>
                  {/* PERMISSÕES DUTY */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>DUTY</h3>
                    <div className={styles.descCheck}>
                      <p className={styles.permissionDescription}>Aceder ao menu "Duty" e fazer duties.</p>
                      {permissions.duty.GET ? <CiSquareCheck onClick={() => {permissions.duty.GET = false; permissions.duty.PUT = false; permissions.duty.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.duty.GET = true; permissions.duty.PUT = true; permissions.duty.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Adicionar, editar e eliminar duty reports, tipos e áreas de duties.</p>
                      {permissions.duty.DELETE ? <CiSquareCheck onClick={() => {permissions.duty.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.duty.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                  </div>
                  {/* PERMISSÕES AVARIAS */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>AVARIAS</h3>
                    <div className={styles.descCheck}>
                      <p className={styles.permissionDescription}>Mudar o estado da avaria, adicionar áreas e tipos de avarias.</p>
                      {permissions.malfunctions.PUT ? <CiSquareCheck onClick={() => {permissions.malfunctions.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.malfunctions.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Editar e conferir avarias, editar e eliminar áreas e tipos de avarias.</p>
                      {permissions.malfunctions.DELETE ? <CiSquareCheck onClick={() => {permissions.malfunctions.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.malfunctions.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                  </div>
                  {/* PERMISSÕES HOUSEKEEPING */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>HOUSEKEEPING</h3>
                    <div className={styles.descCheck}>
                      <p className={styles.permissionDescription}>Mudar o estado do pedido, adicionar tipos de pedidos.</p>
                      {permissions.housekeeping.PUT ? <CiSquareCheck onClick={() => {permissions.housekeeping.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.housekeeping.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Editar pedidos, editar e eliminar tipos de pedidos.</p>
                      {permissions.housekeeping.DELETE ? <CiSquareCheck onClick={() => {permissions.housekeeping.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.housekeeping.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    {/* <div className={styles.descCheck} style={{border: "0"}}>
                        <p className={styles.permissionDescription}>Permitir contagem de roupa.</p>
                        {permissions["linen-count"].GET ? <CiSquareCheck onClick={() => {permissions["linen-count"].GET = false; permissions["linen-count"].POST = false; permissions["linen-count"].PUT = false; permissions["linen-count"].DELETE = false; permissions["linen-group"].GET = false; permissions["linen-group"].POST = false; permissions["linen-group"].PUT = false; permissions["linen-group"].DELETE = false; permissions["linen-suppliers"].GET = false; permissions["linen-suppliers"].POST = false; permissions["linen-suppliers"].PUT = false; permissions["linen-suppliers"].DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions["linen-count"].GET = true; permissions["linen-count"].POST = true; permissions["linen-count"].PUT = true; permissions["linen-count"].DELETE = true; permissions["linen-group"].GET = true; permissions["linen-group"].POST = true; permissions["linen-group"].PUT = true; permissions["linen-group"].DELETE = true; permissions["linen-suppliers"].GET = true; permissions["linen-suppliers"].POST = true; permissions["linen-suppliers"].PUT = true; permissions["linen-suppliers"].DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div> */}
                  </div>
                  {/* PERMISSÕES MANUTENÇÃO - TRABALHOS */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>MANUTENÇÃO</h3>
                    <div style={{borderLeft: "1px solid var(--placeholder-color-opacity)", borderRadius: "4px"}}>
                      <h4 className={styles.subtitle}>Trabalhos</h4>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Aceder ao menu "Trabalhos".</p>
                        {permissions.jobs.GET ? <CiSquareCheck className={styles.check} onClick={() => {permissions.jobs.GET = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.jobs.GET = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Agendar trabalhos.</p>
                        {permissions.jobs.POST ? <CiSquareCheck className={styles.check} onClick={() => {permissions.jobs.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.jobs.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Realizar trabalhos agendados.</p>
                        {permissions.jobs.PUT ? <CiSquareCheck className={styles.check} onClick={() => {permissions.jobs.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.jobs.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar e eliminar trabalhos agendados, aceder ao backoffice dos trabalhos.</p>
                        {permissions.jobs.DELETE ? <CiSquareCheck className={styles.check} onClick={() => {permissions.jobs.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.jobs.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Adicionar tarefas.</p>
                        {permissions.tasks.POST ? <CiSquareCheck className={styles.check} onClick={() => {permissions.tasks.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.tasks.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar tarefas.</p>
                        {permissions.tasks.PUT ? <CiSquareCheck className={styles.check} onClick={() => {permissions.tasks.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.tasks.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Eliminar tarefas.</p>
                        {permissions.tasks.DELETE ? <CiSquareCheck className={styles.check} onClick={() => {permissions.tasks.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.tasks.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Adicionar intervenções.</p>
                        {permissions.interventions.POST ? <CiSquareCheck className={styles.check} onClick={() => {permissions.interventions.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.interventions.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar intervenções.</p>
                        {permissions.interventions.PUT ? <CiSquareCheck className={styles.check} onClick={() => {permissions.interventions.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.interventions.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Eliminar intervenções.</p>
                        {permissions.interventions.DELETE ? <CiSquareCheck className={styles.check} onClick={() => {permissions.interventions.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.interventions.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Adicionar unidades de medida.</p>
                        {permissions.units.POST ? <CiSquareCheck className={styles.check} onClick={() => {permissions.units.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.units.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck} style={{border: "0"}}>
                        <p className={styles.permissionDescription}>Eliminar unidades de medida.</p>
                        {permissions.units.DELETE ? <CiSquareCheck className={styles.check} onClick={() => {permissions.units.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.units.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                    </div>

                    <div style={{borderLeft: "1px solid var(--placeholder-color-opacity)", borderRadius: "4px"}}>
                      <h4 className={styles.subtitle}>Equipamentos</h4>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Aceder ao menu "Equipamentos".</p>
                        {permissions.equipments.GET ? <CiSquareCheck className={styles.check} onClick={() => {permissions.equipments.GET = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.equipments.GET = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Criar categorias, subcategorias, equipamentos e ativos.</p>
                        {permissions.equipments.POST ? <CiSquareCheck className={styles.check} onClick={() => {permissions.equipments.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.equipments.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar categorias, subcategorias, equipamentos e ativos.</p>
                        {permissions.equipments.PUT ? <CiSquareCheck className={styles.check} onClick={() => {permissions.equipments.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.equipments.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck} style={{border: "0"}}>
                        <p className={styles.permissionDescription}>Eliminar categorias, subcategorias, equipamentos e ativos.</p>
                        {permissions.equipments.DELETE ? <CiSquareCheck className={styles.check} onClick={() => {permissions.equipments.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.equipments.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                    </div>
                  </div>
                  {/* PERMISSÕES ESTATÍSTICAS */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>ESTATÍSTICAS</h3>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Aceder às estatísticas.</p>
                      {permissions.statistics.GET ? <CiSquareCheck onClick={() => {permissions.statistics.GET = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.statistics.GET = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Aceder às estatísticas e fórmulas de tratamento de dados de trabalhos.</p>
                      {permissions.costs.GET ? <CiSquareCheck onClick={() => {permissions.costs.GET = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.costs.GET = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Exportar dados dos trabalhos.</p>
                      {permissions.costs.POST ? <CiSquareCheck onClick={() => {permissions.costs.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.costs.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                  </div>
                  {/* PERMISSÕES LOCAIS */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>LOCAIS</h3>
                    <div className={styles.descCheck}>
                      <p className={styles.permissionDescription}>Adicionar locais.</p>
                      {permissions.locals.POST ? <CiSquareCheck onClick={() => {permissions.locals.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.locals.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck}>
                      <p className={styles.permissionDescription}>Editar locais.</p>
                      {permissions.locals.PUT ? <CiSquareCheck onClick={() => {permissions.locals.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.locals.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                    <div className={styles.descCheck} style={{border: "0"}}>
                      <p className={styles.permissionDescription}>Eliminar locais.</p>
                      {permissions.locals.DELETE ? <CiSquareCheck onClick={() => {permissions.locals.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.locals.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                    </div>
                  </div>
                  {/* PERMISSÕES UTILIZADORES */}
                  <div className={styles.permissionDivision}>
                    <h3 className={styles.divisionTitle}>UTILIZADORES</h3>
                    <div style={{borderLeft: "1px solid var(--placeholder-color-opacity)", borderRadius: "4px"}}>
                      <h4 className={styles.subtitle}>Utilizadores</h4>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Adicionar utilizadores.</p>
                        {permissions.users.POST ? <CiSquareCheck onClick={() => {permissions.users.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.users.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar, eliminar e desativar utilizadores.</p>
                        {permissions.users.DELETE ? <CiSquareCheck onClick={() => {permissions.users.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.users.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck} style={{border: "0"}}>
                        <p className={styles.permissionDescription}>Ver e atribuir todos os hóteis.</p>
                        {permissions.clients.PUT ? <CiSquareCheck onClick={() => {permissions.clients.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.clients.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                    </div>

                    <div style={{borderLeft: "1px solid var(--placeholder-color-opacity)", borderRadius: "4px"}}>
                      <h4 className={styles.subtitle}>Permissões</h4>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Adicionar grupos de permissões de utilizadores.</p>
                        {permissions.groups.POST ? <CiSquareCheck onClick={() => {permissions.groups.POST = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.groups.POST = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck}>
                        <p className={styles.permissionDescription}>Editar grupos de permissões de utilizadores.</p>
                        {permissions.groups.PUT ? <CiSquareCheck onClick={() => {permissions.groups.PUT = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.groups.PUT = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                      <div className={styles.descCheck} style={{border: "0"}}>
                        <p className={styles.permissionDescription}>Eliminar grupos de permissões de utilizadores.</p>
                        {permissions.groups.DELETE ? <CiSquareCheck onClick={() => {permissions.groups.DELETE = false; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#21ba45"}/> : <CiSquareRemove onClick={() => {permissions.groups.DELETE = true; setUpdatedPermissions({...updatedPermissions});}} size={30} className={styles.check} color={"#ff4f5a"}/>}
                      </div>
                    </div>
                  </div>
                </>
                : ""
              }

              {
                id
                ? <button className={styles.submitBtn} onClick={() => {changeName(id)}} type="">EDITAR</button>
                : <button className={styles.submitBtn} onClick={() => {addGroup()}} type="">ADICIONAR</button>
              }
            </div>
          </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarGruposUtilizadores;
import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import styles from "./Trabalhos.module.css";
import GetDate from "../../../Components/GetDate/GetDate";
import { useRef, useEffect } from "react";
import axios from "axios";
import { Url } from "../../../Constants/globals";
import { NavLink, useLocation, useNavigate  } from "react-router-dom";
import { IoPlayCircleOutline, IoPerson, IoLocationSharp, IoSearch, IoArrowBackCircleOutline, IoArrowForwardCircleOutline, IoPauseCircleOutline } from "react-icons/io5";
import { HiPencilSquare, HiCog6Tooth, HiTrash, HiCheck, HiOutlineInformationCircle } from "react-icons/hi2";
import { MdAdd } from "react-icons/md";
import ProgressBar from "@ramonak/react-progress-bar";
import RefreshToken from "../../../RefreshToken/RefreshToken";
import getLocal from "../../../Components/GetLocalName/GetLocalName";
import { useDebounce } from "../../../Components/Debounce/Debounce";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
import Loading from "../../../Components/Loading/Loading";
import Alert from "../../../Components/Alert/Alert";
import LoadingAnimationOnly from "../../../Components/LoadingAnimationOnly/LoadingAnimationOnly";
import EncryptDecryptStorage from "../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function Trabalhos({permissions}) {

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loadingAnimationOnly, setLoadingAnimationOnly] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [selectedJob, setSelectedJob] = useState("");
  const [jobStatus, setJobStatus] = useState(state ? state.from ? state.from : "disponiveis" : "disponiveis");
  const [jobStatusId, setJobStatusId] = useState(state ? (state.from === "agendados" ? "agendados" : state.from === "disponiveis" ? "1" : state.from === "ativos" ? "2" : state.from === "pausados" ? "4" : state.from === "completos" ? "3" : state.from === "my" ? "my" : "1") : "1");
  const [startPageUrl, setStartPageUrl] = useState(true);
  const [pageUrl, setPageUrl] = useState(state?.page ? state.page : "");
  const [jobsData, setJobsData] = useState([]);
  const [jobsCount, setJobsCount] = useState(0);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [chosenIdModal, setChosenIdModal] = useState(null);
  const [searchName, setSearchName] = useState(localStorage.getItem("jobSearchName") ?? "");
  const debouncedSearchName = useDebounce(searchName, 250);
  const [deleteModalJob, setDeleteModalJob] = useState(false);

  // Deteta quando clica fora do modal de ações para fechá-lo
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) setChosenIdModal(null);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);
  }

  const getJobs = async (status, search, page) => {
    let url = status === "my" ? `${Url}/jobs/my-schedules/?start=0&limit=15` : status === "agendados" ? `${Url}/jobs?start=0&limit=15` : `${Url}/jobs/schedules/?start=0&limit=15&status=${status}`;
    const finalUrl = startPageUrl && pageUrl ? pageUrl : page ? page : `${url}${search ? `&name=${search}` : ``}`;
    axios.get(finalUrl, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setPageUrl(finalUrl);
      setJobsData(res.data.detail);
      setJobsCount(res.data.count);
      setNext(res.data.next);
      setPrevious(res.data.previous);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getJobs(status, search, page) : window.location.reload();
      }
      else {
        setNext("");
        setPrevious("");
        setJobsData([]);
        setJobsCount(0);
      }
    }).finally(() => {
      setStartPageUrl(false);
      setLoading(false);
      setLoadingAnimationOnly(false);
    });
  };

  const deleteJob = async () => {
    await setAlert();
    setSpinner(true);
    await axios.delete(Url + "/jobs/"+selectedJob.id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(async (res) => {
      setAlert(<Alert type={"success"}>Trabalho eliminado com sucesso</Alert>);
      setTimeout(() => {
        setLoadingAnimationOnly(true);
        getJobs("agendados", searchName, false);
        setSpinner(false);
      }, 1000);
    })
    .catch(async (err) => {
      setSpinner(false);
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? deleteJob() : window.location.reload();
      }
      setAlert(<Alert type={"error"}>Erro ao apagar trabalho</Alert>);
    });
  }

  const changeStatusJob = (id, status) => {
    axios.put(Url +"/jobs/schedules/"+id, {
      status_id: status
    }, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      }
    })
    .then(async () => {
      await setJobStatus(status === 2 ? "ativos" : status === 4 ? "pausados" : "completos");
      await setJobStatusId(status.toString());
      await getJobs(status, searchName, false);
    })
    .catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? changeStatusJob(id, status) : window.location.reload();
      }
      else return "error";
    });
  }

  const searchData = (name) => {
    setLoadingAnimationOnly(true);
    setSearchName(name);
    localStorage.setItem("jobSearchName", name.trim());
  }

  const getDateNoTime = (date) => {
    var rightDate = new Date(date);
    rightDate.setHours(0);
    return rightDate;
  }

  const isTimeInvalid = (time) => {
    const now = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const compareNow = Number(now.split(":")[0]) * 60 + Number(now.split(":")[1]);
    const compareTime = Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]);

    if (compareNow > compareTime) return true;
    return false;
  }

  useEffect(() => {
    if (jobStatus === "my") getJobs("my", debouncedSearchName, false);
    else if (jobStatus === "agendados") getJobs("agendados", debouncedSearchName, false);
    else getJobs(jobStatusId, debouncedSearchName, false);
  }, [debouncedSearchName]);

  const handlePageChange = (url) => {
    setLoadingAnimationOnly(true);
    setJobsCount("");
    getJobs(jobStatusId, searchName, url);
  }

  const handleStatusChange = async (name, id) => {
    setLoadingAnimationOnly(true);
    setJobsCount("");
    await setJobStatus(name);
    await setJobStatusId(id);
    if (name === "my") await getJobs("my", searchName, false);
    else if (name === "agendados") await getJobs("agendados", searchName, false);
    else await getJobs(id, searchName, false);
  }

  const handle3dots = (e, id) => {
    e.preventDefault();
    setChosenIdModal(id);
  }

  const JobsList = () => {
    return (
      <>
        <div className={styles.jobSection}>
          <p>Trabalho</p>
          <p>{ jobStatus === "agendados" ? "Detalhes" : "Locais"}</p>
          <p>Descrição</p>
        </div>
        {
          jobsData.map((job) => (
            <div key={job.id}>
              <NavLink className={styles.jobContainer} to={ jobStatus === "agendados" ? "/manutencao/trabalhos/editar/"+job.id : "/manutencao/trabalhos/detalhes/"+job.id} state={{from: jobStatus ? jobStatus : "", page: searchName ? "" : pageUrl}}>
                <div className={styles.iconArea}>
                  <div style={{ display: "flex", justifyContent: "center"}}>
                    <HiCog6Tooth style={{padding: "2px"}} size={20} color={ jobStatus === "agendados" ? "#ff9436" : jobStatus === "disponiveis" ? "#529eff" : (jobStatus === "ativos" || jobStatus === "my") ? "#38d189" : jobStatus === "completos" ? "#21ba45" : "#ff9436"}/>
                  </div>
                  <div className={styles.jobInfo}>
                    <h4>{job.name ? job.name : ""}</h4>
                    <div className={styles.createdbyAndDate}>
                      <p style={jobStatus === "disponiveis" && (new Date() > getDateNoTime(job.date) && isTimeInvalid(job.hour)) ? {color: "#d2797f"} : {}}>{ jobStatus === "agendados" ? GetDate(job.date_start, true) : GetDate(new Date(job.date), true)} {jobStatus === "agendados" && job.date_end !== job.date_start ? "até "+GetDate(job.date_end, true) : ""} às {jobStatus === "agendados" ? job.time : job.hour}</p>
                    </div>
                    {
                      (jobStatus === "ativos" || jobStatus === "my" || jobStatus === "pausados") ?
                      <div>
                        <p style={{color: jobStatus === "pausados" ? "#ffab40" : "#38d189", marginBottom: "10px"}}>{jobStatus === "pausados" ? "Em pausa: "+job.percent+" %" : job.percent > 0.02 ? "Em curso: "+job.percent+" %" : "Em curso..."}</p>
                        <ProgressBar completed={job.percent === 0.02 ? 1 : job.percent} height={"7px"} labelSize={"0px"} bgColor={ jobStatus === "pausados" ? "#ffab40" : "#38d189"} />
                      </div>
                      : jobStatus === "completos" ?
                      <div>
                        <p style={{color: "#21ba45", marginBottom: "10px"}}>Concluído</p>
                        <ProgressBar completed={100} height={"7px"} labelSize={"0px"} bgColor={"#21ba45"} />
                      </div>
                    : ""
                    }
                  </div>
                </div>
                <div className={styles.iconArea}>
                  {
                    jobStatus === "agendados" ?
                    <div className={styles.jobInfo}>
                      <div style={{marginLeft: "34px"}} className={styles.createdbyAndDate}>
                        <p>Ação <label style={{textTransform: "lowercase"}}>{job.job_type}</label></p>
                        <p>{job.interventions === 1 ? job.interventions + " intervenção" : job.interventions +" intervenções"} em {job?.locals?.length === 1 ? job?.locals?.length+" local" : job?.locals?.length+" locais"}</p>
                        <p style={{marginTop: "5px"}}>Criado por: {job.created_by}</p>
                      </div>
                    </div>
                    :
                    <div className={styles.iconArea}>
                  <div style={{width: "24px", display: "flex", justifyContent: "right", marginLeft: "10px"}}>
                    <IoLocationSharp size={19}/>
                  </div>
                  <div className={styles.jobInfo}>
                    <h4 style={{letterSpacing: "0.4px"}}>
                      {
                        job?.locals?.map((local, index) => (
                          <p key={index} >{index > 1 ? "" : getLocal(local.building_name, local.block_name, local.floor_name, local.name)}</p>
                        ))
                      }
                      {
                        job?.locals?.length > 2 ?
                        <p>...</p>
                        :
                        ""
                      }
                      </h4>
                  </div>
                </div>
                  }
                </div>
                <div className={styles.jobDetails}>
                  <div className={styles.description}>
                    { job?.description?.length > 0 ? <p>{job?.description?.length > 200 ? job?.description?.slice(0, 201)+"..." : job?.description }</p> : ""}
                  </div>
                  {
                    permissions.jobs &&
                    (permissions.jobs.PUT ||  permissions.jobs.DELETE) &&
                    ((jobStatus === "completos")) ?
                      <div></div>
                    :
                      <div className={styles.actions} onClick={(e) =>  handle3dots(e, job.id) }>
                        <img src="/3dots.svg" height={20} alt=""/>
                        {
                          chosenIdModal === job.id ?
                            <div ref={wrapperRef} className={styles.actionsModal}>
                              {
                                jobStatus === "disponiveis" ?
                                  <div className={styles.actionsModalOption} onClick={() => { changeStatusJob(job.id, 2); }}>
                                    <IoPlayCircleOutline color="#38d189" size={22}/>
                                    Iniciar
                                  </div>
                                : ""
                              }
                              {
                                jobStatus === "agendados" ?
                                  <div className={styles.actionsModalOption} onClick={() => navigate("/manutencao/trabalhos/editar/"+job.id, { state:{from: ""}})}>
                                    <HiPencilSquare color="#529eff" size={18} style={{margin: "2px"}}/>
                                    Editar
                                  </div>
                                : ""
                              }
                              {
                                jobStatus === "agendados" ?
                                  <div className={styles.actionsModalOption} onClick={async () => { await setSelectedJob(job); await setDeleteModalJob(true); await setChosenIdModal(null);}}>
                                    <HiTrash color="#ff4f5a" size={18}/>
                                    Eliminar
                                  </div>
                                : ""
                              }
                              {
                                jobStatus === "ativos" ?
                                  <div className={styles.actionsModalOption} onClick={async () => { changeStatusJob(job.id, 4);}}>
                                    <IoPauseCircleOutline color="#ffab40" size={18}/>
                                    Pausar
                                  </div>
                                : ""
                              }
                              {
                                jobStatus === "pausados" ?
                                  <div className={styles.actionsModalOption} onClick={() => changeStatusJob(job.id, 2)}>
                                    <IoPlayCircleOutline color="#21ba45" size={18}/>
                                    Retomar
                                  </div>
                                : ""
                              }
                              {
                                jobStatus === "ativos" && job.percent === 100 ?
                                  <div className={styles.actionsModalOption} onClick={async () => { changeStatusJob(job.id, 3);}}>
                                    <HiCheck color="#21ba45" size={18}/>
                                    Concluir
                                  </div>
                                : ""
                              }
                              {/* {
                                malfunction.status.id === 4 ?
                                <div className={styles.actionsModalOption} onClick={() => setChangeStatusModal([true, malfunction.id, 6])}>
                                <IoRefreshCircleOutline color="#529eff" size={22} style={{margin: "2px"}}/>
                                Reabrir
                              </div>1,2,5
                              : ""
                              } */}
                            </div>
                          : <></>
                        }
                      </div>
                  }
                </div>
              </NavLink>
            </div>
          ))
        }
        <div className={styles.changePages}>
          {previous === "" ? "" :  <IoArrowBackCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(previous) }}/>}
          {next === "" ? "" : <IoArrowForwardCircleOutline className={styles.changePageButtons} size={40} onClick={() => { handlePageChange(next) }}/>}
        </div>
      </>
    );
  }

  return (
    <>
      <Layout tab={"manutenção - trabalhos"} permissions={permissions}>
        {alert}
        {deleteModalJob ? <DeleteModal onClose={setDeleteModalJob} headerText={"ELIMINAR TRABALHO"} confirmationText={"Deseja eliminar "+ selectedJob.name+" ?"} deleteFunction={deleteJob} objectId={selectedJob.id}/> : ""}
        {/* {changeStatusModal[0] ? <ChangeStatusPage malfunctionId={changeStatusModal[1]} status={changeStatusModal[2]} showModal={changeStatusModal} setShowModal={setChangeStatusModal} url={"/malfunctions/"} type={"avaria"} /> : ""} */}
        {
        spinner ? <Loading /> :
        loading ? <></> :
        <div className={styles.content}>
          <div className={styles.searchBar}>
            <div className={styles.jobsStatus}>
              {
                permissions.jobs &&
                permissions.jobs.PUT ?
                  <div title="Os meus trabalhos" className={ jobStatus === "my" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("my", "0"); }}>
                    {jobStatus === "my" ? jobsCount + " " : ""}
                    <IoPerson style={{marginLeft: "3px"}}/>
                  </div>
                :""
              }
              {
                permissions.jobs &&
                permissions.jobs.DELETE ?
                  <div className={ jobStatus === "agendados" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("agendados", ""); }}>
                    {jobStatus === "agendados" ? jobsCount + " " : ""}
                    Agendados
                  </div>
                :""
              }
              <div className={ jobStatus === "disponiveis" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("disponiveis", "1"); }}>
                {jobStatus === "disponiveis" ? jobsCount + " " : ""}
                Disponíveis
              </div>
              <div className={ jobStatus === "ativos" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("ativos", "2"); }}>
                {jobStatus === "ativos" ? jobsCount + " " : ""}
                Ativos
              </div>
              <div className={ jobStatus === "pausados" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("pausados", "4"); }}>
                {jobStatus === "pausados" ? jobsCount + " " : ""}
                Pausados
              </div>
              <div className={ jobStatus === "completos" ? styles.activeStatus : styles.status } onClick={() => { handleStatusChange("completos", "3"); }}>
                {jobStatus === "completos" ? jobsCount + " " : ""}
                Concluídos
              </div>
            </div>
            <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
              {
                permissions.jobs &&
                permissions.jobs.POST ?
                  <NavLink className={styles.addJob} to={"/manutencao/trabalhos/adicionar"} state={{from: jobStatus ? jobStatus : ""}}>
                    <MdAdd className={styles.addIcon} size={22} />
                  </NavLink>
                :""
              }
              <div className={styles.search}>
                <IoSearch className={styles.searchIcon}  />
                <input placeholder={"Procurar"} value={searchName} onChange={(e) => { searchData(e.target.value) }}/>
              </div>
            </div>
          </div>
          {
            loadingAnimationOnly ? <LoadingAnimationOnly/> :
            jobsData.length > 0 ?
              <div className={styles.scrollJobs}>
                <JobsList/>
              </div>
            :
              <div className={styles.noData}>
                <HiOutlineInformationCircle color="#8f8f8f" size={22}/>
                <p className={styles.noDataText}>{jobStatus === "my" ? "Não existem trabalhos aprovados por si" : "Não existem trabalhos "+jobStatus}</p>
              </div>
          }
        </div>
        }
      </Layout>
    </>
  );
}

export default Trabalhos;
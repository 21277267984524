import React, { useState, useCallback } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarTrabalhos.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import MultiSelect from "../../../../Components/MultiSelect/MultiSelect";
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarTrabalhos({permissions}) {

  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [nameValue, setNameValue] = useState("");

  const [descriptionValue, setDescriptionValue] = useState("");

  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypeValue, setJobTypeValue] = useState("");

  const [periodicities, setPeriodicities] = useState([]);
  const [periodicityValue, setPeriodicityValue] = useState("");

  const [interventions, setInterventions] = useState([]);
  const [selectedInterventions, setSelectedInterventions] = useState("");

  const [locais, setLocais] = useState([]);
  const [previousLocais, setPreviousLocais] = useState("");
  const [nextLocais, setNextLocais] = useState("");
  const [selectedLocais, setSelectedLocais] = useState([]);
  const urlLocais = Url + "/locals?start=0&limit=16";

  const dailyPeriodicities = [{id: 1, name: "Todos os dias úteis", each: 0}, {id: 2, name: "Todos os dias", each: "1"}];
  const [dailyPeriodicityValue, setDailyPeriodicityValue] = useState("");
  
  const weekdays = [{id: 1, name: "Segunda-feira"}, {id: 2, name: "Terça-feira"}, {id: 3, name: "Quarta-feira"}, {id: 4, name: "Quinta-feira"}, {id: 5, name: "Sexta-feira"}, {id: 6, name: "Sábado"}, {id: 7, name: "Domingo"}];
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [weeklyPeriodicityValue, setWeeklyPeriodicityValue] = useState("1");
  
  const [monthPeriodicities, setMonthPeriodicities] = useState([]);
  const [monthPeriodicityValue, setMonthPeriodicityValue] = useState("");
  const [initialMonthValue, setInitialMonthValue] = useState(new Date());
  const [finalMonthValue, setFinalMonthValue] = useState(new Date().setMonth(new Date().getMonth() + 1, 1));
  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [anualEachMonth, setAnualEachMonth] = useState(1);
  const [anualDay, setAnualDay] = useState(1);
  const [selectedTime, setSelectedTime] = useState("");
  const [initialDateValue, setInitialDateValue] = useState(new Date().toISOString().slice(0, 10));
  const [finalDateValue, setFinalDateValue] = useState(new Date(+new Date() + 86400000).toISOString().slice(0, 10));
  const jobStatus = state ? state.from : "";

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getJobDetails = useCallback((id) => {
    axios.get(Url+"/jobs/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        await setNameValue(res.data.name);
        await setDescriptionValue(res.data.description);
        await setJobTypeValue(res.data.job_type);
        await setSelectedInterventions(res.data.interventions);
        await setSelectedLocais(res.data.locals);
        await setPeriodicityValue(res.data.periodicity);
        if (res.data.periodicity.id === 4 || res.data.periodicity.id === 5){
          await setInitialMonthValue(new Date(res.data.date_start));
          await setFinalMonthValue(new Date(res.data.date_end));
        }
        else {
          await setInitialDateValue(res.data.date_start);
          await setFinalDateValue(res.data.date_end);
        }
        if (res.data.periodicity.id === 2 && !res.data.only_weekdays){
          setDailyPeriodicityValue({id: 2, name: "Todos os dias", each: res.data.every});
        }
        else {
          setDailyPeriodicityValue({id: 1, name: "Todos os dias úteis", each: 0});
        }
        await setAnualEachMonth(res.data.periodicity.id === 5 ? res.data.every : 1);
        
        // importante para evitar duplicações
        await setSelectedWeekdays([]);
        for (let x=0; x<weekdays.length; x++) {
          if (res.data.weekdays.includes(weekdays[x].id-1) && !selectedWeekdays.includes(weekdays[x])){
            await setSelectedWeekdays(selectedWeekdays => [...selectedWeekdays, weekdays[x]]);
          }
        }
        await setSelectedTime(res.data.time);
        await setWeeklyPeriodicityValue(res.data.every ? res.data.every : 1);
        await setMonthPeriodicityValue(res.data.schedule_month ? res.data.schedule_month : "");
        await setDayOfMonth(res.data.schedule_month ? (res.data.schedule_month.id === 1 ? new Date(res.data.date_start).getDate() : 1) :"");
        await setAnualDay(res.data.periodicity.id === 5 ? new Date(res.data.date_start).getDate() : 1);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getJobDetails(id) : window.location.reload();
      }
      else {
        console.log(err);
      }
      await setLoading(false);
    })
  }, []);

  const getJobTypes = useCallback(() => {
    axios.get(Url+"/jobs/job-types/", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setJobTypes(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getJobTypes() : window.location.reload();
      }
    })
  }, []);
  
  const getPeriodicities = useCallback(() => {
    axios.get(Url+"/jobs/schedule-periodicities/", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        setPeriodicities(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getPeriodicities() : window.location.reload();
      }
    })
  }, []);

  const getMonthPeriodicities = useCallback(() => {
    axios.get(Url+"/jobs/month-schedules/", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setMonthPeriodicities(res.data);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getMonthPeriodicities() : window.location.reload();
      }
    })
  }, []);

  const getInterventions = useCallback(() => {
    axios.get(Url+"/interventions?start=0&limit=5000", {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setInterventions(res.data.detail);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getInterventions() : window.location.reload();
      }
    })
  }, []);

  const getLocais = useCallback((urlLocais) => {
    axios.get(urlLocais, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setLocais(res.data.detail);
      setPreviousLocais(res.data.previous);
      setNextLocais(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getLocais(urlLocais) : window.location.reload();
      }
      else {
        setLocais([]);
        setNextLocais("");
        setPreviousLocais("");
      }
    })
  }, []);

  useEffect(() => {
    getLocais(urlLocais);
  }, [urlLocais, getLocais]);

  useEffect(() => {
    getJobTypes();
    getPeriodicities();
    getMonthPeriodicities();
    getInterventions();
    if (id){
      getJobDetails(id);
    }
    else {
      setLoading(false);
    }
  }, [id, getInterventions, getJobDetails, getPeriodicities, getMonthPeriodicities, getJobTypes, navigate]);

  const addJob = async () => {
    await setAlert();
    if (nameValue === "" || jobTypeValue === "" || selectedInterventions.length === 0 || periodicityValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    else if (selectedTime === "") {
      return setAlert(<Alert type={"error"}>Escolha a hora de início do trabalho</Alert>);
    }
    else if (periodicityValue.id === 2 && dailyPeriodicityValue === "") {
      return setAlert(<Alert type={"error"}>Escolha o tipo de periodicidade diária</Alert>);
    }
    else if ((periodicityValue.id === 2 || periodicityValue.id === 3) && finalDateValue < initialDateValue){
      return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    else if (periodicityValue.id === 3 && weeklyPeriodicityValue < 1) {
      return setAlert(<Alert type={"error"}>Escolha de quantas em quantas semanas deseja marcar</Alert>);
    }
    else if ((periodicityValue.id === 3 || (periodicityValue.id === 4 && (monthPeriodicityValue.id === 2 || monthPeriodicityValue.id === 3))) && selectedWeekdays.length === 0) {
      return setAlert(<Alert type={"error"}>Escolha os dias da semana desejados</Alert>);
    }
    else if (periodicityValue.id === 4 && monthPeriodicityValue === "") {
      return setAlert(<Alert type={"error"}>Escolha como deseja marcar mensalmente</Alert>);
    }
    else if ((periodicityValue.id === 4 || periodicityValue.id === 5) && initialMonthValue > new Date(finalMonthValue)) {
      return setAlert(<Alert type={"error"}>O mês inicial não pode ser mais recente do que o final</Alert>);
    }
    else if ((periodicityValue.id === 4 || periodicityValue.id === 5) && (initialMonthValue.getFullYear() < new Date().getFullYear() || initialMonthValue.getMonth() < new Date().getMonth() || new Date(new Date(finalMonthValue).getFullYear()+"-"+(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')+"-"+dayOfMonth) < new Date())) {
      if ((monthPeriodicityValue.id === 1) && (new Date(initialMonthValue.getFullYear()+"-"+(initialMonthValue.getMonth()+1).toString().padStart(2, '0')+"-"+dayOfMonth) < new Date() || new Date(new Date(finalMonthValue).getFullYear()+"-"+(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')+"-"+dayOfMonth) < new Date())) {
        return setAlert(<Alert type={"error"}>Não pode marcar trabalhos para o passado</Alert>);
      }
      return setAlert(<Alert type={"error"}>Não pode marcar trabalhos para o passado</Alert>);
    }
    else if (periodicityValue.id === 4 && monthPeriodicityValue.id === 1 && (dayOfMonth === "" || dayOfMonth < 1 || dayOfMonth > 31)) {
      return setAlert(<Alert type={"error"}>Escolha um dia do mês válido</Alert>);
    }
    else if (periodicityValue.id === 5 && anualEachMonth < 1) {
      return setAlert(<Alert type={"error"}>Escolha de quantos em quantos meses deseja marcar</Alert>);
    }
    else if (periodicityValue.id === 5 && (anualDay < 1 && anualDay > 31)) {
      return setAlert(<Alert type={"error"}>Escolha um dia do mês válido</Alert>);
    }
      setSpinner(true);
      var finalInterventions = [];
      for (var a = 0; a < selectedInterventions.length; a++){
        finalInterventions.push(selectedInterventions[a].id);
      }
      var finalLocals = [];
      for (var b = 0; b < selectedLocais.length; b++){
        finalLocals.push(selectedLocais[b].local.id);
      }
      let body = {};
      var finalWeekDays = [];
      switch (periodicityValue.id) {
        case 1:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: finalDateValue,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: 0,
            schedule_month_id: 0,
            only_weekdays: false
          }
          break;
        case 2:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: initialDateValue,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: dailyPeriodicityValue.each,
            schedule_month_id: 0,
            only_weekdays: dailyPeriodicityValue.id === 1
          }
          break;
        case 3:
          for(let y = 0; y < selectedWeekdays.length; y++){
            finalWeekDays.push((selectedWeekdays[y].id)-1);
          }
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: initialDateValue,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: weeklyPeriodicityValue-1,
            schedule_month_id: 0,
            only_weekdays: false,
            week_days: finalWeekDays
          }
          break;
        case 4:
          for(let y = 0; y < selectedWeekdays.length; y++){
            finalWeekDays.push((selectedWeekdays[y].id)-1);
          }
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: `${initialMonthValue.getFullYear()}-${(initialMonthValue.getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}`,
            date_end: `${new Date(finalMonthValue).getFullYear()}-${(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}`,
            periodicity_id: periodicityValue.id,
            every: 0,
            schedule_month_id: monthPeriodicityValue.id,
            only_weekdays: false,
            week_days: finalWeekDays.length > 0 ? finalWeekDays : null
          }
          break;
        case 5:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: `${initialMonthValue.getFullYear()}-${(initialMonthValue.getMonth()+1).toString().padStart(2, '0')}-${anualDay}`,
            date_end: `${new Date(finalMonthValue).getFullYear()}-${(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')}-${anualDay}`,
            periodicity_id: periodicityValue.id,
            every: anualEachMonth,
            schedule_month_id: 0,
            only_weekdays: false
          }
          break;
        default:
          break;
      }
      await axios
      .post(Url + "/jobs", body, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Trabalho criado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/manutencao/trabalhos", jobStatus ? {state: { from: jobStatus}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addJob() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err?.response?.data?.detail ? err.response.data.detail : "Erro ao criar trabalho"}</Alert>);
        }
      });
  }

  const editJob = async (id) => {
    await setAlert();
    if (nameValue === "" || jobTypeValue === "" || selectedInterventions.length === 0 || periodicityValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    else if (selectedTime === "") {
      return setAlert(<Alert type={"error"}>Escolha a hora de início do trabalho</Alert>);
    }
    else if (periodicityValue.id === 2 && dailyPeriodicityValue === "") {
      return setAlert(<Alert type={"error"}>Escolha o tipo de periodicidade diária</Alert>);
    }
    else if ((periodicityValue.id === 2 || periodicityValue.id === 3) && finalDateValue < initialDateValue){
      return setAlert(<Alert type={"error"}>A data inicial não pode ser maior do que a data final</Alert>);
    }
    else if (periodicityValue.id === 3 && weeklyPeriodicityValue < 1) {
      return setAlert(<Alert type={"error"}>Escolha de quantas em quantas semanas deseja marcar</Alert>);
    }
    else if ((periodicityValue.id === 3 || (periodicityValue.id === 4 && (monthPeriodicityValue.id === 2 || monthPeriodicityValue.id === 3))) && selectedWeekdays.length === 0) {
      return setAlert(<Alert type={"error"}>Escolha os dias da semana desejados</Alert>);
    }
    else if (periodicityValue.id === 4 && monthPeriodicityValue === "") {
      return setAlert(<Alert type={"error"}>Escolha como deseja marcar mensalmente</Alert>);
    }
    else if ((periodicityValue.id === 4 || periodicityValue.id === 5) && initialMonthValue > new Date(finalMonthValue)) {
      return setAlert(<Alert type={"error"}>O mês inicial não pode ser mais recente do que o final</Alert>);
    }
    else if ((periodicityValue.id === 4 || periodicityValue.id === 5) && (initialMonthValue.getFullYear() < new Date().getFullYear() || initialMonthValue.getMonth() < new Date().getMonth() || new Date(`${new Date(finalMonthValue).getFullYear()}-${(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}`) < new Date())) {
      if ((monthPeriodicityValue.id === 1) && (new Date(`${initialMonthValue.getFullYear()}-${(initialMonthValue.getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth}`) < new Date() || new Date(`${new Date(finalMonthValue).getFullYear()}-${(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}}`) < new Date())) {
        return setAlert(<Alert type={"error"}>Não pode marcar trabalhos para o passado</Alert>);
      }
      return setAlert(<Alert type={"error"}>Não pode marcar trabalhos para o passado</Alert>);
    }
    else if (periodicityValue.id === 4 && monthPeriodicityValue.id === 1 && (dayOfMonth === "" || dayOfMonth < 1 || dayOfMonth > 31)) {
      return setAlert(<Alert type={"error"}>Escolha um dia do mês válido</Alert>);
    }
    else if (periodicityValue.id === 5 && anualEachMonth < 1) {
      return setAlert(<Alert type={"error"}>Escolha de quantos em quantos meses deseja marcar</Alert>);
    }
    else if (periodicityValue.id === 5 && (anualDay < 1 && anualDay > 31)) {
      return setAlert(<Alert type={"error"}>Escolha um dia do mês válido</Alert>);
    }
      setSpinner(true);
      var finalInterventions = [];
      for (var a = 0; a < selectedInterventions.length; a++){
        finalInterventions.push(selectedInterventions[a].id);
      }
      var finalLocals = [];
      for (var b = 0; b < selectedLocais.length; b++){
        finalLocals.push(selectedLocais[b].local.id);
      }
      let body = {};
      switch (periodicityValue.id) {
        case 1:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: finalDateValue,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: 0,
            schedule_month_id: 0,
            only_weekdays: false
          }
          break;
        case 2:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: initialDateValue,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: dailyPeriodicityValue.each,
            schedule_month_id: 0,
            only_weekdays: dailyPeriodicityValue.id === 1
          }
          break;
        case 3:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_end: finalDateValue,
            periodicity_id: periodicityValue.id,
            every: weeklyPeriodicityValue,
            schedule_month_id: 0,
            only_weekdays: false,
            week_day: selectedWeekdays
          }
          break;
        case 4:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: `${initialMonthValue.getFullYear()}-${(initialMonthValue.getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}`,
            date_end: `${new Date(finalMonthValue).getFullYear()}-${(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')}-${dayOfMonth >= 10 ? dayOfMonth : `0${dayOfMonth}`}`,
            periodicity_id: periodicityValue.id,
            every: 0,
            schedule_month_id: monthPeriodicityValue.id,
            only_weekdays: false,
            week_days: selectedWeekdays.length > 0 ? selectedWeekdays : null
          }
          break;
        case 5:
          body = {
            name: nameValue,
            description: descriptionValue,
            job_type_id: jobTypeValue.id,
            interventions: finalInterventions,
            locals: finalLocals,
            time: selectedTime,
            date_start: initialMonthValue.getFullYear()+"-"+(initialMonthValue.getMonth()+1).toString().padStart(2, '0')+"-"+anualDay,
            date_end: new Date(finalMonthValue).getFullYear()+"-"+(new Date(finalMonthValue).getMonth()+1).toString().padStart(2, '0')+"-"+anualDay,
            periodicity_id: periodicityValue.id,
            every: anualEachMonth,
            schedule_month_id: 0,
            only_weekdays: false
          }
          break;
        default:
          break;
      }
    await axios
      .put(Url + "/jobs/"+id, body, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async () => {
        setAlert(<Alert type={"success"}>Trabalho editado com sucesso</Alert>);
        setTimeout(() => {
          navigate("/manutencao/trabalhos", jobStatus ? {state: { from: jobStatus}} : "");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editJob(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err?.response?.data?.detail ?? "Erro ao editar trabalho"}</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "manutenção - trabalho #"+id : "manutenção - trabalhos"} permissions={permissions}>
        {alert}
        { 
          spinner ? <Loading /> :
          loading ? <></> :
          <div className={styles.content}>
            <NavLink className={styles.goBack} to="/manutencao/trabalhos" state={ jobStatus ? {from: jobStatus, search: state ? state.search : ""} : ""}>
              <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
            </NavLink>
            <div className={styles.form}>
              <div className={styles.formField}>
                <p className={ nameValue ? styles.inputLabel : styles.inputLabelNull}>Nome</p>
                <input
                  defaultValue={nameValue}
                  rows={3}
                  placeholder={"Indique o nome do trabalho..."}
                  onChange={(value) => setNameValue(value.target.value)}
                />
              </div>
              <div className={styles.formField}>
                <p className={ descriptionValue ? styles.inputLabel : styles.inputLabelNull}>Descrição</p>
                <input
                  defaultValue={descriptionValue}
                  rows={3}
                  placeholder={"Escreva uma pequena descrição do trabalho..."}
                  onChange={(value) => setDescriptionValue(value.target.value)}
                />
              </div>
              <Dropdown
                label={"Tipo"}
                placeholder={"Escolha um tipo de ação"}
                dropdownWidth={"40vw"}
                itemsWidth={"40vw"}
                items={jobTypes}
                value={jobTypeValue}
                onValueChange={setJobTypeValue}
                required={true}
              />
              <MultiSelect
                label={"Intervenções"}
                placeholder={"Escolher intervenções"}
                itemsWidth={"calc(40vw - 20px)"}
                items={interventions}
                value={selectedInterventions}
                onValueChange={setSelectedInterventions} 
                required={true}
              />
              <MultiSelect
                label={"Locais"}
                placeholder={"Trabalhos serão realizados em todos os seguintes locais"}
                itemsWidth={"calc(40vw - 20px)"}
                items={locais}
                value={selectedLocais}
                onValueChange={setSelectedLocais}
                onUrlChange={getLocais}
                required={true}
                search={true}
                urlSearch={"/locals"}
                pagination={true}
                nextUrl={nextLocais}
                previousUrl={previousLocais}
              />
              <Dropdown
                label={"Periodicidade"}
                placeholder={"Escolha a periodicidade do trabalho..."}
                dropdownWidth={"40vw"}
                itemsWidth={"40vw"}
                items={periodicities}
                value={periodicityValue}
                onValueChange={setPeriodicityValue}
                required={true}
              />
              {
                periodicityValue ?
                  <div className={styles.periodicityContent}>
                    <h4 style={{textTransform: "uppercase"}}>{periodicityValue.name}</h4>
                    <div className={styles.formPeriocity}>
                      {
                        periodicityValue.id === 1 ?
                          <div className={styles.formField}>
                            <p className={ finalDateValue ? styles.inputLabel : styles.inputLabelNull}>Data</p>
                            <input
                              type={"date"}
                              defaultValue={finalDateValue}
                              onChange={(value) => {setFinalDateValue(value.target.value)}}
                            />
                          </div>
                        : ""
                      }
                      {
                        periodicityValue.id === 2 ?
                          <div className={styles.formPeriocity}>
                            <Dropdown
                              label={"Dias"}
                              placeholder={"Escolha o tipo de periodicidade diária..."}
                              dropdownWidth={"calc(30vw - 20px)"}
                              itemsWidth={"calc(30vw - 20px)"}
                              items={dailyPeriodicities}
                              value={dailyPeriodicityValue}
                              onValueChange={setDailyPeriodicityValue}
                              required={true}
                            />
                            {
                              dailyPeriodicityValue.id === 2 ?
                              <div className={styles.formFieldEach}>
                                <p className={ dailyPeriodicityValue.each >= 1 ? styles.inputLabel : styles.inputLabelNull}>A cada</p>
                                <input
                                  defaultValue={dailyPeriodicityValue.each}
                                  onChange={(value) => {setDailyPeriodicityValue({...dailyPeriodicityValue, each: value.target.value})}}
                                />
                                <p>dia (s)</p>
                              </div>
                              :""
                            }
                            <div className={styles.formField}>
                              <p className={ initialDateValue ? styles.inputLabel : styles.inputLabelNull}>Data inicial</p>
                              <input
                                disabled={id ? true : false}
                                style={{color: id ? "#888" : ""}}
                                type={"date"}
                                defaultValue={initialDateValue}
                                onChange={(value) => {setInitialDateValue(value.target.value)}}
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                            <div className={styles.formField}>
                              <p className={ finalDateValue ? styles.inputLabel : styles.inputLabelNull}>Data final</p>
                              <input
                                type={"date"}
                                defaultValue={finalDateValue}
                                onChange={(value) => {setFinalDateValue(value.target.value)}}
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                          </div>
                        : ""
                      }
                      {
                        periodicityValue.id === 3 ?
                          <div className={styles.formPeriocity}>
                            <div className={styles.formFieldEach}>
                              <p className={ weeklyPeriodicityValue >= 1 ? styles.inputLabel : styles.inputLabelNull}>A cada</p>
                              <input
                                defaultValue={weeklyPeriodicityValue}
                                onChange={(value) => {setWeeklyPeriodicityValue(value.target.value)}}
                              />
                              <p>semanas à/ao:</p>
                            </div>
                            <MultiSelect
                              label={"Dias da semana"}
                              placeholder={"Escolher dias da semana"}
                              itemsWidth={"calc(40vw - 20px)"}
                              items={weekdays}
                              value={selectedWeekdays}
                              onValueChange={setSelectedWeekdays} 
                              required={true}
                            />
                            <div className={styles.formField}>
                              <p className={ initialDateValue ? styles.inputLabel : styles.inputLabelNull}>Data inicial</p>
                              <input
                                disabled={id ? true : false}
                                style={{color: id ? "#888" : ""}}
                                type={"date"}
                                defaultValue={initialDateValue}
                                onChange={(value) => {setInitialDateValue(value.target.value)}}
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                            <div className={styles.formField}>
                              <p className={ finalDateValue ? styles.inputLabel : styles.inputLabelNull}>Data final</p>
                              <input
                                type={"date"}
                                defaultValue={finalDateValue}
                                onChange={(value) => {setFinalDateValue(value.target.value)}}
                                min={new Date().toISOString().slice(0, 10)}
                              />
                            </div>
                          </div>
                        : ""
                      }
                      {
                        periodicityValue.id === 4 ?
                          <div className={styles.formPeriocity}>
                            <Dropdown
                              label={"Marcar no/a"}
                              placeholder={"Escolha como deseja marcar o dia..."}
                              dropdownWidth={"calc(30vw - 20px)"}
                              itemsWidth={"calc(30vw - 20px)"}
                              items={monthPeriodicities}
                              value={monthPeriodicityValue}
                              onValueChange={setMonthPeriodicityValue}
                              required={true}
                            />
                            {
                              monthPeriodicityValue.id === 1 ?
                              <div className={styles.formField}>
                                <p className={ dayOfMonth >= 1 ? styles.inputLabel : styles.inputLabelNull}>Dia do mês</p>
                                <input
                                  style={{width: "6%"}}
                                  defaultValue={dayOfMonth}
                                  onChange={(value) => {setDayOfMonth(value.target.value)}}
                                />
                              </div>
                              : ""
                            }
                            {
                              monthPeriodicityValue.id === 2 || monthPeriodicityValue.id === 3 ?
                                <MultiSelect
                                  label={"Dias da semana"}
                                  placeholder={"Escolher dias da semana"}
                                  itemsWidth={"calc(40vw - 20px)"}
                                  items={weekdays}
                                  value={selectedWeekdays}
                                  onValueChange={setSelectedWeekdays} 
                                  required={true}
                                />
                              : ""
                            }
                            {
                              monthPeriodicityValue ?
                                <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                                  <div className={styles.formField}>
                                    <p className={ initialMonthValue ? styles.inputLabel : styles.inputLabelNull}>Mês inicial</p>
                                    <div>
                                      <DatePicker
                                        disabled={id ? true : false}
                                        style={{color: id ? "#888" : ""}}
                                        selected={initialMonthValue}
                                        onChange={(date) => setInitialMonthValue(date)}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                  <div className={styles.formField}>
                                    <p className={ finalMonthValue ? styles.inputLabel : styles.inputLabelNull}>Mês final</p>
                                    <div>
                                      <DatePicker
                                        selected={finalMonthValue}
                                        onChange={(date) => setFinalMonthValue(date)}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        minDate={new Date().setMonth(new Date().getMonth() + 1)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              : ""
                            }
                          </div>
                        : ""
                      }
                      {
                        periodicityValue.id === 5 ?
                        <div className={styles.formPeriocity}>
                          <div className={styles.formFieldEach}>
                            <p className={ anualEachMonth >= 1 ? styles.inputLabel : styles.inputLabelNull}>A cada</p>
                            <input
                              defaultValue={anualEachMonth}
                              onChange={(value) => {setAnualEachMonth(value.target.value)}}
                            />
                            <p style={{margin: "10px", marginLeft: "0"}}>meses, no dia:</p>
                            <input
                              style={{borderRadius: "4px"}}
                              defaultValue={anualDay}
                              onChange={(value) => {setAnualDay(value.target.value)}}
                            />
                          </div>
                          <div className={styles.formField}>
                            <p className={ initialMonthValue ? styles.inputLabel : styles.inputLabelNull}>Mês inicial</p>
                            <div>
                              <DatePicker
                                disabled={id ? true : false}
                                style={{color: id ? "#888" : ""}}
                                selected={initialMonthValue}
                                onChange={(date) => setInitialMonthValue(date)}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                                minDate={new Date().setMonth(new Date().getMonth())}
                              />
                            </div>
                          </div>
                          <div className={styles.formField}>
                            <p className={ finalMonthValue ? styles.inputLabel : styles.inputLabelNull}>Mês final</p>
                            <div>
                              <DatePicker
                                selected={finalMonthValue}
                                onChange={(date) => setFinalMonthValue(date)}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                                minDate={new Date().setMonth(new Date().getMonth() + 1)}
                              />
                            </div>
                          </div>
                        </div>
                        : ""
                      }
                      <div className={styles.formFieldTime}>
                        <p className={ selectedTime ? styles.inputLabel : styles.inputLabelNull}>Hora</p>
                        <div>
                          <TimePicker
                            value={selectedTime}
                            onChange={(time) => setSelectedTime(time)}
                            disableClock={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                : ""
              }
              {
                id ?
                <button className={styles.submitBtn} onClick={() => editJob(id)} type="">EDITAR</button>
                :
                <button className={styles.submitBtn} onClick={() => addJob()} type="">ADICIONAR</button>
              }
            </div>
          </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarTrabalhos;
import React, { useState, useRef } from "react";
import Layout from "../../../../Components/Layout/Layout";
import styles from "./AdicionarUtilizadores.module.css";
import Loading from "../../../../Components/Loading/Loading";
import Alert from "../../../../Components/Alert/Alert";
import { useEffect } from "react";
import axios from "axios";
import { Url } from "../../../../Constants/globals";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiArrowsRightLeft, HiOutlineLockClosed, HiOutlineLockOpen, HiArrowPath, HiCog6Tooth } from "react-icons/hi2";
import { MdAdd } from "react-icons/md";
import RefreshToken from "../../../../RefreshToken/RefreshToken";
import Dropdown from "../../../../Components/Dropdown/Dropdown";
import MultiSelect from "../../../../Components/MultiSelect/MultiSelect";
import { IoPencil, IoTrash } from "react-icons/io5";
import DeleteModal from "../../../../Components/DeleteModal/DeleteModal";
import EncryptDecryptStorage from "../../../../Components/EncryptDecryptStorage/EncryptDecryptStorage";

function AdicionarUtilizadores({permissions}) {

  const divRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [firstnameValue, setFirstnameValue] = useState("");
  const [lastnameValue, setLastnameValue] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [deleteModalGroup, setDeleteModalGroup] = useState(false);

  const urlGroups = Url+"/groups?start=0&limit=50000";
  const [groups, setGroups] = useState([]);
  const [nextGroups, setNextGroups] = useState("");
  const [groupValue, setGroupValue] = useState("");

  const [showPermissions, setShowPermissions] = useState(id ? false : true);
  const [isPoppedOut, setIsPoppedOut] = useState(false);

  const urlClients = Url+"/clients?start=0&limit=15";
  const [clients, setClients] = useState([]);
  const [previousClients, setPreviousClients] = useState("");
  const [nextClients, setNextClients] = useState("");
  const [clientValue, setClientValue] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);

  const urlProfiles = Url+"/malfunctions/profiles/?start=0&limit=15";
  const [profiles, setProfiles] = useState([]);
  const [nextProfiles, setNextProfiles] = useState("");
  const [previousProfiles, setPreviousProfiles] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const [firstLoadAccountTypes, setFirstLoadAccountTypes] = useState(true);
  const accountTypesUrl = Url+"/users/account-types/?start=0&limit=15";
  const [accountTypes, setAccountTypes] = useState([]);
  const [nextAccountTypes, setNextAccountTypes] = useState("");
  const [accountTypeValue, setAccountTypeValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(false);

  const getUserDetails = async (id) => {
    axios.get(Url+"/users/"+id, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
        await setFirstnameValue(res.data.first_name);
        await setLastnameValue(res.data.last_name);
        await setClientValue(res.data.client);
        await setEmail(res.data.email);
        await setUsername(res.data.username);
        await setAccountTypeValue(res.data.account_type);
        await setSelectedProfiles(res.data.technical_profile);
        await setSelectedClients(res.data.many_clients.length > 0 ? res.data.many_clients : [res.data.client]);
        await setGroupValue(res.data.user_group);
        await setLoading(false);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getUserDetails(id) : window.location.reload();
      }
    })
  }

  const getGroups = async (urlGroups) => {
    axios.get(urlGroups, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      if (groups.length > 0 && !groups.includes(res.data.detail)) {
        const updatedGroups = groups.concat(res.data.detail);
        setGroups(updatedGroups);
      } else {
        setGroups(res.data.detail);
      }
      setNextGroups(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getGroups(urlGroups) : window.location.reload();
      }
      else {
        console.log(err);
      }
    })
  }
  
  const getClients = async (urlClients) => {
    axios.get(urlClients, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      if(permissions.clients && permissions.clients.PUT){
        setClients(res.data.detail);
        setPreviousClients(res.data.previous);
        setNextClients(res.data.next);
      }
      else {
        setClients(EncryptDecryptStorage("decrypt").user.many_clients);
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getClients(urlClients) : window.location.reload();
      }
      else {
        setClients([]);
        setNextClients("");
        setPreviousClients("");
      }
    })
  }

  useEffect(() => {
    getClients(urlClients);
  }, [urlClients]);
  
  const getTechnicalProfiles = async (urlProfiles) => {
    axios.get(urlProfiles, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then((res) => {
      setProfiles(res.data.detail);
      setPreviousProfiles(res.data.previous);
      setNextProfiles(res.data.next);
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getTechnicalProfiles(urlProfiles) : window.location.reload();
      }
      else {
        setProfiles([]);
        setNextProfiles("");
        setPreviousProfiles("");
      }
    })
  }

  useEffect(() => {
    getTechnicalProfiles(urlProfiles);
  }, [urlProfiles]);

  const getAccountTypes = (accountTypesUrl) => {
    axios.get(accountTypesUrl, {
      headers: {
        Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
      },
    }).then( async (res) => {
      setNextAccountTypes(res.data.next);
      if (firstLoadAccountTypes) {
        setAccountTypes(res.data.detail);
        setFirstLoadAccountTypes(false);
      } else {
        setAccountTypes(prevLocals => [...prevLocals, ...res.data.detail]);
      }
    }).catch(async (err) => {
      if (err.request && err.request.status === 401){
        var newToken = await RefreshToken(err);
        newToken ? getAccountTypes(accountTypesUrl) : window.location.reload();
      }
      else {
        console.log(err);
      }
    })
  }

  const deleteGroup = async (id) => {
    await setAlert("");
    await setGroupValue("");
    await axios
      .delete(Url + "/groups/"+id, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(() => {
        setGroups(groups.filter((group) => group.id !== id));
        setAlert(<Alert type={"success"}>Grupo eliminado com sucesso</Alert>);
      })
      .catch(async (err) => {
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? deleteGroup(id) : window.location.reload();
        }
        setAlert(<Alert type={"error"}>Erro ao eliminar grupo</Alert>);
      });
  }

  const Permissions = () => {
    return (
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
        <Dropdown
          label={"Grupo"}
          placeholder={"Escolher o grupo do utilizador"}
          itemsWidth={groupValue ? "calc(40vw - 80px)" : "calc(40vw - 20px)"}
          dropdownWidth={groupValue ? "calc(40vw - 80px)" : "calc(40vw - 20px)"}
          items={groups}
          onUrlChange={getGroups}
          value={groupValue}
          onValueChange={setGroupValue}
          nextUrl={nextGroups}
          required={true}
        />
        {
          groupValue ?
          <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
            {
              permissions.groups && permissions.groups.PUT ?
              <NavLink className={styles.edit} to={"/backoffice/utilizadores/grupos/editar/"+groupValue.id} state={{from: id ? id : ""}}>
                <IoPencil size={20} />
              </NavLink>
              : ""
            }

            {
              permissions.groups && permissions.groups.DELETE ?
              <NavLink className={styles.delete} onClick={() => setDeleteModalGroup(true)}>
                <IoTrash size={20} />
              </NavLink>
              : ""
            }
          </div>
          : ""
        }
      </div>
    );
  }

  useEffect(() => {
    getGroups(urlGroups);
  }, [urlGroups]);
  

  useEffect(() => {
    getAccountTypes(accountTypesUrl);
  }, [accountTypesUrl]);

  useEffect(() => {
    getAccountTypes(accountTypesUrl);
    if (id) getUserDetails(id);
    else {
      getClients(urlClients);
      setLoading(false);
    }
  }, []);

  const handlePermissionsContainer = () => {
    if (showPermissions){
      setIsPoppedOut(true);
      setTimeout(() => {
        setShowPermissions(false);
        setIsPoppedOut(false);
      },280);
    } else {
      setShowPermissions(true);
    }
  }


  const addUser = async () => {
    await setAlert();
    if (password.length < 8){
      return setAlert(<Alert type={"error"}>A palavra-passe tem de ter pelo menos 8 caracteres</Alert>);
    }
    else if (password !== confirmpassword){
      return setAlert(<Alert type={"error"}>A palavra-passe e a sua confirmação não coincidem</Alert>);
    }
    else if (firstnameValue === "" || lastnameValue === "" || username === "" || selectedClients.length === 0 || (selectedClients.length > 1 && clientValue === "") || groupValue === ""){
      return setAlert(<Alert type={"error"}>Apresenta campos que não podem ser vazios</Alert>);
    }
    var finalProfiles = [];
    if (selectedProfiles.length > 0){
      for (var a = 0; a < selectedProfiles.length; a++){
        finalProfiles.push(selectedProfiles[a].id);
      }
    }
    var finalClients = [];
    if (selectedClients.length > 0){
      for (var b = 0; b < selectedClients.length; b++){
        finalClients.push(selectedClients[b].id);
      }
    }
    setSpinner(true);
    await axios
      .post(Url + "/users", {
        first_name: firstnameValue,
        last_name: lastnameValue,
        username: username,
        email: email,
        client_id: finalClients.length === 1 ? finalClients[0] : clientValue.id,
        many_clients: finalClients,
        account_type_id: accountTypeValue.id,
        password: password,
        technical_profile: (finalProfiles.length > 0 && accountTypeValue.id === 2) ? finalProfiles : [],
        user_group_id: groupValue.id
      }, {
        headers: {
          Authorization: `Bearer ${EncryptDecryptStorage("decrypt").token}`,
        }
      },)
      .then(async (res) => {
        setAlert(<Alert type={"success"}>Utilizador criado com sucesso</Alert>);
        setTimeout(() => {
            navigate("/backoffice/utilizadores");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? addUser() : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>Erro ao criar utilizador</Alert>);
        }
      });
  }

  const editUser = async (id) => {
    if (firstnameValue === "" || lastnameValue === "" || username === "" || selectedClients.length === 0 || (selectedClients.length > 1 && clientValue === "") || groupValue === ""){
        return;
    }
    var finalProfiles = [];
    if (selectedProfiles.length > 0){
      for (var a = 0; a < selectedProfiles.length; a++){
        finalProfiles.push(selectedProfiles[a].id);
      }
    }
    var finalClients = [];
    if (selectedClients.length > 0){
      for (var b = 0; b < selectedClients.length; b++){
        finalClients.push(selectedClients[b].id);
      }
    }
    setAlert();
    var jason = EncryptDecryptStorage("decrypt");
    setSpinner(true);
    await axios
      .put(Url + "/users/"+id, {
        first_name: firstnameValue,
        last_name: lastnameValue,
        username: username,
        email: email,
        client_id: finalClients.length === 1 ? finalClients[0] : clientValue.id,
        many_clients: finalClients,
        account_type_id: accountTypeValue.id,
        technical_profile: (finalProfiles.length > 0 && accountTypeValue.id === 2) ? finalProfiles : [],
        user_group_id: groupValue.id
      }, {
        headers: {
          Authorization: "Bearer "+jason.token,
        }
      },)
      .then(() => {
        setAlert(<Alert type={"success"}>Utilizador editado com sucesso</Alert>);
        if (jason.user.id.toString() === id){
          jason.user.first_name = firstnameValue;
          jason.user.last_name = lastnameValue;
          jason.user.username = username;
          jason.user.email = email;
          jason.user.client = clientValue;
          jason.user.account_type = accountTypeValue.name;
          jason.user_group = groupValue;
          jason.many_clients = selectedClients;
          EncryptDecryptStorage("encrypt", jason);
        }
        setTimeout(() => {
            navigate("/backoffice/utilizadores");
        }, 1000);
      })
      .catch(async (err) => {
        setSpinner(false);
        if (err.request && err.request.status === 401){
          var newToken = await RefreshToken(err);
          newToken ? editUser(id) : window.location.reload();
        }
        else {
          setAlert(<Alert type={"error"}>{err.request.response ? JSON.parse(err.request.response).detail : "Erro ao editar utilizador"}</Alert>);
        }
      });
  }

  return (
    <>
      <Layout tab={id ? "backoffice utilizador #"+id : "backoffice utilizadores"} permissions={permissions}>
        {alert}
        { 
        spinner ? <Loading /> :
        loading ? <></> :
        <div ref={divRef} className={styles.content}>
          {deleteModalGroup ? <DeleteModal onClose={setDeleteModalGroup} onValueChange={setGroups} headerText={"ELIMINAR GRUPO"} confirmationText={"Deseja eliminar "+groupValue.name+" ?"} deleteFunction={deleteGroup} objectId={groupValue.id}/> : ""}
          <NavLink className={styles.goBack} to="/backoffice/utilizadores">
            <HiChevronLeft size={60} color={"var(--goback-btn)"}/>
          </NavLink>
          <div className={styles.form}>
            <button style={showPermissions ? {color: "var(--text-primary)", border: "1px solid var(--input-background)"} : {}} className={styles.permissionsBtn} onClick={() => {handlePermissionsContainer(showPermissions)}}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "3px"}}>
                <HiOutlineLockClosed size={20}/>
                <HiArrowsRightLeft size={16} />
                <HiOutlineLockOpen size={20}/>
              </div>
              <p className={styles.permissionsText}>Permissões e acessos</p>
            </button>
            {
              showPermissions ?
              <div className={`${styles.containerPermissions} ${showPermissions ? styles.popin : ""} ${isPoppedOut ? styles.popout : ""}`}>
                <h3 className={styles.permissionsTitle}>Permissões e acessos{
              permissions.groups && permissions.groups.POST ?
              <NavLink className={styles.addUser} to={"/backoffice/utilizadores/grupos/adicionar"} state={{from: id ? id : ""}}>
                <MdAdd className={styles.addIcon} size={18} />
              </NavLink>
              : ""
            }</h3>
                <Permissions/>
              </div>
              : ""
            }
            <div className={styles.formField}>
                <p className={ firstnameValue ? styles.inputLabel : styles.inputLabelNull}>Primeiro nome</p>
                <input
                    defaultValue={firstnameValue}
                    rows={3}
                    placeholder="Indique o primeiro nome..."
                    onChange={(value) => setFirstnameValue(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={ lastnameValue ? styles.inputLabel : styles.inputLabelNull}>Último nome</p>
                <input
                    defaultValue={lastnameValue}
                    rows={3}
                    placeholder="Indique o último nome..."
                    onChange={(value) => setLastnameValue(value.target.value)}
                />
            </div>
            <MultiSelect
              label={"Hotel"}
              placeholder={"Escolher hotel"}
              itemsWidth={"calc(40vw - 20px)"}
              items={clients}
              value={selectedClients}
              onValueChange={setSelectedClients}
              onUrlChange={getClients}
              required={true}
              search={true}
              urlSearch={"/clients"}
              pagination={true}
              nextUrl={nextClients}
              previousUrl={previousClients}
            />

            {
              selectedClients.length > 1 ?
                <Dropdown
                  label={"Hotel atual"}
                  placeholder={"Escolher hotel atual do utilizador"}
                  itemsWidth={"40vw"}
                  items={selectedClients}
                  value={clientValue}
                  onValueChange={setClientValue}
                  required={true}
                />
              :""
            }
            <Dropdown
              label={"Departamento"}
              placeholder={"Escolher departamento do utilizador"}
              itemsWidth={"40vw"}
              items={accountTypes}
              onUrlChange={getAccountTypes}
              value={accountTypeValue}
              onValueChange={setAccountTypeValue}
              nextUrl={nextAccountTypes}
              required={true}
            />
            {
              accountTypeValue.name === "Manutenção"
              ?
                <div style={{display: "flex", gap: "10px"}}>
                  <MultiSelect
                    label={"Perfil técnico"}
                    placeholder={"Atribuir perfis técnicos"}
                    itemsWidth={"calc(40vw - 20px)"}
                    dropdownWidth={"100%"}
                    items={profiles}
                    value={selectedProfiles}
                    onValueChange={setSelectedProfiles}
                    onUrlChange={getTechnicalProfiles}
                    required={false}
                    search={true}
                    urlSearch={"/malfunctions/profiles/"}
                    pagination={true}
                    nextUrl={nextProfiles}
                    previousUrl={previousProfiles}
                  />
                  <div className={styles.backgroundAddIcon} onClick={() => getTechnicalProfiles(urlProfiles)}>
                    <HiArrowPath size={22} />
                  </div>
                  <div className={styles.backgroundAddIcon} onClick={() => window.open("/backoffice/utilizadores/perfis", "_blank")}>
                    <HiCog6Tooth size={22} />
                  </div>
                </div>
              :""
            }
            <div className={styles.formField}>
                <p className={styles.inputLabel}>Email</p>
                <input
                    defaultValue={email}
                    rows={3}
                    placeholder="Indique o email..."
                    onChange={(value) => setEmail(value.target.value)}
                />
            </div>
            <div className={styles.formField}>
                <p className={ username ? styles.inputLabel : styles.inputLabelNull}>Username</p>
                <input
                    defaultValue={username}
                    rows={3}
                    placeholder="Indique o nome de utlizador..."
                    onChange={(value) => setUsername(value.target.value)}
                />
            </div>
            {
                id ?
                ""
                : 
                <>
                    <div className={styles.formField}>
                        <p className={ password ? styles.inputLabel : styles.inputLabelNull}>Password</p>
                        <input
                            type="password"
                            rows={3}
                            placeholder="Indique a palavra-passe..."
                            onChange={(value) => setPassword(value.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <p className={confirmpassword ? styles.inputLabel : styles.inputLabelNull}>Confirmar password</p>
                        <input
                            type="password"
                            rows={3}
                            placeholder="Confirme a palavra-passe..."
                            onChange={(value) => setConfirmpassword(value.target.value)}
                        />
                    </div>
                </>
            }
            {
              id ?
              <button className={styles.submitBtn} onClick={() => editUser(id)} type="">EDITAR</button>
              :
              <button className={styles.submitBtn} onClick={() => addUser()} type="">ADICIONAR</button>
            }
          </div>
        </div>
        }
      </Layout>
    </>
  );
}

export default AdicionarUtilizadores;